import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-reserve-desc',
    templateUrl: './reserve-desc.component.html',
    styleUrls: ['./reserve-desc.component.less'],
})
export class ReserveDescComponent implements OnInit {
    desc = '';
    constructor() {
    }

    ngOnInit(): void {
        this.desc = '';
    }

}
