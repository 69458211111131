import { Component, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StartupService } from '@core';
import { environment } from '@env/environment';
import { AuthHttp } from '@services/sys/auth.service';
import { LicenceService } from '@services/sys/licence.service';
import { TokenService } from '@services/sys/token.service';
import { VersionService } from '@services/sys/version.service';
import * as dayjs from 'dayjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-user-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class UserLoginComponent {
    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Input is required',
            email: 'Please enter valid Email.',
        },
    };
    loading = false;
    beforeName: string | null = '';

    constructor(
        fb: FormBuilder,
        private router: Router,
        private startupSrv: StartupService,
        public service: AuthHttp,
        public msg: NzMessageService,
        private tokenService: TokenService,
        private versionService: VersionService,
        private licenceService: LicenceService,
    ) {
        const username = localStorage.getItem('username');
        console.log('username', username);
        this.form = fb.group({
            userName: [username, [Validators.required]],
            password: [null, [Validators.required]],
            remember: [true],
        });
    }

    get versionInfo(): any {
        return {
            version: environment.version,
            commit: environment.commit,
        };
    }

    // #region fields

    get userName(): AbstractControl {
        return this.form.controls.userName;
    }

    get password(): AbstractControl {
        return this.form.controls.password;
    }

    get remember(): AbstractControl {
        return this.form.controls.remember;
    }

    get currentYear(): string {
        return dayjs().format('YYYY');
    }

    form: FormGroup;
    error = '';

    async submit(): Promise<void> {
        localStorage.removeItem('hasCheckLicence');
        sessionStorage.removeItem('hasExpired');
        localStorage.setItem('firstLogin', 'true');
        this.error = '';
        this.userName.markAsDirty();
        this.userName.updateValueAndValidity();
        this.password.markAsDirty();
        this.password.updateValueAndValidity();
        if (this.userName.invalid || this.password.invalid) {
            return;
        }

        try {
            this.loading = true;
            const data = await this.service.login({
                username: this.userName.value,
                password: this.password.value,
            });
            this.beforeName = localStorage.getItem('username');
            if (this.remember.value) {
                localStorage.setItem('username', this.userName.value);
            } else {
                localStorage.removeItem('username');
            }
            await this.tokenService.set(data);
            const licences = await this.licenceService.getLicencedFeatures();
            localStorage.setItem('licences', JSON.stringify(licences));
            // this.router.navigateByUrl('/');
            // const { firstLink } = await this.startupSrv.load();
            // console.log('firstLink', firstLink);
            this.router.navigateByUrl('/');
            // this.getMaintenance();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    getMaintenance(): void {
        if (this.beforeName !== this.userName.value) {
            localStorage.removeItem('maintenance');
        }
        const maintenanceTime = localStorage.getItem('maintenance');
        this.versionService.getVersionInfo().then((res) => {
            const expireDate = dayjs(res.license.expireDate);
            const now = dayjs();
            // expireDate = dayjs('2022-04-22T16:00:00Z');
            const nowFormat = String(dayjs().format('YYYY-MM-DD'));
            if (!environment.maintenance) {
                return;
            }
            if (!(expireDate && maintenanceTime !== nowFormat)) {
                return;
            }
            /*
            if (expireDate.isBefore(now)) {
                this.msg.warning(`
                 <ng-template #tpl>
                    <span>The licence has expired, please contact <a href="mailto: support@acentury.co">support@acentury.co</a></span>
                </ng-template>`);
                localStorage.setItem('maintenance', nowFormat);
            }
            if (expireDate.isAfter(now) && expireDate.isBefore(now.add(1, 'month'))) {
                this.msg.warning(`
                 <ng-template #tpl>
                    <span>The licence will expire soon, please contact <a href="mailto: support@acentury.co">support@acentury.co</a></span>
                </ng-template>`);
                localStorage.setItem('maintenance', nowFormat);
            }
            */
        });
    }
}
