import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationParams, NotificationWithPage, ResponseData } from '@app/interfaces/lm/notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    private baseUrl = '/notices';

    constructor(private http: HttpClient) {}

    async find(params: NotificationParams): Promise<NotificationWithPage> {
        return this.http
            .get<NotificationWithPage>(this.baseUrl, {
                params: Object.assign(params),
            })
            .toPromise();
    }

    async batchRead(data: {
        ids: number[];
    }): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}/batch/readed`, data).toPromise();
    }

    async response(data: ResponseData): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}/response`, data).toPromise();
    }
}
