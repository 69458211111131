<div class="upload-container">
    <div *ngIf="showProgress" class="progress-container">
        <app-progress
            *ngIf="progress && progress > 0"
            [percent]="progress"
            [strokeWidth]="12"
            nzStrokeColor="#28A745"
            [showInfo]="true"
        ></app-progress>
    </div>

    <nz-upload
        *ngIf="progress === 0"
        [nzBeforeUpload]="beforeUpload"
    >
        <button nz-button>
            <em nz-icon nzType="upload"></em>
            {{ buttonText }}
        </button>
    </nz-upload>
</div>
