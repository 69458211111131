<div class="scroll-card-tab" #tabRow>
    <div class="arrow left-arrow" *ngIf="showArrow" (click)="scrollLeft()">
        <span nz-icon nzType="left" nzTheme="outline"></span>
    </div>
    <div class="tabs-container">
        <div class="tabs"  #tab [class]="showArrow ? 'hidden-tabs' : ''">
            <div
                    class="card-item"
                    *ngFor="let item of tabs; let i = index"
                    [class.active]="selectTabIndex === i"
                    (click)="selectTab(i)"
            >
                {{ item.tab }}
            </div>
        </div>
    </div>

    <div class="arrow right-arrow" *ngIf="showArrow" (click)="scrollRight()">
        <span nz-icon nzType="right" nzTheme="outline"></span>
    </div>
</div>
