<div class="input-container">
    <input
        type="text"
        nz-input
        trim="blur"
        [ngClass]="readonly ? 'readonly-input' : ''"
        [nzAutocomplete]="auto"
        [autocomplete]="autocomplete"
        [type]="type"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        [readonly]="readonly"
        (input)="onInput($event)"
        (ngModelChange)="changeValue()"
    />

    <nz-autocomplete #auto>
        <nz-auto-option
            *ngFor="let option of selectOptions"
            [nzValue]="option[valueKey]"
            [nzLabel]="option[labelKey]"
        >
            {{ option.label }}
        </nz-auto-option>
    </nz-autocomplete>

</div>
