import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Item, Matrix } from '@app/interfaces/lm/matrix';

interface CellSize {
    width: string;
    height: string;
    maxWidth: string;
    minWidth: string;
}

@Component({
    selector: 'app-matrix',
    templateUrl: './matrix.component.html',
    styleUrls: ['./matrix.component.less'],
})
export class MatrixComponent implements AfterViewInit {
    @Input() loading = false;
    @Input() data: Matrix | undefined;
    @Input() contentTemplate: TemplateRef<any> | undefined;
    @Output() changeSelect = new EventEmitter();
    @ViewChild('matrixContainer') matrixContainer: ElementRef | undefined;

    hoverPosition: number[] = [];
    selectPosition: Item[] = [];
    tableScroll = {
        x: '800px',
        y: '200px',
    };

    tableStyle = {
        width: '800px',
    };
    tableWidth = '100px';

    get cellStyle(): CellSize {
        return {
            width: this.data?.itemWidth || '120px',
            height: this.data?.itemHeight || '50px',
            maxWidth: this.data?.itemWidth || '120px',
            minWidth: this.data?.itemWidth || '120px',
        };
    }

    ngAfterViewInit(): void {
        this.setTableWidth();
    }

    @HostListener('window:resize', ['$event.target'])
    onResize(): void {
        this.setTableWidth();
    }

    setTableWidth(): void {
        if (this.matrixContainer) {
            console.log('setWidth');
            const clientWidth = this.matrixContainer.nativeElement.clientWidth;
            const clientHeight = this.matrixContainer.nativeElement.clientHeight;
            const cellClientWidth = Number(this.data?.itemWidth.split('px')?.[0]);
            const cellClientHeight = Number(this.data?.itemHeight.split('px')?.[0]);
            const rowWidth = cellClientWidth * ((this.data?.xLabels.length || 0) + 1);
            const columnHeight = cellClientHeight * ((this.data?.yLabels.length || 0) + 1);

            const x = clientWidth > rowWidth ? rowWidth : clientWidth;
            const y = clientHeight > columnHeight ? columnHeight : clientHeight - 100;
            console.log('tableRow', clientHeight, columnHeight);
            this.tableWidth = x + 20 + 'px';
            this.tableScroll = {
                x: x + 'px',
                y: y + 'px',
            };

            this.tableStyle = {
                width: x + 20 + 'px',
            };

            console.log('tableScroll', this.tableScroll, this.tableStyle);
        }
    }

    mouseEnter(xIndex: number, yIndex: number): void {
        this.hoverPosition = [xIndex, yIndex];
    }

    mouseLeave(): void {
        this.hoverPosition = [];
    }

    selectCell(xIndex: number, yIndex: number, event: MouseEvent): void {
        const index = this.selectPosition.findIndex((item) => item.xIndex === xIndex && item.yIndex === yIndex);
        if (index === -1) {
            const data = this.getCellData(xIndex, yIndex);
            if (!event.ctrlKey) {
                this.selectPosition = [];
            }
            if (data) {
                this.selectPosition.push(data);
            }
        } else {
            this.selectPosition.splice(index, 1);
        }

        this.changeSelect.emit(this.selectPosition);
    }

    selectAll(): void {
        const data = this.data?.items ?? [];
        this.selectPosition = [...data];
        this.changeSelect.emit(this.selectPosition);
    }

    clearSelect(): void {
        this.selectPosition = [];
        this.hoverPosition = [];
        this.changeSelect.emit(this.selectPosition);
    }

    isSelect(xIndex: number, yIndex: number): boolean {
        return this.selectPosition.filter((item) => item.xIndex === xIndex && item.yIndex === yIndex)?.length > 0;
    }

    getCellData(xIndex: number, yIndex: number): Item | undefined {
        return this.data?.items.filter((item) => item.xIndex === xIndex && item.yIndex === yIndex)?.[0];
    }
}
