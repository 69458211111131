import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
})
export class ProgressComponent {
    @Input() percent: number | undefined;
    @Input() strokeWidth = 4;
    @Input() nzType = 'line';
    @Input() showInfo = false;
    @Input() nzStrokeColor: any = { '0%': '#FFA14B', '100%': '#FF902B' };
}
