<div *ngIf="item" class="item-container" [ngClass]="needResponse ? 'response-item' : ''" (click)="handleClickName()">
    <div class="icon">
        <em nz-icon nzType="svg:record"></em>
    </div>
    <div class="info">
        <div class="info-name">
            <div *ngIf="showUserName" class="user-name">
                {{ item.fromUserName }}
            </div>

            <div *ngIf="showProjectName && projectName" class="project-name">
                {{ projectName }}
            </div>
        </div>
        <div class="info-content">
            <app-overflow-tooltip [text]="item.content"></app-overflow-tooltip>
        </div>
    </div>
    <div class="right">
        <div class="time">
            {{ item.sendTime | date: 'yyyy/MM/dd h:mm a' }}
        </div>

        <div *ngIf="needResponse" class="response">
            Response
        </div>
    </div>
</div>
