<nz-spin [nzSpinning]="loading" [class]="loading ? 'loading-box' : ''">
    <form nz-form nz-row [formGroup]="validateForm">
        <nz-form-item nz-col [nzSpan]="formSpan" *ngFor="let fieldItem of formFields" style="display: flex">
            <nz-form-label
                *ngIf="fieldItem?.label"
                [nzSpan]="labelSpan"
                [nzRequired]="fieldItem.required"
                style="white-space: normal; overflow: visible;word-break: break-word"
            >{{ fieldItem.label }}</nz-form-label>
            <nz-form-control [nzSpan]="valueSpan"  [nzErrorTip]="getErrTipByField(fieldItem)">
                <nz-select
                    *ngIf="fieldItem?.type === 'select'"
                    [formControlName]="fieldItem.key"
                    [nzPlaceHolder]="fieldItem?.placeholder || getFieldTip(fieldItem)"
                    (ngModelChange)="changeFormValue(fieldItem)"
                    [nzDisabled]="fieldItem.disabled"
                    nzAllowClear
                >
                    <nz-option
                        *ngFor="let item of fieldItem.options"
                        [nzLabel]="item.label"
                        [nzValue]="item.value"
                    ></nz-option>
                </nz-select>
                <input
                    *ngIf="!fieldItem.type || fieldItem.type === 'input'"
                    nz-input
                    [placeholder]="fieldItem?.placeholder || getFieldTip(fieldItem)"
                    [formControlName]="fieldItem.key"
                    [readonly]="fieldItem.disabled"
                    [class]="fieldItem.disabled ? 'disabled-input' : ''"
                    trim="blur"
                />
                <nz-input-number
                    [nzPlaceHolder]="fieldItem?.placeholder || getFieldTip(fieldItem)"
                    *ngIf="fieldItem.type === 'inputNumber'"
                    [formControlName]="fieldItem.key"
                    [nzMin]="fieldItem?.min"
                    [nzStep]="1"
                    [nzDisabled]="fieldItem.disabled"
                    (ngModelChange)="changeFormValue(fieldItem)"
                    style="width: 100%"
                ></nz-input-number>
                <textarea
                    *ngIf="fieldItem.type === 'textarea'"
                    nz-input
                    [placeholder]="fieldItem?.placeholder || getFieldTip(fieldItem)"
                    [formControlName]="fieldItem.key"
                    trim="blur"
                ></textarea>
                <label
                    *ngIf="fieldItem.type === 'checkbox'"
                    style="margin-left: 100px; color: #656565"
                    nz-checkbox
                    (nzCheckedChange)="changeCheckBox($event)"
                    [formControlName]="fieldItem.key"
                >
                    {{ fieldItem?.label || fieldItem?.secondLabel || ''}}
                </label>
                <ng-container *ngIf="fieldItem.type === 'autocomplete'">
                    <input
                        *ngIf="fieldItem.disabled"
                        nz-input
                        [placeholder]="fieldItem?.placeholder || getFieldTip(fieldItem)"
                        [formControlName]="fieldItem.key"
                        [readonly]="fieldItem.disabled"
                        [class]="fieldItem.disabled ? 'disabled-input' : ''"
                        trim="blur"
                    />
                    <ng-container *ngIf="!fieldItem.disabled">
                        <input
                            nz-input
                            [formControlName]="fieldItem.key"
                            [placeholder]="fieldItem?.placeholder || getFieldTip(fieldItem)"
                            (ngModelChange)="changeFormValue(fieldItem)"
                            [readonly]="fieldItem.disabled"
                            [nzAutocomplete]="auto"
                            trim="blur"
                        />
                        <nz-autocomplete #auto>
                            <nz-auto-option
                                *ngFor="let option of fieldItem.options"
                                [nzValue]="option.value"
                                [nzLabel]="option.label"
                            >
                                {{ option.label }}
                            </nz-auto-option>
                        </nz-autocomplete>
                    </ng-container>
                </ng-container>
                <app-form-multiple-input
                    *ngIf="fieldItem.type === 'select-input'"
                    [formControlName]="fieldItem.key"
                    [options]="fieldItem.options"
                ></app-form-multiple-input>
            </nz-form-control>
        </nz-form-item>
    </form>
</nz-spin>
