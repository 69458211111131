<nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="close()" [nzOkText]="null" nzCancelText="Close">
    <ng-container *nzModalContent>
        <div class="user-info">
            <div class="left">
                <app-avatar
                    *ngIf="detail.fromUserName"
                    [info]="{ name: detail.fromUserName }"
                    [size]="50"
                ></app-avatar>
                <div class="name">
                    <div class="name-user">From: {{ detail.fromUserName }}</div>
                    <div class="name-project">
                        <span>{{ projectName }}</span>
                    </div>
                </div>
            </div>
            <div class="project-date">
                <span>{{ detail.sendTime | date: 'yyyy/MM/dd h:mm a' }}</span>
            </div>
        </div>

        <div class="content-container">
            <div class="title">
                <span>{{ detail.refType }}</span>
            </div>
            <div class="content">
                <span>{{ detail.content }}</span>
            </div>

            <div *ngIf="needResponse" class="response">
                Response:
                <input
                    [(ngModel)]="responseContent"
                    nz-input
                    placeholder="Response..."
                    class="response-input"
                    trim="blur"
                />
            </div>
        </div>
    </ng-container>

    <ng-container *nzModalFooter>
        <button
            nz-button
            nzType="default"
            (click)="close()"
        >
            Cancel
        </button>

        <button
            *ngIf="needResponse"
            [nzLoading]="submitLoading"
            nz-button
            nzType="primary"
            (click)="handleResponse()"
        >
            Response
        </button>
    </ng-container>
</nz-modal>
