import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

preloaderFinished();

function preloaderFinished(): void {
    const body = document.querySelector('body')!;
    const preloader = document.querySelector('.preloader')!;

    body.style.overflow = 'hidden';

    function remove(): void {
        // preloader value null when running --hmr
        if (!preloader) {
            return;
        }
        preloader.addEventListener('transitionend', () => {
            preloader.className = 'preloader-hidden';
        });

        preloader.className += ' preloader-hidden-add preloader-hidden-add-active';
    }

    (window as any).appBootstrap = () => {
        setTimeout(() => {
            remove();
            body.style.overflow = '';
        }, 100);
    };
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, {
        defaultEncapsulation: ViewEncapsulation.Emulated,
        preserveWhitespaces: false,
    })
    .then((res) => {
        const win = window as NzSafeAny;
        if (win && win.appBootstrap) {
            win.appBootstrap();
        }
        return res;
    })
    .catch((err) => console.error(err));
