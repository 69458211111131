import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EngineerProject } from '@app/interfaces/lm/engineer';

@Component({
    selector: 'app-engineer-item',
    templateUrl: './engineer-item.component.html',
    styleUrls: ['./engineer-item.component.less'],
})
export class EngineerItemComponent {
    @Input() item: EngineerProject | undefined;
    @Output() clickName = new EventEmitter();

    handleClickName(): void {
        this.clickName.emit(this.item);
    }
}
