import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.less'],
})
export class CardComponent {
    @Input() cardTitle: string | undefined;
    @Input() showMore = false;
    @Input() showShadow = true;
    @Output() more = new EventEmitter();

    handleMore(): void {
        this.more.emit();
    }
}
