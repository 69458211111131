import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from '@app/interfaces/lm/notification';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.less'],
})
export class NotificationItemComponent {
    @Input() item: Notification | undefined;
    @Input() showUserName = false;
    @Input() showProjectName = true;
    @Input() canResponse = true;

    @Output() detail = new EventEmitter();

    get projectName(): string {
        return this.item?.map?.projectName ?? '';
    }

    get needResponse(): boolean {
        return this.item?.responseStatus === 'WAITING' && this.canResponse;
    }

    handleClickName(): void {
        this.detail.emit(this.item);
    }
}
