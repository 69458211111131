import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { default as ngLang } from '@angular/common/locales/en';
import { APP_INITIALIZER, NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { zhCN as dateLang } from 'date-fns/locale';
import { en_US as zorroLang } from 'ng-zorro-antd/i18n';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

const LANG = {
    abbr: 'en',
    ng: ngLang,
    zorro: zorroLang,
    date: dateLang,
};
import { registerLocaleData } from '@angular/common';

registerLocaleData(LANG.ng, LANG.abbr);
const LANG_PROVIDES = [{ provide: NZ_I18N, useValue: en_US }];
// #endregion

// #endregion

// #region Startup Service
import { DefaultInterceptor, StartupService } from '@core';

const INTERCEPTOR_PROVIDES = [{ provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true }];
// #endregion

// #region global third module
const GLOBAL_THIRD_MODULES: Type<any>[] = [];
// #endregion

export function StartupServiceFactory(startupService: StartupService): () => Promise<void> {
    return () => startupService.load();
}

const APPINIT_PROVIDES = [
    StartupService,
    {
        provide: APP_INITIALIZER,
        useFactory: StartupServiceFactory,
        deps: [StartupService],
        multi: true,
    },
];
// #endregion

import { SharedModule } from '@shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        NzMessageModule,
        NzNotificationModule,
        ...GLOBAL_THIRD_MODULES,
    ],
    providers: [...LANG_PROVIDES, ...INTERCEPTOR_PROVIDES, ...APPINIT_PROVIDES],
    bootstrap: [AppComponent]
})
export class AppModule {}
