import {
    Component, ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { RemoteOption } from '@app/interfaces/sys/dict';
import { DictService } from '@services/sys/dict.service';

@Component({
    selector: 'app-remote-select',
    templateUrl: './remote-select.component.html',
    styleUrls: ['./remote-select.component.less'],
})
export class RemoteSelectComponent implements OnInit, OnChanges {
    @ViewChild('overflowText') overflowText: ElementRef | undefined;
    @ViewChild('optionEl') optionEl: ElementRef | undefined;
    @Input() requestName!: string;
    @Input() defaultOption!: RemoteOption;
    @Input() preloadData: RemoteOption[] = [];

    @Output() changeValue = new EventEmitter();

    page = 0;
    size = 20;
    keyword = '';
    overflowWidth = 0;
    showTotal = 0;
    showNum = 0;

    list: RemoteOption[] = [];
    finished = false;
    loading = false;
    searchLoading = false;
    currentValue: number | null = null;
    timer: number | null | undefined;
    searchTimer: number | null = null;
    contentWidth = 0;

    constructor(private dictService: DictService) {
    }

    ngOnInit(): void {
        this.overflowWidth = 0;
        setTimeout(() => {
            this.initWidth();
        }, 50);
    }

    @HostListener('window:resize', ['$event.target'])
    _resize(): void {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = window.setTimeout(() => {
            this.initWidth();
        }, 300);
    }

    initWidth(): void {
        // if (!this.optionEl) {
        //     return;
        // }
        this.showTotal = 0;
        this.showNum = 0;
        // 12 arrow width
        const maxWidth = this.overflowText?.nativeElement.clientWidth;
        const boxWidth = maxWidth - 12;
        if (!this.contentWidth) {
            this.contentWidth = this.optionEl?.nativeElement.clientWidth;
        }
        this.overflowWidth =  this.contentWidth - boxWidth;
        this.showTotal = Number(((boxWidth / 9) - 2).toFixed(0));
        this.showNum = Number(((this.showTotal / 2 ) - 2).toFixed(0));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.preloadData) {
            this.list = [...this.preloadData];
        }
        if (changes.defaultOption) {
            this.currentValue = this.defaultOption.value;
            this.setDefaultOption();
        }
    }

    changeSelect(value: number): void {
        const option = this.list.find(item => item.value === value);
        this.changeValue.emit(option);
        this.contentWidth = 0;
    }

    open(): void {
        this.handleRefresh();
    }

    async handleRefresh(needLoading: boolean = true): Promise<void> {
        this.page = 0;
        await this.fetchData(needLoading);
    }

    async fetchData(needLoading: boolean = true): Promise<void> {
        try {
            if (needLoading) {
                this.loading = true;
            }
            console.log('fetch data, page=', this.page);
            const query = {
                page: this.page,
                size: this.size,
                keyword: this.keyword,
            };
            console.log('fetchData', this.requestName, query);
            if (!this.requestName) {
                console.log('can not find request interface');
                return;
            }
            const { content, totalPages } = await this.dictService.fetchRemoteOption(query, this.requestName);
            this.loading = false;
            if (this.page === 0) {
                this.list = [
                    ...content,
                ];
            } else {
                this.list = [
                    ...this.list,
                    ...content,
                ];
            }
            this.finished = totalPages === 0 || this.page >= (totalPages - 1);
            if (!this.finished) {
                this.page++;
            }
            console.log('has load all page data', this.finished, 'currentPage = ', this.page);
        } finally {
            if (needLoading) {
                this.loading = false;
            }
        }
    }

    loadMore(): void {
        console.log('load more');
        if (this.finished || this.loading) {
            return;
        }
        this.fetchData();
    }

    setDefaultOption(): void {
        const ids: Array<number | string> = this.list.map(item => item.value);
        if (this.defaultOption.value && !ids.includes(this.defaultOption.value)) {
            this.list = [
                {
                    ...this.defaultOption,
                },
                ...this.list,
            ];
        }
    }

    onSearch(event: string): void {
        if (this.keyword === event) {
            return;
        }
        this.searchLoading = true;
        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
            this.searchTimer = null;
        }
        this.searchTimer = setTimeout(async () => {
            try {
                this.keyword = event;
                console.log('onSearch', this.keyword);
                await this.handleRefresh(false);
            } finally {
                this.searchLoading = false;
            }
        }, 500);
    }
}
