<nz-modal [(nzVisible)]="isVisible" nzTitle="Conflict Info" (nzOnCancel)="close()" [nzOkText]="null">
    <ng-container *nzModalContent>
        <div *ngIf="detail" class="info">
            <div class="info-item">
                <div class="item-label">Project Name</div>
                <div class="item-value">
                    {{ detail.projectName }}
                </div>
            </div>

            <div class="info-item">
                <div class="item-label">Tester Name</div>
                <div class="item-value">
                    {{ detail.testerName }}
                </div>
            </div>

            <div class="info-item">
                <div class="item-label">Start Date</div>
                <div class="item-value">
                    {{ detail.startDate | date: 'yyyy-MM-dd' }}
                </div>
            </div>

            <div class="info-item">
                <div class="item-label">End Date</div>
                <div class="item-value">
                    {{ detail.endDate | date: 'yyyy-MM-dd' }}
                </div>
            </div>
        </div>
    </ng-container>

    <div *nzModalFooter>
        <button nz-button class="text-btn-primary" (click)="handleShare()">Share</button>

        <button nz-button class="text-btn-primary" (click)="handleApprove()">Approve</button>

        <button nz-button class="text-btn-danger" (click)="handleReject()">Reject</button>

        <button nz-button class="text-btn-gray" (click)="close()">Close</button>
    </div>
</nz-modal>
