import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-search-keyword',
    templateUrl: './search-keyword.component.html',
    styleUrls: ['./search-keyword.component.less'],
})
export class SearchKeywordComponent implements OnInit {
    @Input() keyword!: string;
    @Input() disabledBtn = false;
    @Output() search = new EventEmitter();
    @Output() keywordChange = new EventEmitter();
    constructor() {
    }

    ngOnInit(): void {
    }

    enterSearch(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.onSearch();
        }
    }

    onSearch(): void {
        if (this.disabledBtn) {
            return;
        }
        this.search.emit();
    }

    clearKeyword(): void {
        this.keyword = '';
        this.keywordChange.emit(this.keyword);
        setTimeout(() => {
            this.onSearch();
        }, 100);
    }

    changeValue(value: string): void {
        this.keywordChange.emit(value);
    }

}
