<form nz-form [formGroup]="validateForm" [nzAutoTips]="autoTips" class="form-container">
    <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired> Name</nz-form-label>
        <nz-form-control [nzSpan]="16">
            <input nz-input formControlName="name" trim="blur"/>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired> Type</nz-form-label>
        <nz-form-control [nzSpan]="16">
            <nz-select formControlName="type" [nzDisabled]="editData !== undefined">
                <nz-option *ngFor="let option of typeOptions" [nzValue]="option.id" [nzLabel]="option.name"></nz-option>
            </nz-select>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="typeId !== 'TEST_ENVIRONMENT_REQUIREMENTS'">
        <nz-form-label [nzSpan]="5"> Owner</nz-form-label>
        <nz-form-control [nzSpan]="16">
            <nz-select formControlName="ownerId">
                <nz-option *ngFor="let option of ownerOptions" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
            </nz-select>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired> Start Date</nz-form-label>
        <nz-form-control [nzSpan]="16">
            <nz-date-picker formControlName="planStartDate"></nz-date-picker>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="5" nzRequired> End Date</nz-form-label>
        <nz-form-control [nzSpan]="16">
            <nz-date-picker formControlName="planEndDate"></nz-date-picker>
        </nz-form-control>
    </nz-form-item>

    <ng-container *ngIf="typeId === 'TEST_CASE_DEVELOPMENT'">
        <nz-form-item>
            <nz-form-label [nzSpan]="5"> Total Testcase</nz-form-label>
            <nz-form-control [nzSpan]="16">
                <input nz-input formControlName="totalTestcase" type="number" />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="editData && editData.id">
            <nz-form-label [nzSpan]="5"> Finished Testcase</nz-form-label>
            <nz-form-control [nzSpan]="16">
                <input nz-input formControlName="finishedTestcase" type="number" />
            </nz-form-control>
        </nz-form-item>
    </ng-container>

    <nz-form-item>
        <nz-form-label [nzSpan]="5"> Description</nz-form-label>
        <nz-form-control [nzSpan]="16">
            <textarea rows="5" nz-input formControlName="description" class="textarea-input" trim="blur"></textarea>
        </nz-form-control>
    </nz-form-item>
</form>
