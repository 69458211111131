import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@app/interfaces/sys/auth';
import { Menu } from '@app/interfaces/sys/menu';
import { TokenService } from '@services/sys/token.service';

import { NzIconService } from 'ng-zorro-antd/icon';
import { ICONS } from '../../../style-icons';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { getMenu } from './menu';

/**
 * Used for application startup
 * Generally used to get the basic data of the application, like: Menu Data, User Data, etc.
 */
@Injectable()
export class StartupService {
    constructor(iconSrv: NzIconService, private injector: Injector, private tokenService: TokenService) {
        iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
    }

    private viaHttp(resolve: any, reject: any): void {
        const tokenData: Auth = this.tokenService.get();
        if (!tokenData?.token) {
            this.injector.get(Router).navigateByUrl('/login');
            resolve({});
            return;
        }

        const userPermissions = this.tokenService.getUserPermissions();
        const expiredPermissions = this.tokenService.getExpiredPermissions();
        const menus = getMenu(userPermissions, expiredPermissions, tokenData.roleNames);
        const firstLink = this.getFirstLink(menus);
        resolve({
            firstLink,
            menus,
        });
    }

    getFirstLink(menus: Menu[]): string {
        const firstMenu = menus.find(item => !item?.hide && !item?.disabled);
        if (!firstMenu) {
            return '/';
        }
        if (firstMenu?.children && firstMenu.children.length > 0) {
            const first = firstMenu?.children?.find(item => !item?.hide && !item?.disabled);
            return first?.link ?? '/';
        }

        return firstMenu?.link || '/';
    }

    load(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.viaHttp(resolve, reject);
        });
    }
}
