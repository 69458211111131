import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectOption } from '@app/interfaces/rm/matrix';

@Component({
    selector: 'app-overflow-text',
    templateUrl: './overflow-text.component.html',
    styleUrls: ['./overflow-text.component.less'],
})
export class OverflowTextComponent implements OnInit {
    @ViewChild('overflowText') overflowText: ElementRef | undefined;
    @ViewChild('optionEl') optionEl: ElementRef | undefined;
    @ViewChild('spanBox') spanBox: any;
    @Output() change = new EventEmitter();
    @Input() item!: SelectOption;
    @Input() index!: number;
    @Input() type = 'select';
    @Input() options: SelectOption[] = [];
    @Input() allowSelectShow?: boolean = true;
    overflowWidth = 0;
    showTotal = 0;
    showNum = 0;
    timer: number | null | undefined;
    contentWidth = 0;

    constructor() {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.initWidth();
        }, 500);
    }

    @HostListener('window:resize')
    _resize(): void {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = window.setTimeout(() => {
            this.initWidth();
        }, 100);
    }

    getTextWidth(text: string, font: number): number {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const style = window.getComputedStyle(document.body);
        context!.font = font + 'px ' + style.fontFamily;
        const metrics = context!.measureText(text);
        return metrics.width;
    }

    initWidth(): void {
        this.overflowWidth = 0;
        const spanElWidth = this.spanBox?.nativeElement?.scrollWidth;
        if (!this.contentWidth) {
            this.contentWidth = this.type === 'select' ? this.getTextWidth(this.item.name, 14) : spanElWidth;
        }
        this.showTotal = 0;
        this.showNum = 0;
        const maxWidth = this.overflowText?.nativeElement.clientWidth;
        // 12 arrow width
        const boxWidth = this.type === 'select' ? maxWidth - 12 : maxWidth;
        this.overflowWidth = this.contentWidth - boxWidth;
        this.showTotal = Number(((boxWidth / 9) - 2).toFixed(0));
        this.showNum = Number(((this.showTotal / 2 ) - 2).toFixed(0));
    }


    changeSelect(id: number, i: number): void {
        this.change.emit({ id, i });
    }
}


