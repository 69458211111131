import {
    AlipayCircleOutline,
    ApiOutline,
    AppstoreAddOutline,
    AppstoreOutline,
    ArrowDownOutline,
    AuditOutline,
    BarChartOutline,
    BookOutline,
    BorderLeftOutline,
    BorderRightOutline,
    CaretRightOutline,
    ClusterOutline,
    CloudOutline,
    CloseSquareFill,
    ContainerOutline,
    ControlOutline,
    CopyrightOutline,
    CustomerServiceOutline,
    DashboardOutline,
    DatabaseFill,
    DatabaseOutline,
    DeleteFill,
    DeleteOutline,
    DesktopOutline,
    DingdingOutline,
    DislikeOutline,
    DownloadOutline,
    FolderOpenOutline,
    ForkOutline,
    FrownOutline,
    FullscreenExitOutline,
    FullscreenOutline,
    GithubOutline,
    GlobalOutline,
    GoldOutline,
    HddOutline,
    HomeOutline,
    InsertRowBelowOutline,
    LaptopOutline,
    LikeOutline,
    LockOutline,
    LogoutOutline,
    MailOutline,
    MenuFoldOutline,
    MenuUnfoldOutline,
    MessageOutline,
    PartitionOutline,
    PlusCircleTwoTone,
    MinusCircleFill,
    MinusCircleTwoTone,
    MinusCircleOutline,
    MinusOutline,
    MinusSquareFill,
    MoreOutline,
    NodeExpandOutline,
    NodeIndexOutline,
    PauseOutline,
    PayCircleOutline,
    PieChartOutline,
    PlaySquareOutline,
    PlusCircleOutline,
    PlusOutline,
    PrinterOutline,
    QuestionOutline,
    RadarChartOutline,
    RocketOutline,
    SafetyCertificateOutline,
    ScanOutline,
    SelectOutline,
    SettingFill,
    SettingOutline,
    ShareAltOutline,
    ShoppingCartOutline,
    SlidersOutline,
    SnippetsOutline,
    SolutionOutline,
    SoundOutline,
    StarOutline,
    StopFill,
    SyncOutline,
    TagsOutline,
    TagsTwoTone,
    TaobaoCircleOutline,
    TaobaoOutline,
    TeamOutline,
    ToolOutline,
    TrophyOutline,
    UsbOutline,
    UserAddOutline,
    UsergroupAddOutline,
    UserOutline,
    WeiboCircleOutline,
    WifiOutline,
    QuestionCircleTwoTone,
    ClockCircleOutline,
    FileProtectOutline,
    FileSearchOutline,
    MobileOutline,
    MobileFill,
    MobileTwoTone,
    FundProjectionScreenOutline,
    FundFill,
    FundOutline,
    FundTwoTone,
    FundViewOutline,
    AppstoreFill,
    AppstoreTwoTone,
    ExpandAltOutline,
    ExpandOutline,
    RedoOutline,
    UndoOutline,
    HomeTwoTone,
    UnorderedListOutline,
    PlusSquareOutline,
    MinusSquareOutline,
    DisconnectOutline
} from '@ant-design/icons-angular/icons';

export const ICONS_AUTO = [
    AlipayCircleOutline,
    ApiOutline,
    AppstoreOutline,
    ArrowDownOutline,
    BookOutline,
    BorderLeftOutline,
    BorderRightOutline,
    CaretRightOutline,
    ClusterOutline,
    CloudOutline,
    CloseSquareFill,
    CopyrightOutline,
    CustomerServiceOutline,
    DashboardOutline,
    DatabaseOutline,
    DingdingOutline,
    DislikeOutline,
    DownloadOutline,
    ForkOutline,
    FrownOutline,
    FullscreenExitOutline,
    FullscreenOutline,
    GithubOutline,
    GlobalOutline,
    GoldOutline,
    HddOutline,
    LaptopOutline,
    LikeOutline,
    LockOutline,
    LogoutOutline,
    MailOutline,
    MenuFoldOutline,
    MenuUnfoldOutline,
    MessageOutline,
    PayCircleOutline,
    PieChartOutline,
    PrinterOutline,
    RocketOutline,
    ScanOutline,
    SettingOutline,
    ShareAltOutline,
    ShoppingCartOutline,
    SoundOutline,
    StarOutline,
    StopFill,
    TaobaoCircleOutline,
    TaobaoOutline,
    TeamOutline,
    ToolOutline,
    TrophyOutline,
    UsbOutline,
    UserOutline,
    WeiboCircleOutline,
    SlidersOutline,
    SnippetsOutline,
    WifiOutline,
    DesktopOutline,
    HomeOutline,
    BarChartOutline,
    UserAddOutline,
    DatabaseFill,
    UsergroupAddOutline,
    InsertRowBelowOutline,
    SelectOutline,
    DeleteFill,
    SyncOutline,
    MinusOutline,
    MinusSquareFill,
    FolderOpenOutline,
    ControlOutline,
    SolutionOutline,
    AuditOutline,
    SafetyCertificateOutline,
    PauseOutline,
    PartitionOutline,
    PlusOutline,
    DeleteOutline,
    AppstoreAddOutline,
    PlusCircleTwoTone,
    MinusCircleFill,
    MinusCircleTwoTone,
    MinusCircleOutline,
    NodeExpandOutline,
    NodeIndexOutline,
    PlaySquareOutline,
    PlusCircleOutline,
    RadarChartOutline,
    ContainerOutline,
    SettingFill,
    MoreOutline,
    QuestionOutline,
    TagsOutline,
    TagsTwoTone,
    QuestionCircleTwoTone,
    ClockCircleOutline,
    FileProtectOutline,
    FileSearchOutline,
    MobileOutline,
    MobileFill,
    MobileTwoTone,
    FundProjectionScreenOutline,
    FundFill,
    FundOutline,
    FundTwoTone,
    FundViewOutline,
    AppstoreFill,
    AppstoreTwoTone,
    ExpandAltOutline,
    ExpandOutline,
    RedoOutline,
    UndoOutline,
    HomeTwoTone,
    UnorderedListOutline,
    PlusSquareOutline,
    MinusSquareOutline,
    DisconnectOutline
];
