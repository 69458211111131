import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Version, VersionInfo } from '@app/interfaces/sys/version';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    private baseUrl = '/version';
    constructor(private http: HttpClient) {
    }

    getVersion(): Promise<Version> {
        return this.http.get<Version>(`${this.baseUrl}`).toPromise();
    }

    getVersionConfig(): Promise<string> {
        return this.http.get<string>(`${this.baseUrl}/config`).toPromise();
    }

    getSwitchVersion(hideErrorMessage: string = 'true', showEmailError: string = 'false'): Promise<string> {
        return this.http.get<string>(`/switchservice/api/v1/version/config`, {
            headers: {
                hideErrorMessage,
                showEmailError
            },
        }).toPromise();
    }

    getVersionInfo(data?: { flag: boolean }): Promise<VersionInfo> {
        return this.http.get<VersionInfo>(`${this.baseUrl}/info`, {
            params: data ? Object.assign(data) : null,
        }).toPromise();
    }
}
