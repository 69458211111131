import { Menu } from '@app/interfaces/sys/menu';
import { environment } from '@env/environment';

const menus: Menu[] = [
    {
        key: 'dashboard',
        text: 'Dashboard',
        link: '/dashboard',
        icon: { type: 'icon', value: 'radar-chart' },
        permissions: ['Dashboard View'],
        categoryKey: 'dashboard',
    },
    {
        key: 'home',
        text: 'Home',
        link: '/home',
        icon: { type: 'icon', value: 'home' },
        permissions: ['Home View'],
        categoryKey: 'system',
    },
    {
        key: 'systemLog',
        text: 'System Log',
        link: '/system-log',
        icon: { type: 'icon', value: 'book' },
        permissions: ['System Log View'],
        categoryKey: 'system',
    },
    {
        key: 'auditLog',
        text: 'Audit Log',
        link: '/audit-log',
        icon: { type: 'icon', value: 'container' },
        permissions: ['Audit Log View'],
        categoryKey: 'system'
    },
    {
        key: 'role',
        text: 'Role',
        link: '/role',
        icon: { type: 'icon', value: 'solution' },
        permissions: ['Role View'],
        categoryKey: 'system'
    },
    {
        key: 'accounts',
        text: 'Accounts',
        link: '/accounts',
        icon: { type: 'icon', value: 'usergroup-add' },
        permissions: ['Account View'],
        categoryKey: 'system'
    },
    {
        key: 'licence',
        text: 'Licence',
        link: '/licence',
        icon: { type: 'icon', value: 'file-protect' },
        permissions: ['Licence View'],
        categoryKey: 'system'
    },
    {
        key: 'licenceManager',
        text: 'Licence',
        link: '/licence-manager',
        icon: { type: 'icon', value: 'file-protect' },
        permissions: ['Licence Manager View'],
        categoryKey: 'system'
    },
    {
        key: 'notification',
        text: 'Notification',
        link: '/notification/notification',
        icon: { type: 'icon', value: 'message' },
        permissions: ['Notification View'],
        categoryKey: 'system'
    },
    {
        key: 'notificationSetting',
        text: 'Settings',
        link: '/notification-setting',
        icon: { type: 'icon', value: 'usergroup-add' },
        permissions: ['Notification Setting View', 'Resource Reservation Setting View'],
        categoryKey: 'system'
    },
    {
        key: 'createAdmin',
        text: 'Create Admin',
        link: '/create-admin',
        icon: { type: 'icon', value: 'user-add' },
        permissions: [],
    },
    {
        key: 'systemConfiguration',
        text: 'System Configuration',
        link: '/system-config',
        icon: { type: 'icon', value: 'sliders' },
        permissions: [],
    },
    {
        key: 'screenMirror',
        text: 'Screen Mirror',
        link: '/screen-mirror',
        icon: { type: 'icon', value: 'book' },
        permissions: ['Screen Mirror View'],
    },
    {
        key: 'systemDatabase',
        text: 'System Database',
        link: '/system-database',
        icon: { type: 'icon', value: 'database', theme: 'fill' },
        permissions: [],
    },
    {
        key: 'systemEmail',
        text: 'System Email',
        link: '/system-email',
        icon: { type: 'icon', value: 'svg:email' },
        permissions: [],
    },
    {
        key: 'teams',
        text: 'Teams',
        link: '/team/view',
        icon: { type: 'icon', value: 'svg:team' },
        permissions: ['Team View'],
    },
    {
        key: 'application',
        text: 'application',
        link: '/application',
        icon: { type: 'icon', value: 'hdd' },
        permissions: [],
    },
    {
        key: 'project',
        text: 'Project',
        link: '/project',
        icon: { type: 'icon', value: 'fork' },
        permissions: ['Project View'],
    },
    {
        key: 'teamList',
        text: 'Team List',
        link: '/team/list/all',
        icon: { type: 'icon', value: 'svg:team' },
        permissions: [],
    },
    {
        key: 'ongoingTask',
        text: 'Ongoing Task',
        link: '/task/ongoing',
        icon: { type: 'icon', value: 'bulb' },
        permissions: ['Ongoing Task View'],
    },
    {
        key: 'preparationTask',
        text: 'Preparation Task',
        link: '/task/preparation',
        icon: { type: 'icon', value: 'bulb' },
        permissions: ['Preparation Task View'],
    },
    // {
    //     key: 'notification',
    //     text: 'Notification',
    //     link: '/notification',
    //     icon: { type: 'icon', value: 'message' },
    //     permissions: ['Notification View'],
    // },
    {
        key: 'calendar',
        text: 'Calendar',
        link: '/calendar',
        icon: { type: 'icon', value: 'message' },
        permissions: [],
    },
    {
        key: 'utilization',
        text: 'Utilization',
        link: '/utilization',
        icon: { type: 'icon', value: 'bar-chart' },
        permissions: [],
    },
    {
        key: 'lmResource',
        text: 'Resource',
        link: '/resource',
        icon: { type: 'icon', value: 'appstore' },
        permissions: [],
    },
    {
        key: 'location',
        text: 'Location',
        link: '/location',
        icon: { type: 'icon', value: 'svg:location' },
        permissions: [],
    },
    {
        key: 'psaManagement',
        text: 'PSA Management',
        link: '/psa-management',
        icon: { type: 'icon', value: 'insert-row-below' },
        permissions: [],
    },
    {
        key: 'simulatorManagement',
        text: 'Simulator Management',
        link: '/simulator',
        icon: { type: 'icon', value: 'control' },
        permissions: ['Simulator View'],
        categoryKey: 'HAServer',
    },
    // rm menu
    {
        key: 'resource',
        text: 'Resource Management',
        link: '/chamber-management',
        icon: { type: 'icon', value: 'appstore' },
        permissions: ['Chamber View', 'Radio View', 'Attenuator View', 'Scanner View', 'UE Server View', 'Wireline View'],
        categoryKey: 'labManagement',
    },
    {
        key: 'resourceAssignment',
        text: 'Resource Assignment',
        link: '/resource-assignment',
        icon: { type: 'icon', value: 'appstore-add' },
        permissions: ['Resource Assignment Chamber View', 'Resource Assignment Radio View', 'Resource Assignment Engineer View',
            'Resource Assignment UE View'],
        categoryKey: 'labManagement',
    },
    {
        key: 'resourceReservation',
        text: 'Resource Reservation',
        link: '/resource-reservation',
        icon: { type: 'icon', value: 'calendar' },
        permissions: ['Calendar View'],
        categoryKey: 'labManagement',
    },
    {
        key: 'resourceRequest',
        text: 'Resource Request',
        link: '/resource-request/pending',
        icon: { type: 'icon', value: 'insert-row-below' },
        permissions: ['Request and Approvals'],
        categoryKey: 'labManagement',
    },
    {
        key: 'deviceControl',
        text: 'Device Control',
        link: '/device-control',
        icon: { type: 'icon', value: 'sliders' },
        permissions: ['Device Control View'],
        categoryKey: 'resourceControl',
    },
    {
        key: 'matrixMonitor',
        text: 'Attenuator Monitor',
        link: '/matrix-monitor',
        icon: { type: 'icon', value: 'sliders' },
        permissions: ['Attenuator Monitor View'],
        categoryKey: 'resourceControl',
    },
    {
        key: 'radioTesting',
        text: 'Connection Control',
        link: '/radio-testing',
        icon: { type: 'icon', value: 'sliders' },
        permissions: ['Radio Testing View'],
        categoryKey: 'resourceControl',
    },
    {
        key: 'labMonitor',
        text: 'RF Monitoring',
        link: '/rf-monitoring',
        icon: { type: 'icon', value: 'control' },
        permissions: ['RF Monitoring View'],
        categoryKey: 'resourceControl',
    },
    {
        key: 'ueControl',
        text: 'UE Control',
        link: '/ue-control',
        icon: { type: 'icon', value: 'book' },
        permissions: ['UE Control View'],
        categoryKey: 'resourceControl'
    },
    {
        key: 'advancedMobility',
        text: 'Advanced Mobility',
        link: '/advanced-mobility',
        icon: { type: 'icon', value: 'desktop' },
        permissions: ['Advanced Mobility View'],
        categoryKey: 'testOrchestration'
    },
    {
        key: 'e2eScripting',
        text: 'E2E Scripting',
        link: '/e2e-scripting',
        icon: { type: 'icon', value: 'mobile' },
        permissions: ['E2E Scripting View'],
        categoryKey: 'testOrchestration'
    },
    {
        key: 'networkImpairment',
        text: 'Network Impairment',
        link: '/network-impairment',
        icon: { type: 'icon', value: 'desktop' },
        permissions: ['Network Impairment View'],
        categoryKey: 'resourceControl'
    },
    {
        key: 'opticalSwitch',
        text: 'Optical Switch',
        link: '/optical-switch',
        icon: { type: 'icon', value: 'cluster' },
        permissions: ['Optical Switch View'],
        categoryKey: 'resourceControl'
    },
    // ha
    // {
    //     key: 'backupManager',
    //     text: 'Backup Manager',
    //     link: '/backup-manager',
    //     icon: { type: 'icon', value: 'clock-circle' },
    //     permissions: ['Backup Scheduler View', 'Backup Archive View'],
    // },
];

let userPermissions: string[] = [];
let userRoleNames: string[] = [];

const setMenu = (currentMenu: Menu[]): void => {
    const enablePages: string[] = environment.enablePages || [];
    currentMenu.forEach((item: Menu) => {
        if (item.children && item.children.length > 0) {
            setMenu(item.children);
        }
        if (item.children && item.children.length === 0) {
            item.hide = true;
            return;
        }

        if (item.key && !enablePages.includes(item.key)) {
            item.hide = true;
            return;
        }
        if (!item.permissions || item.permissions.length === 0) {
            item.hide = false;
            item.disabled = true;
            return;
        }

        if (item.permissions.some((permission: string) => userPermissions.includes(permission))) {
            item.hide = false;
            item.disabled = false;
            return;
        }

        // item.hide = true;
        item.disabled = true;
    });
};

export function getMenu(permissions: string[], expPermissions: string[], roleNames: string[] = []): Menu[] {
    userPermissions = [...permissions];
    userRoleNames = [...roleNames];
    setMenu(menus);
    return menus;
}
