<div class="input-container">
    <div #selectRef >
        <nz-select
            [ngStyle]="{
                'width': autoWidth ? 'auto' : '100%'
            }"
            [nzDropdownMatchSelectWidth]="false"
            class="form-auto-select"
            nzShowSearch
            [nzPlaceHolder]="placeHolder"
            [nzMode]="mode"
            [nzShowArrow]="true"
            [nzLoading]="loading"
            [(ngModel)]="value"
            (ngModelChange)="changeValue()"
            [nzDropdownStyle]="{ width: selectMaxWidth + 'px'}">
            <nz-option *ngFor="let item of selectOptions" [nzLabel]="item.label" [nzValue]="item.value" [nzDisabled]="item.disabled"></nz-option>
        </nz-select>
    </div>
</div>
