import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '@app/interfaces/sys/dict';

@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['./form-input.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormInputComponent),
            multi: true,
        },
    ],
})
export class FormInputComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() placeholder = '';
    @Input() readonly = false;
    @Input() type = 'input';
    @Input() autocomplete = '';
    @Input() options: any[] = [];
    @Input() labelKey = 'label';
    @Input() valueKey = 'value';
    @Output() change = new EventEmitter();
    value = '';
    selectOptions: SelectOption[] = [];

    onChange: any = () => {
    }
    onTouch: () => void = () => null;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            this.selectOptions = [...this.options];
        }
    }

    ngOnInit(): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    clearValue(): void {
        this.value = '';
        this.changeValue();
    }

    changeValue(): void {
        this.onChange(this.value);
        this.change.emit();
    }

    onInput(e: Event): void {
        const value = (e.target as HTMLInputElement).value;
        if (!value) {
            this.selectOptions = [...this.options];
            return;
        }
        this.selectOptions = this.options.filter(it => it.label.toLowerCase().includes(value.toLowerCase()));
    }
}
