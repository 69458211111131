import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification, ResponseData } from '@app/interfaces/lm/notification';
import { NotificationService } from '@services/lm/notification.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-notification-detail',
    templateUrl: './notification-detail.component.html',
    styleUrls: ['./notification-detail.component.less'],
})
export class NotificationDetailComponent {
    @Input() canResponse = true;

    @Output() responseSuccess = new EventEmitter();

    isVisible = false;
    detail: Notification = {} as Notification;

    responseContent = '';
    submitLoading = false;

    get projectName(): string {
        const projectDetail = this.detail?.detail;
        if (!projectDetail) {
            return '';
        }
        const projectInfo = JSON.parse(projectDetail);
        return projectInfo?.projectName ?? '';
    }

    get needResponse(): boolean {
        return this.detail?.responseStatus === 'WAITING' && this.canResponse;
    }

    constructor(private message: NzMessageService,
                private notificationService: NotificationService) {}

    open(item: Notification): void {
        console.log('dialog open', item);
        this.isVisible = true;
        this.detail = {
            ...item,
        };
    }

    close(): void {
        console.log('dialog close');
        this.isVisible = false;
        this.responseContent = '';
    }

    async handleResponse(): Promise<void> {
        console.log('handleResponse', this.responseContent);
        const responseContent = this.responseContent?.trim();
        if (responseContent.length === 0) {
            this.message.warning('Response is mandatory.');
            return ;
        }
        const data: ResponseData = {
            responseContent,
            id: this.detail.id,
            responseStatus: 'ACCEPT'
        };
        try {
            this.submitLoading = true;
            await this.notificationService.response(data);
            this.responseSuccess.emit();
            this.close();
        } finally {
            this.submitLoading = false;
        }
    }
}
