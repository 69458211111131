import { EventEmitter, Injectable } from '@angular/core';
import { CalendarData } from '@app/interfaces/lm/project';
import { Auth } from '@app/interfaces/sys/auth';
import { Feature } from '@app/interfaces/sys/permission';
import { STColumn } from '@app/interfaces/sys/st';
import { PermissionService } from '@services/sys/permission.service';
import { TokenService } from '@services/sys/token.service';
import * as dayjs from 'dayjs';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface MessageConfig {
    key: string;
    value: string;
    color?: string;
}

interface Message {
    [key: string]: MessageConfig[];
}

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    layout: {
        y: number;
    } = {
        y: 0,
    };

    message: Message = {
        PROJECT_STATUS: [
            {
                key: 'ON_GOING',
                value: 'Start',
                color: 'status-primary',
            },
            {
                key: 'IDLE',
                value: 'Idle',
                color: 'status-purple',
            },
            {
                key: 'FINISHED',
                value: 'Sign Off',
                color: 'status-success',
            },
            {
                key: 'ARCHIVED',
                value: 'Archived',
                color: 'status-danger',
            },
        ],
    };
    globalEvent = new EventEmitter();

    private collapseMenuSubject = new Subject<boolean>();
    collapseMenuChange$ = this.collapseMenuSubject.asObservable();

    constructor(private tokenService: TokenService,
                private permissionService: PermissionService) {
    }

    setLayout(): void {
        const y = document.body.clientHeight - 261;
        this.layout = {
            ...this.layout,
            y,
        };
    }

    transformValue(type: string, key: string, field: string = 'value'): string {
        const info = this.message[type]?.find((item) => item.key === key);
        if (!info) {
            return '';
        }
        return info[field as keyof MessageConfig] ?? '';
    }

    hasPermission(permissions: string[]): boolean {
        const userPermissions = this.tokenService.getUserPermissions();


        if (!permissions || permissions.length === 0) {
            return true;
        }

        return permissions.some((p: string) => userPermissions.includes(p));
    }

    hasExpiredPermission(permissions: string[]): boolean {
        const expiredPermissions = this.tokenService.getExpiredPermissions();

        if (!permissions || permissions.length === 0) {
            return true;
        }

        return permissions.some((p: string) => expiredPermissions.includes(p));
    }

    initCalendar(startDate: string): Array<CalendarData[]> {
        const startDay = dayjs(startDate).startOf('M').startOf('w').toISOString();
        const endDay = dayjs(startDate).endOf('M').endOf('w').toISOString();
        const diffDay = dayjs(endDay).diff(dayjs(startDay), 'day');
        console.log('calendar day', diffDay);
        const data: CalendarData[][] = [];
        let item: CalendarData[] = [];
        const columnLength = 7;
        const rowLength = Math.ceil(diffDay / columnLength);
        let d = 0;
        new Array(rowLength).fill(null).forEach(() => {
            item = [];
            new Array(columnLength).fill(null).forEach(() => {
                item.push({
                    date: dayjs(startDay).add(d, 'day').toISOString(),
                });
                d++;
            });
            data.push(item);
        });
        return data;
    }

    submitPermission(
        requiredFields: string[],
        formData: {
            [key: string]: any;
        },
    ): boolean {
        if (!formData) {
            return false;
        }
        return !Object.keys(formData).some(
            (key) =>
                (requiredFields.includes(key) && (formData[key] === null || formData[key]?.length === 0)) || formData[key] === undefined,
        );
    }

    formatLogSize(size: number): string {
        if (size > 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(1) + ' MB';
        } else if (size > 52) {
            return (size / 1024).toFixed(1) + ' KB';
        }
        return size + ' byte';
    }

    sendGlobalEvent(name: string, value?: any): void {
        this.globalEvent.emit({ name, value });
    }

    watchGlobalEvent(name: string): Observable<any> {
        return this.globalEvent.pipe(
            filter((item) => item.name === name),
            map((item) => item.value)
        );
    }

    toggleMenuCollapse(status: boolean) {
        this.collapseMenuSubject.next(status);
    }

    getTextWidth (text: string, offset = 30, fontPixel: number = 14) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const font: string = `${fontPixel}px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif`
        if(context) {
            context.font = font;
            const metrics = context.measureText(text);
            // return Math.round(metrics.width) + offset > 400 ? 400 : Math.round(metrics.width) + offset;
            return Math.round(metrics.width) + offset;
        }
        return -1;
    }

    calculateColumnWidth(data: any, key: string) {
        const getLongest = (data: any, key: string): string => {
            return data.reduce((longest: string, cur: any) => cur[key]?.length > longest.length ? cur[key] : longest, '');
        }

        return data.reduce((longest: string, cur: any) => {
            if (key === 'chamber') {
                let longChamber = getLongest(cur.chamberVOS, 'name');
                return longChamber?.length > longest?.length ? longChamber : longest;
            }
            if (key === 'radio') {
                let longRadio = getLongest(cur.radioVOS, 'name');
                return longRadio?.length > longest?.length ? longRadio : longest;
            }

            if (key === 'roleName') {
                let longRoles = cur.roleNames.join(',');
                return longRoles?.length > longest?.length ? longRoles : longest;
            }

            if (key === 'fullName') {
                let longUsername = `${cur.fullName} (${cur.username})`;
                return longUsername?.length > longest?.length ? longUsername : longest;
            }

            if (key === 'chamberNames') {
                let chamberNames = cur.chamberNames.join(',');
                return chamberNames?.length > longest?.length ? chamberNames : longest;
            }

            return cur[key]?.length > longest.length ? cur[key] : longest;
        }, '');
    }

    updateColumnConfig(data: any, columns: STColumn[], keys: string[]): STColumn[] {
        if (!data || data.length === 0 || !keys || !keys.length) {
            return columns;
        }
        return columns.map(column => {
            const title = column.title || '';
            keys.forEach(key => {
                const longest = this.getTextWidth(this.calculateColumnWidth(data, key));
                const nameWidth = this.getTextWidth(title);
                if (column.index === key && longest > nameWidth) {
                    column.width = `${longest}px`;
                }
            });
            return column;
        });
    }

    getDropdownWidth(data: any[], key: string, defaultWidth: number = 300) {
        if(!data || !data.length) {
            return defaultWidth;
        }

        const longString = this.calculateColumnWidth(data, key);
        const width = this.getTextWidth(longString.slice(0, 50)) + 60;
        console.log('#### width', width, '#### default', defaultWidth, '#### Result', width > defaultWidth ? width : defaultWidth);
        return width > defaultWidth ? width : defaultWidth;
    }
}
