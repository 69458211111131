import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { STColumn, STData, STPage } from '@app/interfaces/sys/st';
import { ApplicationService } from '@services/lm/application.service';
import { Application } from '@app/interfaces/lm/application';
import { ApplicationDetailComponent } from './components/application-detail/application-detail.component';

@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
})
export class ApplicationComponent implements OnInit, OnChanges {
    @Input() type = '';
    @ViewChild('applicationDetail') applicationDetail: ApplicationDetailComponent | undefined;
    loading = false;
    columns: STColumn[] = [
        {
            title: 'Project name',
            index: 'projectName',
            type: 'link',
            click: (record: STData) => {
                console.log('record', record);
                this.router.navigate(['/project/detail', record.id]);
            },
        },
        {
            title: 'Tester',
            index: 'testerName',
        },
        {
            title: 'Start Date',
            index: 'startDate',
            type: 'date',
            dateFormat: 'yyyy-MM-dd',
        },
        {
            title: 'End Date',
            index: 'endDate',
            type: 'date',
            dateFormat: 'yyyy-MM-dd',
        },
        {
            title: 'Action',
            render: 'action',
        },
    ];
    data: Application[] = [];
    pages: STPage = {
        show: true,
    };

    constructor(private applicationService: ApplicationService, private router: Router) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type) {
            this.fetchData();
        }
    }

    async fetchData(): Promise<void> {
        try {
            this.loading = true;
            const query = {
                type: this.type,
            };
            this.data = await this.applicationService.find(query);
        } finally {
            this.loading = false;
        }
    }

    handleDetail(row: Application): void {
        console.log('handleDetail', row);
        this.applicationDetail?.open(row);
    }
}
