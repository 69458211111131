import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-overflow-tooltip',
    templateUrl: './overflow-tooltip.component.html',
    styleUrls: ['./overflow-tooltip.component.less'],
})
export class OverflowTooltipComponent {
    @Input() text: string | undefined | null;
    @Input() className?: string;
    @ViewChild('tooltipText') tooltipText: ElementRef | undefined;
    showTooltip = false;

    timeout: number | null = null;

    initTooltip(): void {
        const scrollWidth = this.tooltipText?.nativeElement.scrollWidth;
        const clientWidth = this.tooltipText?.nativeElement.clientWidth;
        this.showTooltip = (this.className && clientWidth === scrollWidth) || clientWidth < scrollWidth;
    }

    mouseEnter(): void {
        this.initTooltip();
    }
}
