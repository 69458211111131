<div class="legend-container">
    <div
        *ngFor="let item of legends"
        class="legend-item"
    >
        <div *ngIf="item.isHalf" class="legend-color half-triangle"></div>
        <div *ngIf="!item.isHalf" class="legend-color" [ngStyle]="{ background: item.color }"></div>
        <div class="legend-label">{{ item.label }}</div>
    </div>
</div>
