import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { SettingsService } from '@services/sys/settings.service';

@Directive({
    selector: '[appPermission]',
})
export class PermissionDirective {
    @Input('appPermission')
    public set hasPermission(permission: string[]) {
        const hasPermission = this.settingService.hasPermission(permission);
        if (hasPermission) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private renderer: Renderer2,
        private viewContainer: ViewContainerRef,
        private settingService: SettingsService
    ) {}
}
