<div *ngFor="let reservationItem of data, let j = index" class="popover-item">
    <div class="label">
        <div *ngIf="reservationItem?.status === 'PENDING' || reservationItem.approvedUserId">
            <span class="label-text">Requestor(s) Name:</span>{{ reservationItem.userName || '' }}
        </div>
        <ng-container *ngIf="reservationItem?.status !== 'PENDING'">
            <!-- request approved -->
            <div *ngIf="reservationItem.approvedUserId">
                <span class="label-text">Approved by:</span>{{ reservationItem.approvedUserName }}
            </div>
            <!-- reserved -->
            <div *ngIf="!reservationItem.approvedUserId">
                <span class="label-text">Reserved by:</span>{{ reservationItem.userName }}
            </div>
        </ng-container>
        <div>
            <span class="label-text">From:</span>{{ reservationItem.startTime | date: 'YYYY-MM-dd HH:mm:ss' }}
        </div>
        <div>
            <span class="label-text">To:</span>{{ reservationItem.endTime | date: 'YYYY-MM-dd HH:mm:ss' }}
        </div>
        <div *ngIf="reservationItem?.description">
            <span class="label-text">Description:</span>{{ reservationItem.description }}
        </div>

        <div *ngIf="reservationItem?.status === 'PENDING' && canLinkTo" class="request-box">
            <a (click)="toRequest(reservationItem)">Pending Resource Request</a>
            <div class="status-change-container">
                <button (click)="changeRequestStatus(reservationItem, 'APPROVED')" nz-button nzType="link" 
                nzSize="small" class="status-change-button" [nzLoading]="approveButtonLoading">
                Approve
                </button>
                <button (click)="changeRequestStatus(reservationItem, 'REJECTED')" nz-button nzType="link"
                nzSize="small" class="status-change-button" [nzLoading]="rejectButtonLoading">
                Reject
                </button>
            </div>
        </div>
    </div>
</div>
