import { Feature } from '@app/interfaces/sys/permission';

const featureGroup: any[] = [
    {
        name: 'System',
        subFeatures: [
            {
                name: 'Dashboard',
                key: 'dashboard',
            },
            {
                name: 'Accounts',
                key: 'accounts',
            },
            {
                name: 'Role',
                key: 'role',
            },
            {
                name: 'Licence',
                key: 'licence',
            },
            {
                name: 'Licence Manager',
                key: 'licenceManager',
            },
            {
                name: 'Audit Log',
                key: 'auditLog',
            },
            {
                name: 'System Log',
                key: 'systemLog',
            },
            {
                name: 'Profile Settings',
                key: 'profileSetting',
            },
        ],
    },
    {
        name: 'Resource',
        subFeatures: [
            {
                name: 'Resource',
                key: 'resource',
                subFeatures: [
                    {
                        name: 'NE Control',
                        key: 'neControl',
                        subFeatures: [{
                            name: 'NE Control',
                            key: 'neControl',
                        }],
                    },
                ],
            },
            {
                name: 'Resource Assignment',
                key: 'resourceAssignment'
            },
            {
                name: 'Attenuator Monitor',
                key: 'matrixMonitor',
            },
            {
                name: 'Device Control',
                key: 'deviceControl',
            }
        ],
    },
    {
        name: 'RF Monitor',
        subFeatures: [
            {
                name: 'RF Monitoring',
                key: 'labMonitor',
            },
        ],
    },
    {
        name: 'Simulators',
        subFeatures: [
            {
                name: 'Simulator Management',
                key: 'simulatorManagement',
            },
        ],
    },
    {
        name: 'Advanced Mobility',
        subFeatures: [
            {
                name: 'Advanced Mobility',
                key: 'advancedMobility',
            },
        ],
    },
    {
        name: 'UE Control',
        subFeatures: [
            {
                name: 'UE Control',
                key: 'ueControl',
            },
        ],
    },
    {
        name: 'Lab Management',
        subFeatures: [
            {
                name: 'Lab Management',
                key: 'labManagement'
            },
            {
                name: 'Resource Reservation',
                key: 'resourceReservation',
            },
            {
                name: 'Resource Request',
                key: 'resourceRequest'
            }
        ]
    },
    {
        name: 'Network Impairment',
        subFeatures: [
            {
                name: 'Network Impairment',
                key: 'networkImpairment',
            },
            {
                name: 'Wireline Management',
                key: 'wirelineManagement',
            },
        ],
    },
    {
        name: 'Optical Switch',
        subFeatures: [
            {
                name: 'Optical Switch',
                key: 'opticalSwitch',
            },
            {
                name: 'Wireline Management',
                key: 'wirelineManagement',
            },
        ],
    },
    {
        name: 'High Availability Solution',
        subFeatures: [
            {
                name: 'Notification',
                key: 'notification',
            },
            {
                name: 'Notification Setting',
                key: 'notificationSetting',
            },
        ],
    },
    {
        name: 'E2E Scripting',
        subFeatures: [
            {
                name: 'E2E Scripting',
                key: 'e2eScripting',
            },
        ],
    },
];

const deviceControlApis = [
    {
        id: '65a971c23479438cad31a0d5f10e657a',
        name: 'GET /matrices/hardware-types',
    },
    {
        id: 'f3c460068e3e4f9cbee25b3596fabce7',
        name: 'GET /chambers/list',
    },
    {
        id: '2f2bfdaa673c47cbb82c02d2f54b4fae',
        name: 'GET /matrices/chamber-connected',
    },
    {
        id: 'aefd4ea6cdef4402bb6d86416e2e57ff',
        name: 'GET /matrix-data/radio-testing',
    },
    {
        id: 'f726497df01944dca13a9aeb01d02636',
        name: 'PUT /matrix-data/attenuation',
    },
    {
        id: '77f97c8b6c7a4e389b5814e077872b23',
        name: 'PUT /matrix-data/on-off',
    },
    {
        id: '40213935722845ff81dee35a71d7534b',
        name: 'PUT /matrix-data/max-attenuation',
    },
    {
        id: '1dae088d417940e9b2c0445358fdb209',
        name: 'PUT /matrix/data/selected',
    },
    {
        id: '489c3caaa15d4aeda0e22b4e2335abbb',
        name: 'GET /matrix-data/attenuation',
    },
    {
        id: '2037ef30aa8b451096d55732aa35bacd',
        name: 'GET /user-radios/port-users',
    },
    {
        id: 'bbf108a8129244028f215f54d2122776',
        name: 'GET /time',
    },
    {
        id: 'a4c31b84c0fb4f278120b1f941d75732',
        name: 'POST /matrix-data/attenuation/pre-check',
    },
];
const calendarViewApis = [
    {
        id: '781a9a60fbd040c9941ed90cff78a5d4',
        name: 'GET /calendar/list',
    },
    {
        id: 'bd1d5235809649699f3fa45d7775009d',
        name: 'GET /calendar/list/self',
    },
    {
        id: '359bbb584eb044868a13ec8fd5e2f933',
        name: 'GET /calendar/approval-setting',
    },
    // use in radio testing and has calendar view judge
    {
        id: '52a01708ca03410d8655ca248cad7f1f',
        name: 'GET /chambers/selfCalendarChamberList'
    }
];

const calendarEditApis = [
    {
        id: '6209fc21492d4d7a83bdd85e1e46abc1',
        name: 'PUT /calendar/edit',
    },
    {
        id: '7ba5669711ee4e94a7ff7308b8726848',
        name: 'POST /calendar',
    },
    {
        id: '7de6fbc36a754ef68929b74ef55db8af',
        name: 'PUT /calendar/cancel',
    },
    {
        id: '94cd8ae30ccb4143aaefa1448968198a',
        name: 'DELETE /calendar',
    },
    {
        id: 'a172e4b440a7474fb86c8d42dd399392',
        name: 'PUT /calendar',
    },
    {
        id: '6209fc21492d4d7a83bdd85e1e46abc1',
        name: 'PUT /calendar/edit',
    },
];

const features: Feature[] = [
    {
        name: 'Home',
        key: 'home',
        children: [
            {
                name: 'Home View',
                apis: [],
            },
            {
                name: 'Project Calendar Card',
                apis: [
                    {
                        id: '5eed659c1fd54dba89cbbfece8c852e6',
                        name: 'GET /projects/all',
                    },
                ],
            },
            {
                name: 'Notification Card',
                apis: [
                    {
                        id: 'bfbfd7ca40b34da1b1331c895904af36',
                        name: 'GET /notices',
                    },
                    {
                        id: '53c78a883c084c7997659a258fee7e8a',
                        name: 'PUT /notices/response',
                    },
                    {
                        id: '4945a30272da475facaee932020a47fd',
                        name: 'PUT /notices/batch/readed',
                    },
                ],
            },
            {
                name: 'Latest Project Card',
                apis: [
                    {
                        id: '3244cd52e1564c77ac5ec1a347c9882d',
                        name: 'GET /projects',
                    },
                    // {
                    //     id: '4b2bca4d9b754044a93f719b0d6026f7',
                    //     name: 'GET /dicts',
                    // }
                ],
            },
        ],
    },
    {
        name: 'Dashboard',
        key: 'dashboard',
        children: [
            {
                name: 'Dashboard View',
                scope: 'All',
                apis: [
                    {
                        id: '87407a42859e407a9becaadd2ace3230',
                        name: 'GET /users/all-options',
                    },
                    {
                        id: '0b5dad9872c7476f9b724c1a7f42cb65',
                        name: 'GET /matrices/running',
                    },
                    {
                        id: '112770904ecb47a9906cdcbac7957211',
                        name: 'GET /chambers/all-options',
                    },
                    {
                        id: '05b7274a1c1b4719bee2a8ba4840e0ed',
                        name: 'GET /radios/all-options',
                    },
                    {
                        id: '15ae10dc31b444b383f0ebf43da69537',
                        name: 'GET /dashboards',
                        scope: 'All',
                    },
                ],
            },
            {
                name: 'Dashboard View',
                scope: 'Self',
                apis: [
                    {
                        id: '87407a42859e407a9becaadd2ace3230',
                        name: 'GET /users/all-options',
                    },
                    {
                        id: '0b5dad9872c7476f9b724c1a7f42cb65',
                        name: 'GET /matrices/running',
                    },
                    {
                        id: '112770904ecb47a9906cdcbac7957211',
                        name: 'GET /chambers/all-options',
                    },
                    {
                        id: '05b7274a1c1b4719bee2a8ba4840e0ed',
                        name: 'GET /radios/all-options',
                    },
                    {
                        id: '15ae10dc31b444b383f0ebf43da69537',
                        name: 'GET /dashboards',
                        scope: 'Self',
                    },
                ],
            },
        ],
    },
    {
        name: 'System Log',
        key: 'systemLog',
        children: [
            {
                name: 'System Log View',
                apis: [
                    {
                        id: '32e1b4ac5bcc4085aff3231eeee92d2a',
                        name: 'GET /log-files',
                    },
                    {
                        id: 'f5640af313794a7fbfa3f489bb250ff2',
                        name: 'GET /log-files/download',
                    },
                ],
            },
        ],
    },
    {
        name: 'UE Control',
        key: 'ueControl',
        children: [
            {
                name: 'UE Control View',
                scope: 'All',
                apis: [],
            },
            {
                name: 'UE Control View',
                scope: 'Self',
                apis: [],
            },
        ],
    },
    {
        name: 'Teams',
        key: 'teams',
        children: [
            {
                name: 'Team View',
                apis: [
                    {
                        id: 'af6bd62e9ae145648ea7b0252d869723',
                        name: 'GET /teams',
                    },
                ],
            },
            {
                name: 'Team Create',
                apis: [
                    {
                        id: 'a190db17e71f46d1bb7218007a8be05a',
                        name: 'POST /teams',
                    },
                ],
            },
            {
                name: 'Team Update',
                apis: [
                    {
                        id: '4b1613a3815d48fab279aa4c6d719987',
                        name: 'PUT /teams',
                    },
                ],
            },
            {
                name: 'Team Member View',
                apis: [
                    {
                        id: '542b2061fca74432be337878a543efb7',
                        name: 'GET /users',
                    },
                ],
            },
        ],
    },
    {
        name: 'Accounts',
        key: 'accounts',
        children: [
            {
                name: 'Account View',
                scope: 'All',
                apis: [
                    {
                        id: '542b2061fca74432be337878a543efb7',
                        name: 'GET /users',
                        scope: 'All',
                    },
                    {
                        id: '45b1e64d96f54091a35151ee57995d30',
                        name: 'GET /teams/all-options',
                    },
                    {
                        id: 'd746048a3bcd43c6a19fd019c5623afd',
                        name: 'GET /roles/all-options',
                    },
                ],
            },
            {
                name: 'Account View',
                scope: 'Team',
                apis: [
                    {
                        id: '542b2061fca74432be337878a543efb7',
                        name: 'GET /users',
                        scope: 'Team',
                    },
                    {
                        id: 'd746048a3bcd43c6a19fd019c5623afd',
                        name: 'GET /roles/all-options',
                    },
                ],
            },
            {
                name: 'Account Create',
                apis: [
                    {
                        id: '61868d143691456ba63186d2e7971318',
                        name: 'POST /users',
                    },
                    {
                        id: '45b1e64d96f54091a35151ee57995d30',
                        name: 'GET /teams/all-options',
                    },
                    {
                        id: 'd746048a3bcd43c6a19fd019c5623afd',
                        name: 'GET /roles/all-options',
                    },
                ],
            },
            {
                name: 'Account Update',
                apis: [
                    {
                        id: 'be87313914f9475db65aaca8a2004165',
                        name: 'PUT /users',
                    },
                    {
                        id: '45b1e64d96f54091a35151ee57995d30',
                        name: 'GET /teams/all-options',
                    },
                    {
                        id: 'd746048a3bcd43c6a19fd019c5623afd',
                        name: 'GET /roles/all-options',
                    },
                    {
                        id: '2889e07f41194be9b2d1dbf7a428a263',
                        name: 'GET /users/ones',
                    },
                ],
            },
            {
                name: 'Account Delete',
                apis: [
                    {
                        id: 'aac98a7ea6844eae8e3fa149a2fa60d2',
                        name: 'DELETE /users',
                    },
                ],
            },
            {
                name: 'Account Export',
                apis: [
                    {
                        id: 'e959f8f0c8491e649f0f78107985ec55',
                        name: 'GET /users/export/csv',
                    },
                ],
            },
            {
                name: 'Account Reset Password',
                apis: [
                    {
                        id: 'ab26f410e3544450b6104e2ba2b96e51',
                        name: 'DELETE /users/password',
                    },
                ],
            },
            {
                name: 'Account Enable',
                apis: [
                    {
                        id: 'b06ce220777b4c719635fb9815519ac8',
                        name: 'PUT /users/enable',
                    },
                ],
            },
        ],
    },
    {
        name: 'Role',
        key: 'role',
        children: [
            {
                name: 'Role View',
                apis: [
                    {
                        id: 'f076e495aac54598a88d0876e66de734',
                        name: 'GET /roles',
                    },
                ],
            },
            {
                name: 'Role Create',
                apis: [
                    {
                        id: '86526f9958e343088d4d3cbca718e21c',
                        name: 'POST /roles',
                    },
                ],
            },
            {
                name: 'Role Update',
                apis: [
                    {
                        id: 'b8d9515972684ea58d00d96b66c12cb3',
                        name: 'PUT /roles',
                    },
                    {
                        id: '47f308f0f42c497d82dc6d19402ae212',
                        name: 'GET /roles/ones',
                    },
                ],
            },
            {
                name: 'Role Delete',
                apis: [
                    {
                        id: 'd173c49f05744a81b3960187d1580f02',
                        name: 'Delete /roles',
                    },
                ],
            },
            {
                name: 'Role Permission Edit',
                apis: [
                    {
                        id: 'cbbf888f9a544c6f9cdfbaa1b23019ac',
                        name: 'PUT /roles/features',
                    },
                    {
                        id: '47f308f0f42c497d82dc6d19402ae212',
                        name: 'GET /roles/ones',
                    },
                    {
                        id: 'c1983395285b45cea8a067955ee26eb4',
                        name: 'PUT /feature-apis',
                    },
                ],
            },
            // {
            //     name: 'Role Permission Synchronization',
            //     apis: [
            //         {
            //             id: 'c1983395285b45cea8a067955ee26eb4',
            //             name: 'PUT /feature-apis',
            //         },
            //     ],
            // },
        ],
    },
    {
        name: 'Audit Log',
        key: 'auditLog',
        children: [
            {
                name: 'Audit Log View',
                apis: [
                    {
                        id: '27f73333408640d39ac7ef9055712b7a',
                        name: 'GET /audit-records',
                    },
                    // {
                    //     id: '4b2bca4d9b754044a93f719b0d6026f7',
                    //     name: 'GET /dicts',
                    // },
                    {
                        id: '87407a42859e407a9becaadd2ace3230',
                        name: 'GET /users/all-options',
                    },
                ],
            },
        ],
    },
    {
        name: 'Project',
        key: 'project',
        children: [
            {
                name: 'Project Task',
                children: [
                    {
                        name: 'Project Task View',
                        apis: [
                            {
                                id: '1b9f904b8b39470e82d8c004e13f3b18',
                                name: 'GET /project-tasks',
                            },
                        ],
                    },
                    {
                        name: 'Project Task Create',
                        apis: [
                            {
                                id: '87407a42859e407a9becaadd2ace3230',
                                name: 'GET /users/all-options',
                            },
                            // {
                            //     id: '4b2bca4d9b754044a93f719b0d6026f7',
                            //     name: 'GET /dicts',
                            // },
                            {
                                id: '97fcb9b31de24fb4a91089d64ebc1041',
                                name: 'POST /project-tasks',
                            },
                        ],
                    },
                    {
                        name: 'Project Task Update',
                        apis: [
                            {
                                id: '87407a42859e407a9becaadd2ace3230',
                                name: 'GET /users/all-options',
                            },
                            // {
                            //     id: '4b2bca4d9b754044a93f719b0d6026f7',
                            //     name: 'GET /dicts',
                            // },
                            {
                                id: 'c73f9ba8721a43c3b53a9675ac9d23d4',
                                name: 'PUT /project-tasks',
                            },
                        ],
                    },
                    {
                        name: 'Task Detail',
                        apis: [
                            {
                                id: '31951303a61b45ce86d697603ee6198c',
                                name: 'GET /project-tasks/one',
                            },
                            {
                                id: '1db91a74d59446aa97bdc3e44402cd45',
                                name: 'GET /project-task-files',
                            },
                            {
                                id: '983518e34ca544f2a0d5da1ddc757161',
                                name: 'GET /files',
                            },
                        ],
                    },
                    {
                        name: 'Edit Task Testcase',
                        apis: [
                            {
                                id: 'c73f9ba8721a43c3b53a9675ac9d23d4',
                                name: 'PUT /project-tasks',
                            },
                        ],
                    },
                    {
                        name: 'Start Work',
                        apis: [
                            {
                                id: 'c73f9ba8721a43c3b53a9675ac9d23d4',
                                name: 'PUT /project-tasks',
                            },
                        ],
                    },
                    {
                        name: 'Archive Task',
                        apis: [
                            {
                                id: 'c73f9ba8721a43c3b53a9675ac9d23d4',
                                name: 'PUT /project-tasks',
                            },
                        ],
                    },
                    {
                        name: 'Finish Task',
                        apis: [
                            {
                                id: 'c73f9ba8721a43c3b53a9675ac9d23d4',
                                name: 'PUT /project-tasks',
                            },
                        ],
                    },
                    {
                        name: 'Upload Task File',
                        apis: [
                            {
                                id: 'b496f9c21d2a41308ce1018302d3a104',
                                name: 'POST /files',
                            },
                            {
                                id: '54494ed6810f4c4e8ab11d9ffd055937',
                                name: 'POST /project-task-files',
                            },
                        ],
                    },
                ],
            },
            // {
            //     name: 'Project Engineer',
            //     children: [
            //         {
            //             name: 'Project Engineer View',
            //             apis: [],
            //         },
            //         {
            //             name: 'Project Engineer Create',
            //             apis: [],
            //         },
            //         {
            //             name: 'Project Engineer Update',
            //             apis: [],
            //         },
            //         {
            //             name: 'Project Engineer Release',
            //             apis: [],
            //         },
            //     ],
            // },
            // {
            //     name: 'Project Hardware',
            //     children: [
            //         {
            //             name: 'Project Hardware View',
            //             apis: [],
            //         },
            //         {
            //             name: 'Project Hardware Create',
            //             apis: [],
            //         },
            //         {
            //             name: 'Project Hardware Update',
            //             apis: [],
            //         },
            //         {
            //             name: 'Project Hardware Release',
            //             apis: [],
            //         },
            //     ],
            // },
            {
                name: 'Project Recent File',
                children: [
                    {
                        name: 'Project File View',
                        apis: [
                            {
                                id: '1db91a74d59446aa97bdc3e44402cd45',
                                name: 'GET /project-task-files',
                            },
                        ],
                    },
                    {
                        name: 'Project File Download',
                        apis: [
                            {
                                id: '983518e34ca544f2a0d5da1ddc757161',
                                name: 'GET /files',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Project Activity',
                children: [
                    {
                        name: 'Project Activity View',
                        apis: [
                            {
                                id: 'bfbfd7ca40b34da1b1331c895904af36',
                                name: 'GET /notices',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Project View',
                apis: [
                    {
                        id: '3244cd52e1564c77ac5ec1a347c9882d',
                        name: 'GET /projects',
                    },
                    // {
                    //     id: '4b2bca4d9b754044a93f719b0d6026f7',
                    //     name: 'GET /dicts',
                    // },
                ],
            },
            {
                name: 'Project Create',
                apis: [
                    {
                        id: '9235ec3fd7934f79acc8ff926428dc21',
                        name: 'POST /projects',
                    },
                    {
                        id: '87407a42859e407a9becaadd2ace3230',
                        name: 'GET /users/all-options',
                        scope: 'Team',
                    },
                ],
            },
            {
                name: 'Project Update',
                apis: [
                    {
                        id: '0efcfd7920e948a08bb7650992173ff7',
                        name: 'PUT /projects',
                    },
                    {
                        id: '87407a42859e407a9becaadd2ace3230',
                        name: 'GET /users/all-options',
                        scope: 'Team',
                    },
                ],
            },
            {
                name: 'Project Delete',
                apis: [
                    {
                        id: 'db27734bc5074cc6b73a2639c84d3e9a',
                        name: 'Delete /projects',
                    },
                ],
            },
            {
                name: 'Project Prime Change',
                apis: [],
            },
            {
                name: 'Project Detail View',
                apis: [
                    {
                        id: 'e0ca10e51e424094ab987ee4539e3ab7',
                        name: 'GET /projects/one',
                    },
                ],
            },
            {
                name: 'Start Project',
                apis: [
                    {
                        id: 'feaa070630d648fea8e639d1f422cd7c',
                        name: 'PUT /projects/status',
                    },
                ],
            },
            {
                name: 'Sign Off Project',
                apis: [
                    {
                        id: 'feaa070630d648fea8e639d1f422cd7c',
                        name: 'PUT /projects/status',
                    },
                ],
            },
            {
                name: 'Idle Project',
                apis: [
                    {
                        id: 'feaa070630d648fea8e639d1f422cd7c',
                        name: 'PUT /projects/status',
                    },
                ],
            },
            {
                name: 'Archive Project',
                apis: [
                    {
                        id: 'feaa070630d648fea8e639d1f422cd7c',
                        name: 'PUT /projects/status',
                    },
                ],
            },
        ],
    },
    {
        name: 'Ongoing Task',
        key: 'ongoingTask',
        children: [
            {
                name: 'Ongoing Task View',
                apis: [
                    {
                        id: '1b9f904b8b39470e82d8c004e13f3b18',
                        name: 'GET /project-tasks',
                    },
                ],
            },
            {
                name: 'Ongoing Task Update',
                apis: [
                    {
                        id: '87407a42859e407a9becaadd2ace3230',
                        name: 'GET /users/all-options',
                    },
                    // {
                    //     id: '4b2bca4d9b754044a93f719b0d6026f7',
                    //     name: 'GET /dicts',
                    // },
                    {
                        id: 'c73f9ba8721a43c3b53a9675ac9d23d4',
                        name: 'PUT /project-tasks',
                    },
                ],
            },
        ],
    },
    {
        name: 'Preparation Task',
        key: 'preparationTask',
        children: [
            {
                name: 'Preparation Task View',
                apis: [
                    {
                        id: '1b9f904b8b39470e82d8c004e13f3b18',
                        name: 'GET /project-tasks',
                    },
                ],
            },
            {
                name: 'Preparation Task Detail',
                apis: [
                    {
                        id: 'e0ca10e51e424094ab987ee4539e3ab7',
                        name: 'GET /projects/one',
                    },
                    {
                        id: '1db91a74d59446aa97bdc3e44402cd45',
                        name: 'GET /project-task-files',
                    },
                    {
                        id: '983518e34ca544f2a0d5da1ddc757161',
                        name: 'GET /files',
                    },
                ],
            },
        ],
    },
    // {
    //     name: 'Simulator Management',
    //     key: 'simulatorManagement',
    //     children: [
    //         {
    //             name: 'Simulator View',
    //             apis: [
    //                 {
    //                     id: 'db8da8c3d7fc42939826f2d77a946b3e',
    //                     name: 'GET /simulators',
    //                 },
    //                 {
    //                     id: '71af6c0d0d7641acbf5167404528ebb5',
    //                     name: 'GET /simulators/types',
    //                 },
    //             ],
    //         },
    //         {
    //             name: 'Simulator Create',
    //             apis: [
    //                 {
    //                     id: 'a427d41d97a64d909e2d1435785376d0',
    //                     name: 'POST /simulators',
    //                 },
    //                 {
    //                     id: '71af6c0d0d7641acbf5167404528ebb5',
    //                     name: 'GET /simulators/types',
    //                 },
    //             ],
    //         },
    //         {
    //             name: 'Simulator Update',
    //             apis: [
    //                 {
    //                     id: '3f39ce763562401aaf1ee7ca863a4d4c',
    //                     name: 'PUT /simulators',
    //                 },
    //                 {
    //                     id: '71af6c0d0d7641acbf5167404528ebb5',
    //                     name: 'GET /simulators/types',
    //                 },
    //                 {
    //                     id: '603d14dc61634de9a9a9817cfb806c4e',
    //                     name: 'GET /simulators/one',
    //                 },
    //             ],
    //         },
    //         {
    //             name: 'Simulator Delete',
    //             apis: [
    //                 {
    //                     id: '18dfba14f4f547b7999dcf2eb1c1fa4f',
    //                     name: 'Delete /simulators',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        name: 'Attenuator Monitor',
        key: 'matrixMonitor',
        children: [
            {
                name: 'Attenuator Monitor View',
                apis: [
                    {
                        id: '0b5dad9872c7476f9b724c1a7f42cb65',
                        name: 'GET /matrices/running',
                    },
                    {
                        id: '55dc4e16a3ff42b9a6ed1dec8dd2f422',
                        name: 'GET /matrix-data/monitor',
                    },
                    {
                        id: '65a971c23479438cad31a0d5f10e657a',
                        name: 'GET /matrices/hardware-types',
                    },
                    {
                        id: 'f726497df01944dca13a9aeb01d02636',
                        name: 'PUT /matrix-data/attenuation',
                    },
                    {
                        id: '77f97c8b6c7a4e389b5814e077872b23',
                        name: 'PUT /matrix-data/on-off',
                    },
                    {
                        id: '68dc2594f1754aee9383328f15b41b73',
                        name: 'GET /matrices/experiment-status',
                    },
                    {
                        id: '3d152f1dcdff4749bc05ba8e73670151',
                        name: 'GET /matrices-schedule/list',
                    },
                    {
                        id: 'a4c31b84c0fb4f278120b1f941d75732',
                        name: 'POST /matrix-data/attenuation/pre-check',
                    },
                ],
            },
            {
                name: 'Attenuator Import',
                apis: [
                    {
                        id: '601e90229e0f4fd588ff6ec89ba821d9',
                        name: 'PUT /matrix-data/import',
                    },
                ],
            },
            {
                name: 'Attenuator Monitor Export',
                apis: [
                    {
                        id: 'd31027bec0254a2c895c0604120f730e',
                        name: 'GET /matrix-data/export',
                    },
                ],
            },
            {
                name: 'Attenuator Schedule View',
                apis: [],
            },
            {
                name: 'Attenuator Set To Max',
                apis: [
                    {
                        id: 'e1ec66525b4182770617de62da61df18',
                        name: 'PUT /matrix-data/max-attenuation-all',
                    },
                ],
            },
        ],
    },
    {
        name: 'Resource',
        key: 'resource',
        children: [
            {
                name: 'Chamber Management',
                children: [
                    {
                        name: 'Chamber View',
                        apis: [
                            {
                                id: 'bfb81736f9624ef4a694512841465b12',
                                name: 'GET /chambers',
                            },
                            {
                                id: 'f345eb58c506411a843fb8d0e2a37252',
                                name: 'GET /chambers/one',
                            },
                            {
                                id: '9543dc5c9cb34fefb4a004d649348b22',
                                name: 'All /lab-monitor/',
                            },
                        ],
                    },
                    {
                        name: 'Chamber Create',
                        apis: [
                            {
                                id: '5613e6d3fff04dca9e63c3f0b33b5448',
                                name: 'POST /chambers',
                            },
                        ],
                    },
                    {
                        name: 'Chamber Update',
                        apis: [
                            {
                                id: '1c80abb9b49948419d5d8f85b806fc4d',
                                name: 'PUT /chambers',
                            },
                        ],
                    },
                    {
                        name: 'Chamber Delete',
                        apis: [
                            {
                                id: 'c72e8262ef61844ad6c6ff966bdacec0',
                                name: 'POST /experiments/check-resource',
                            },
                            {
                                 id: '6b31448d558b487c8bfd22d244f7333e',
                                 name: 'DELETE /chambers',
                            },
                        ],
                    },
                    {
                        name: 'Chamber Export',
                        apis: [],
                    },
                ],
            },
            {
                name: 'Radio Management',
                children: [
                    {
                        name: 'Radio View',
                        apis: [
                            {
                                id: '2ffe91b9a4d34ae3a2e0c2a6f442afb9',
                                name: 'GET /radios',
                            },
                            {
                                id: 'cba626c1fbd1433592e3fe970f12477c',
                                name: 'GET /radios/one',
                            },
                            {
                                id: '20f64069823941deac7cf1290b9e7cdb',
                                name: 'GET /nes/manageable-options',
                            },
                        ],
                    },
                    {
                        name: 'Radio Create',
                        apis: [
                            {
                                id: '3d215beff8474ca8bd1b612e5a4f1ea1',
                                name: 'POST /radios',
                            },
                        ],
                    },
                    {
                        name: 'Radio Update',
                        apis: [
                            {
                                id: '63597ae5273843049c03713301ea57df',
                                name: 'PUT /radios',
                            },
                        ],
                    },
                    {
                        name: 'Radio Delete',
                        apis: [
                            {
                                id: 'c72e8262ef61844ad6c6ff966bdacec0',
                                name: 'POST /experiments/check-resource',
                            },
                            {
                                id: '14fc20f68d304cf2b61a6865a09d91b1',
                                name: 'POST /radio/delete',
                            },
                        ],
                    },
                    {
                        name: 'Radio Export',
                        apis: [],
                    },
                    {
                        name: 'Radio Port Edit',
                        apis: [
                            {
                                id: 'f7eb65de59a84fbba2d811eb7f3ca7b8',
                                name: 'PUT /radio-ports',
                            },
                            {
                                id: 'a850bfef735249109694bc186fd1a45c',
                                name: 'PUT /radio-ports/switchDisable',
                            },
                        ],
                    },
                    {
                        name: 'NE Control',
                        key: 'neControl',
                        apis: [
                            {
                                id: '8b58e4802deb4d918593c942df1314b9',
                                name: 'PUT /nes/parameters',
                            },
                            {
                                id: '26fdeb40a59b4131a8cc8dde394a6270',
                                name: 'GET /nes/parameters',
                            },
                            {
                                id: '82b2ae397c334cd7b18eadd203bc2f2d',
                                name: 'GET /nes',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Attenuator Management',
                children: [
                    {
                        name: 'Attenuator View',
                        apis: [
                            {
                                id: '3e3867c3bba2435fb4267f18c97ce7c8',
                                name: 'GET /matrices',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Create',
                        apis: [
                            {
                                id: 'b851e3aba2b44806927a72fd3dcf4f1d',
                                name: 'POST /matrices',
                            },
                            {
                                id: '65a971c23479438cad31a0d5f10e657a',
                                name: 'GET /matrices/hardware-types',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Update',
                        apis: [
                            {
                                id: '65a971c23479438cad31a0d5f10e657a',
                                name: 'GET /matrices/hardware-types',
                            },
                            {
                                id: '92169ad509964ae9bd1825d97bcca380',
                                name: 'PUT /matrices',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Delete',
                        apis: [
                            {
                                id: 'c72e8262ef61844ad6c6ff966bdacec0',
                                name: 'POST /experiments/check-resource',
                            },
                            {
                                id: '56d625dd652d4c5a87658ce1c0fae881',
                                name: 'DELETE /matrices',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Export',
                        apis: [
                            {
                                id: '084e6dccd5a11ac2ebd56514c060ad1d',
                                name: 'GET /matrices/export',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Status',
                        apis: [
                            {
                                id: '533fbed0e7154be8b5e632df06e976e2',
                                name: 'PUT /matrices/switch-status',
                            },
                            {
                                id: '68dc2594f1754aee9383328f15b41b73',
                                name: 'GET /matrices/experiment-status',
                            },
                            {
                                id: '8b805cfa87374f799836ae5c2f4e6aa9',
                                name: 'PUT /matrices/status/force-stop',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Log View',
                        apis: [
                            {
                                id: '52dc94bf91a046639e5357bfbf143b19',
                                name: 'GET /matrices/logs',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Connections View',
                        apis: [
                            {
                                id: '428ff71d77f24567b9fe834db5648ed8',
                                name: 'GET /matrix-ports/connections',
                            },
                            {
                                id: 'e4a855bcb02840b0a21d7726f9b3cd8b',
                                name: 'GET /matrix-ports/connections/input',
                            },
                            {
                                id: 'a49a812f9eb5469a8a6da3e1387df6fd',
                                name: 'GET /matrix-ports/connections/output',
                            },
                            {
                                id: '622a578ec6f94a3caa68ef29cd4fea18',
                                name: 'GET /radio-ports/options',
                            },
                            {
                                id: 'cb9900afc3f64a2d93726fe02d543cfc',
                                name: 'GET /chamber-ports/options',
                            },
                            {
                                id: '40c600f7247048aa8b9ca24f2d50d6b5',
                                name: 'PUT /matrix-ports/connections/radio',
                            },
                            {
                                id: '6b1533febb9d44f0ba1353e9a9de8be8',
                                name: 'PUT /matrix-ports/connections/chamber',
                            },
                            {
                                id: '741b332ea59142c6b77f91c4effadfc8',
                                name: 'PUT /matrix-ports/description',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Connections Import',
                        apis: [
                            {
                                id: 'dd95ce77591f45fa9640cc6da81dcf6e',
                                name: 'POST /matrix-ports/import',
                            },
                            {
                                id: '6f592cc57c7a4604baa669b49c493340',
                                name: 'PUT /matrix-ports/import/pre-check',
                            },
                        ],
                    },
                    {
                        name: 'Attenuator Connections Export',
                        apis: [
                            {
                                id: '73969390310f4715b6ddca82b03e6876',
                                name: 'GET /matrix-ports/export',
                            },
                            {
                                id: '653199895f81b0bca1a2d710d63e8182',
                                name: 'GET /matrix-ports/export/csv',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Scanner Management',
                key: 'labMonitor',
                children: [
                    {
                        name: 'Scanner View',
                        key: 'labMonitor',
                        apis: [
                            {
                                id: '9543dc5c9cb34fefb4a004d649348b22',
                                name: 'All /lab-monitor',
                            },
                            {
                                id: '3fe115b75b1d4c2a82568c9009243301',
                                name: 'GET /chamber-tools',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'UE Server Management',
                key: 'ueControl',
                children: [
                    {
                        name: 'UE Server View',
                        key: 'ueControl',
                        apis: [
                            {
                                id: '9zB5XP5kK8a5G2A68/frNKWOfayAFRTNkWea/Z4Kh7ZmH9UW8tkDYQ',
                                name: 'GET /sm/smserver',
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Wireline Management',
                key: 'wirelineManagement',
                children: [
                    {
                        name: 'Wireline View',
                        apis: [
                            {
                                id: 'aae2a4b82f4741af9a23f486b403d0dd',
                                name: 'GET /wireline',
                            },
                            {
                                id: '6cd0cdf0b5954dba9a0b6b152cbfa3c5',
                                name: 'GET /wireline/one',
                            },
                        ],
                    },
                    {
                        name: 'Wireline Create',
                        apis: [
                            {
                                id: '74828de839644494983b255430e3e745',
                                name: 'POST /wireline',
                            },
                        ],
                    },
                    {
                        name: 'Wireline Update',
                        apis: [
                            {
                                id: '65f7f7ff42c040ee860dd15e7e2eef36',
                                name: 'PUT /wireline',
                            },
                        ],
                    },
                    {
                        name: 'Wireline Delete',
                        apis: [
                            {
                                id: '417332b6bcd84fbe9665b0bec2f3ebea',
                                name: 'DELETE /wireline',
                            },
                        ],
                    }
                ]
            }
        ],
    },
    {
        name: 'RF Monitoring',
        key: 'labMonitor',
        children: [
            {
                name: 'RF Monitoring View',
                scope: 'All',
                apis: [
                    {
                        id: '9543dc5c9cb34fefb4a004d649348b22',
                        name: 'All /lab-monitor/',
                    },
                    {
                        id: 'c5c56ecf35b5404f88763453e4cc25bb',
                        name: 'Get /lab-monitor/scanning/scanner/events/subscribe',
                    },
                    {
                        id: '112770904ecb47a9906cdcbac7957211',
                        name: 'GET /chambers/all-options',
                    },
                    {
                        id: '3fe115b75b1d4c2a82568c9009243301',
                        name: 'GET /chamber-tools',
                    },
                    {
                        id: '467e463163d1451ab36032f7fbb9c9ad',
                        name: 'PUT /chamber-tools',
                    },
                ],
            },
            {
                name: 'RF Monitoring View',
                scope: 'Self',
                apis: [
                    {
                        id: '9543dc5c9cb34fefb4a004d649348b22',
                        name: 'All /lab-monitor/',
                    },
                    {
                        id: 'c5c56ecf35b5404f88763453e4cc25bb',
                        name: 'Get /lab-monitor/scanning/scanner/events/subscribe',
                    },
                    {
                        id: 'f3c460068e3e4f9cbee25b3596fabce7',
                        name: 'GET /chambers/list',
                    },
                    {
                        id: '3fe115b75b1d4c2a82568c9009243301',
                        name: 'GET /chamber-tools',
                    },
                ],
            },
            {
                name: 'Sweep Scanning',
                apis: [],
            },
            {
                name: 'Threshold Global Settings',
                apis: [
                    {
                        id: '9543dc5c9cb34fefb4a004d649348b22',
                        name: 'All /lab-monitor/',
                    },
                ],
            },
        ],
    },
    {
        name: 'Device Control',
        key: 'deviceControl',
        children: [
            {
                name: 'Device Control View',
                scope: 'All',
                apis: deviceControlApis,
            },
            {
                name: 'Device Control View',
                scope: 'Self',
                apis: [
                    ...deviceControlApis,
                    {
                        id: '52a01708ca03410d8655ca248cad7f1f',
                        name: 'GET /chambers/selfCalendarChamberList'
                    }
                ],
            },
            {
                name: 'Device Control Schedule',
                apis: [
                    {
                        id: 'b851e3aba2b44806927a72fd3dcf4f17',
                        name: 'GET /matrices-schedule',
                    },
                    {
                        id: 'b851e3aba2b44806927a72fd3dcf4f16',
                        name: 'POST /matrices-schedule',
                    },
                    {
                        id: 'de2dc272667844439693e3cd5f3a992c',
                        name: 'POST /matrices-schedule/batch',
                    },
                    {
                        id: '3d152f1dcdff4749bc05ba8e73670151',
                        name: 'GET /matrices-schedule/list',
                    },
                    {
                        id: '2ee1fa9bafa040118c2592f86efbd8d1',
                        name: 'POST /matrices-schedule/check',
                    },
                    {
                        id: 'a49a812f9eb5469a8a6da3e1387df6fd',
                        name: 'GET /matrix-ports/connections/output',
                    },
                ],
            },
        ],
    },

    {
        name: 'Network Impairment',
        key: 'networkImpairment',
        children: [
            {
                name: 'Network Impairment View',
                apis: [
                    {
                        id: 'aae2a4b82f4741af9a23f486b403d0dd',
                        name: 'GET /wireline',
                    },
                    {
                        id: '6cd0cdf0b5954dba9a0b6b152cbfa3c5',
                        name: 'GET /wireline/one',
                    },
                    {
                        id: '5bf02e61b72c4f2384a840aa455e6329',
                        name: 'All /wireline/chimera'
                    },
                    {
                        id: 'c1bbbde00b8d4e5abb174e3051d2fc53',
                        name: 'GET /wireline-template/tree',
                    },
                    {
                        id: '7e91eedab6824a8ba851953bc78498cd',
                        name: 'GET /wireline-template',
                    },
                    {
                        id: '3f5140ea99854fd784b31f8121c14d17',
                        name: 'POST /wireline-template',
                    },
                    {
                        id: '5b18f99e069544d580fd1ca3099ee69c',
                        name: 'PUT /wireline-template',
                    },
                    {
                        id: 'fdb425a241434c76ad52ca60bfb04eb6',
                        name: 'DELETE /wireline-template',
                    },
                ],
            },
        ]
    },
    {
        name: 'Optical Switch',
        key: 'opticalSwitch',
        children: [
            {
                name: 'Optical Switch View',
                key: 'opticalSwitch',
                apis: [
                    {
                        id: 'aae2a4b82f4741af9a23f486b403d0dd',
                        name: 'GET /wireline',
                    },
                    {
                        id: '6cd0cdf0b5954dba9a0b6b152cbfa3c5',
                        name: 'GET /wireline/one',
                    },
                ],
            },
            {
                name: 'Cross Connect Add/Edit',
                key: 'opticalSwitch',
                apis: [
                    {
                        id: '531fbaef1c324e71b9c9586379afb4af',
                        name: 'POST /wireline/cross-connect',
                    },
                ],
            },
            {
                name: 'Cross Connect Delete',
                key: 'opticalSwitch',
                apis: [
                    {
                        id: '20e19bb65fb14ccfa46fbd89fb8e136e',
                        name: 'DELETE /wireline/cross-connect',
                    },
                ],
            }
        ]
    },

    {
        name: 'Advanced Mobility',
        key: 'advancedMobility',
        children: [
            {
                name: 'Advanced Mobility View',
                scope: 'All',
                apis: [
                    {
                        id: 'a99d1f4c4fb44d3faed6d389465edd92',
                        name: 'GET /experiments',
                        scope: 'All',
                    },
                    {
                        id: 'bbf108a8129244028f215f54d2122776',
                        name: 'GET /time',
                    },
                    {
                        id: '79e061acbb614ff08518455f208cf557',
                        name: 'POST /experiments/check',
                    },
                ],
            },
            {
                name: 'Advanced Mobility View',
                scope: 'Self',
                apis: [
                    {
                        id: 'a99d1f4c4fb44d3faed6d389465edd92',
                        name: 'GET /experiments',
                        scope: 'Self',
                    },
                    {
                        id: 'bbf108a8129244028f215f54d2122776',
                        name: 'GET /time',
                    },
                    {
                        id: '79e061acbb614ff08518455f208cf557',
                        name: 'POST /experiments/check',
                    },
                ],
            },
            {
                name: 'Experiment Create',
                apis: [
                    {
                        id: '65a971c23479438cad31a0d5f10e657a',
                        name: 'GET /matrices/hardware-types',
                    },
                    {
                        id: 'f3c460068e3e4f9cbee25b3596fabce7',
                        name: 'GET /chambers/list',
                    },
                    {
                        id: '2f2bfdaa673c47cbb82c02d2f54b4fae',
                        name: 'GET /matrices/chamber-connected',
                    },
                    {
                        id: 'aefd4ea6cdef4402bb6d86416e2e57ff',
                        name: 'GET /matrix-data/radio-testing',
                    },
                    {
                        id: '9f51c50a354e4698911ca227021b603a',
                        name: 'POST /experiments',
                    },
                    {
                        id: '982cd02f655a444ca7bbcbd5306c8cd6',
                        name: 'PUT /experiments/status',
                    },
                    {
                        id: '8cd94fdf36a1476ba77fe930fd145886',
                        name: 'PUT /experiments/create-and-start',
                    },
                    {
                        id: 'ef63e398849f4db6808d25251f4cd9b1',
                        name: 'PUT /experiments/update-and-start',
                    },
                ],
            },
            {
                name: 'Experiment Update',
                apis: [
                    {
                        id: '9e5bea98daa74350acb81efefa133023',
                        name: 'GET /experiments/para',
                    },
                    {
                        id: '5325d542438745e08fc3936038296d71',
                        name: 'PUT /experiments',
                    },
                    {
                        id: '982cd02f655a444ca7bbcbd5306c8cd6',
                        name: 'PUT /experiments/status',
                    },
                    {
                        id: 'aefd4ea6cdef4402bb6d86416e2e57ff',
                        name: 'GET /matrix-data/radio-testing',
                    },
                ],
            },
            {
                name: 'Experiment Delete',
                apis: [
                    {
                        id: '115855a2c9ff4f62b094a00f122bafd0',
                        name: 'GET /experiment/delete',
                    },
                ],
            },
            {
                name: 'Experiment Stop',
                apis: [
                    {
                        id: '5325d542438745e08fc3936038296d71',
                        name: 'PUT /experiments',
                    },
                    {
                        id: '982cd02f655a444ca7bbcbd5306c8cd6',
                        name: 'PUT /experiments/status',
                    },
                ],
            },
            {
                name: 'Experiment Start',
                apis: [
                    {
                        id: '5325d542438745e08fc3936038296d71',
                        name: 'PUT /experiments',
                    },
                    {
                        id: '982cd02f655a444ca7bbcbd5306c8cd6',
                        name: 'PUT /experiments/status',
                    },
                    {
                        id: 'ed60e038a720434bb4fc0a2d7dc897c2',
                        name: 'GET /experiments/enable-status',
                    },
                ],
            },
            {
                name: 'Experiment Monitor View',
                apis: [
                    {
                        id: 'ed60e038a720434bb4fc0a2d7dc897c2',
                        name: 'GET /experiments/enable-status',
                    },
                    {
                        id: '8045aee523d0413ab8bf748fe57d2b44',
                        name: 'GET /experiments/monitor',
                    },
                ],
            },
            {
                name: 'Experiment Schedule',
                apis: [
                    {
                        id: '79e061acbb614ff08518455f208cf557',
                        name: 'POST /experiments/check',
                    },
                ],
            },
        ],
    },
    {
        name: 'E2E Scripting',
        key: 'e2eScripting',
        children: [
            {
                name: 'E2E Scripting View',
                apis: [

                ],
            }
        ],
    },
    // {
    //     name: 'Licence',
    //     key: 'licence',
    //     children: [
    //         {
    //             name: 'Licence View',
    //             apis: [
    //                 {
    //                     id: '96e3fa6524bb4da8a5c4d47c63aec44f',
    //                     name: 'GET /licence/features/all',
    //                 },
    //                 {
    //                     id: '0638839f7f0e401fb224ef9e79d25d51',
    //                     name: 'GET /licence',
    //                 },
    //                 {
    //                     id: 'c9b0851a693d4c018b6dae79e59fb4c1',
    //                     name: 'POST /licence/upload',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        name: 'Licence Manager',
        key: 'licenceManager',
        children: [
            {
                name: 'Licence Manager View',
                apis: [
                    {
                        id: '96e3fa6524bb4da8a5c4d47c63aec44f',
                        name: 'GET /licence/features/all',
                    },
                    {
                        id: '0638839f7f0e401fb224ef9e79d25d51',
                        name: 'GET /licence',
                    },
                    {
                        id: 'c9b0851a693d4c018b6dae79e59fb4c1',
                        name: 'POST /licence/upload',
                    },
                ],
            },
        ],
    },
    {
        name: 'Lab Management',
        key: 'labManagement',
        children: [
            {
                name: 'Resource Assignment',
                key: 'resourceAssignment',
                children: [
                    {
                        name: 'Resource Assignment Chamber',
                        children: [
                            {
                                name: 'Resource Assignment Chamber View',
                                apis: [
                                    {
                                        id: 'bfb81736f9624ef4a694512841465b12',
                                        name: 'GET /chambers',
                                    },
                                    {
                                        id: 'f345eb58c506411a843fb8d0e2a37252',
                                        name: 'GET /chambers/one',
                                    },
                                ],
                            },
                            {
                                name: 'Resource Assignment Chamber Export',
                                apis: [
                                    {
                                        id: '86992a1cc79148b767350cd562bbf0ff',
                                        name: 'GET /chambers/export',
                                    },
                                ],
                            },
                            {
                                name: 'Resource Assignment Chamber Management',
                                apis: [
                                    {
                                        id: '87407a42859e407a9becaadd2ace3230',
                                        name: 'GET /users/all-options',
                                    },
                                    {
                                        id: 'e5af09bef8754739983d049e68765437',
                                        name: 'POST /user-chambers',
                                    },
                                    {
                                        id: '0c6559f5c5db4cdeaa4b21c9e8f423e0',
                                        name: 'DELETE /user-chambers',
                                    },
                                    {
                                        id: 'b171193b811549fea61b2251c95be048',
                                        name: 'GET /user-radios/candidate-radios',
                                    },
                                    {
                                        id: '31e9b650a12248929160b9e150ecedab',
                                        name: 'POST /user-radios',
                                    },
                                    {
                                        id: 'c6ba51502b5c4a6f988430d3bc554d3c',
                                        name: 'DELETE /user-radios',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: 'Resource Assignment Radio',
                        children: [
                            {
                                name: 'Resource Assignment Radio View',
                                apis: [
                                    {
                                        id: '2ffe91b9a4d34ae3a2e0c2a6f442afb9',
                                        name: 'GET /radios',
                                    },
                                    {
                                        id: 'cba626c1fbd1433592e3fe970f12477c',
                                        name: 'GET /radios/one',
                                    },
                                ],
                            },
                            {
                                name: 'Resource Assignment Radio Export',
                                apis: [
                                    {
                                        id: '636e5ce7676bb991f1196b7e9ec569ae',
                                        name: 'GET /radios/export',
                                    },
                                ],
                            },
                            {
                                name: 'Resource Assignment Radio Management',
                                apis: [
                                    {
                                        id: '87407a42859e407a9becaadd2ace3230',
                                        name: 'GET /users/all-options',
                                    },
                                    {
                                        id: '29e758f126824950baf1220bf47c5dd0',
                                        name: 'GET /user-chambers/candidate-chambers',
                                    },
                                    {
                                        id: '31e9b650a12248929160b9e150ecedab',
                                        name: 'POST /user-radios',
                                    },
                                    {
                                        id: 'e5af09bef8754739983d049e68765437',
                                        name: 'POST /user-chambers',
                                    },
                                    {
                                        id: 'c6ba51502b5c4a6f988430d3bc554d3c',
                                        name: 'DELETE /user-radios',
                                    },
                                    {
                                        id: '29e758f126824950baf1220bf47c5dd0',
                                        name: 'GET /user-chambers/candidate-chambers',
                                    },
                                    {
                                        id: '0c6559f5c5db4cdeaa4b21c9e8f423e0',
                                        name: 'DELETE /user-chambers',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: 'Resource Assignment Engineer',
                        children: [
                            {
                                name: 'Resource Assignment Engineer View',
                                apis: [
                                    {
                                        id: '6817c2d46ca2474bb940877761e76eb7',
                                        name: 'GET /user-chambers',
                                    },
                                    {
                                        id: '39e4f918bbbf4caca37c35e8fb93f736',
                                        name: 'GET /user-chambers/ones',
                                    },
                                ],
                            },
                            {
                                name: 'Resource Assignment Engineer Export',
                                apis: [],
                            },
                            {
                                name: 'Resource Assignment Engineer Management',
                                apis: [
                                    {
                                        id: '112770904ecb47a9906cdcbac7957211',
                                        name: 'GET /chambers/all-options',
                                    },
                                    {
                                        id: 'e5af09bef8754739983d049e68765437',
                                        name: 'POST /user-chambers',
                                    },
                                    {
                                        id: 'b171193b811549fea61b2251c95be048',
                                        name: 'GET /user-radios/candidate-radios',
                                    },
                                    {
                                        id: '31e9b650a12248929160b9e150ecedab',
                                        name: 'POST /user-radios',
                                    },
                                    {
                                        id: '0c6559f5c5db4cdeaa4b21c9e8f423e0',
                                        name: 'DELETE /user-chambers',
                                    },
                                    {
                                        id: 'c6ba51502b5c4a6f988430d3bc554d3c',
                                        name: 'DELETE /user-radios',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: 'Resource Assignment UE',
                        key: 'ueControl',
                        children: [
                            {
                                name: 'Resource Assignment UE View',
                                apis: [],
                            },
                            {
                                name: 'Resource Assignment UE Management',
                                apis: [],
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Resource Reservation',
                key: 'resourceReservation',
                children: [
                    {
                        name: 'Calendar View',
                        scope: 'All',
                        apis: calendarViewApis,
                    },
                    {
                        name: 'Calendar View',
                        scope: 'Self',
                        apis: calendarViewApis,
                    },
                    {
                        name: 'Calendar Edit',
                        scope: 'All',
                        apis: calendarEditApis,
                    },
                    {
                        name: 'Calendar Edit',
                        scope: 'Self',
                        apis: calendarEditApis,
                    },
                    {
                        name: 'Approval View',
                        apis: [
                            {
                                id: '359bbb584eb044868a13ec8fd5e2f933',
                                name: 'GET /calendar/approval-setting',
                            },
                        ],
                    },
                    {
                        name: 'Approval Edit',
                        apis: [
                            {
                                id: '245aeab929764baa9c24a16c98ee3257',
                                name: 'POST /calendar/approval-setting',
                            },
                        ],
                    },
                    {
                        name: 'Request and Approvals',
                        key: 'resourceRequest',
                        apis: [
                            {
                                id: 'd39cefb907cb4eaa980550b3f4618ac4',
                                name: 'GET /request',
                            },
                            {
                                id: 'f5c6a2066c2d4156a8678856557c9380',
                                name: 'POST /request',
                            },
                        ],
                    },
                    {
                        name: 'Resource Notification Setting',
                        apis: [],
                        type: 'table',
                        settings: {
                            headers: ['Email Notification', 'In App Notification'],
                            columns: ['Resource Request', 'Approval Status'],
                            permissions: [['Resource Request Email Notification', 'Resource Request In App Notification'], ['Approval Status Email Notification', 'Approval Status In App Notification']],
                        },
                    },
                ],
            },
        ],
    },
    {
        name: 'Profile Settings',
        key: 'profileSetting',
        children: [
            {
                name: 'Profile Setting View',
                apis: [
                    {
                        id: 'dbf8101a563d429689116e24496090aa',
                        name: 'PUT /users/password',
                    },
                    {
                        id: 'be87313914f9475db65aaca8a2004165',
                        name: 'PUT /users',
                    },
                    {
                        id: '2889e07f41194be9b2d1dbf7a428a263',
                        name: 'GET /users/one',
                    },
                ],
            },
        ],
    },
    {
        name: 'Notification',
        key: 'notification',
        children: [
            {
                name: 'Notification View',
                apis: [
                    {
                        id: '3591161c359647cdb37c0748af90672f',
                        name: 'GET /notification',
                    },
                    {
                        id: 'd9f1a4ef2dda4aaa8519441543f34f4d',
                        name: 'PUT /notification/status/read',
                    },
                ],
            },
            {
                name: 'Notification Archive',
                apis: [
                    {
                        id: '6119b5d1d7234bc0a0ea0abd05d28cbf',
                        name: 'PUT /notification/status/archive',
                    },
                ],
            },
            {
                name: 'Notification Delete',
                apis: [
                    {
                        id: '8c54eb80f8324eeda202dd385d56c63c',
                        name: 'PUT /notification/status/unarchive',
                    },
                ],
            },
        ],
    },
    {
        name: 'Notification Setting',
        key: 'notificationSetting',
        children: [
            {
                name: 'Notification Setting',
                children: [
                    {
                        name: 'Notification Setting View',
                        apis: [
                            {
                                id: '25bc306cada7416ee0539c4eb7336120',
                                name: 'GET /notification/email/recipient/list',
                            },
                        ],
                    },
                    {
                        name: 'Notification Email Recipient Create',
                        apis: [
                            {
                                id: '7eae28c97d984355a86662ef18ba414b',
                                name: 'POST /notification/email/recipient',
                            },
                        ],
                    },
                    {
                        name: 'Notification Email Recipient Edit',
                        apis: [
                            {
                                id: '2a4d949899504485a56bee05a0a997fd',
                                name: 'PUT /notification/email/recipient',
                            },
                        ],
                    },
                    {
                        name: 'Notification Email Recipient Delete',
                        apis: [
                            {
                                id: 'bb3b4f668b3e48bda032c3eb51bfff20',
                                name: 'DELETE /notification/email/recipient',
                            },
                        ],
                    },
                    {
                        name: 'Activate Notification',
                        apis: [
                            {
                                id: '8a97f228f9e63f2a17b6a2db1e858238',
                                name: 'POST /notification/email/recipient/setting'
                            },
                            {
                                id: '9a8d71ce00414f1ad34d5fd9740530df',
                                name: 'POST /notification/email/recipient/account'
                            }
                        ]
                    }
                    // {
                    //     name: 'Account Recipient Switch',
                    //     apis: [
                    //         {
                    //             id: '00132296c13a4b92977d81e60d35ea83',
                    //             name: 'POST /notification/account/recipient',
                    //         },
                    //         {
                    //             id: 'e6c0783fba704a2d835f26c797c7a9fc',
                    //             name: 'DELETE /notification/account/recipient',
                    //         },
                    //     ],
                    // },
                ],
            },
            {
                name: 'Resource Reservation Setting',
                children: [
                    {
                        name: 'Resource Reservation Setting View',
                        apis: [],
                    },
                ],
            },
        ],
    },
    // {
    //     name: 'Backup Manager',
    //     children: [
    //         {
    //             name: 'Backup Scheduler',
    //             children: [
    //                 {
    //                     name: 'Backup Scheduler View',
    //                     apis: []
    //                 },
    //                 {
    //                     name: 'Backup Scheduler Edit',
    //                     apis: []
    //                 },
    //                 {
    //                     name: 'Backup Scheduler Start',
    //                     apis: []
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Backup Archive',
    //             children: [
    //                 {
    //                     name: 'Backup Archive View',
    //                     apis: []
    //                 },
    //                 {
    //                     name: 'Backup Archive Restore',
    //                     apis: []
    //                 },
    //                 {
    //                     name: 'Backup Archive Delete',
    //                     apis: []
    //                 }
    //             ]
    //         }
    //     ]
    // }
];

const basicRoleFeatures: {
    [key: string]: {
        features: {
            [key: string]: string[];
        };
    };
} = {
    admin: {
        features: {
            // 'Dashboard View': [],
            'System Log View': [],

            // 'UE Control View': [],
            // 'Network Impairment View': [],

            'Account View': [],
            'Account Create': [],
            'Account Export': [],
            'Account Update': [],
            'Account Delete': [],
            'Account Reset Password': [],
            'Account Enable': [],
            'Role View': [],
            'Role Create': [],
            'Role Update': [],
            'Role Delete': [],
            'Role Permission Edit': [],
            // 'Role Permission Synchronization': [],
            'Audit Log View': [],
            'Licence View': [],
            'Licence Manager View': [],
            // new notification settings for admin
            // 'Notification View': [],
            // 'Notification Archive': [],
            // 'Notification Delete': [],
            // 'Email Recipient View': [],
            // 'Email Recipient Create': [],
            // 'Email Recipient Edit': [],
            // 'Email Recipient Delete': [],
            // 'Account Recipient View': [],
            // 'Account Recipient Switch': [],
        },
    },
};

export { features, basicRoleFeatures, featureGroup };
