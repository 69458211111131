import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { featureGroup, features } from '@app/core/startup/permission';
import {
    ActiveData,
    FeatureGroup,
    FeatureNew,
    LicenceBaseInfo, LicenseSMAData,
    LicenseSMAParams,
    SubFeature,
} from '@app/interfaces/sys/licence';
import { Feature } from '@app/interfaces/sys/permission';
import { environment } from '@env/environment';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root',
})
export class LicenceService {
    constructor(private http: HttpClient) {
    }

    getActive(data: { flag?: boolean }): Promise<ActiveData> {
        return this.http.get<ActiveData>('/license', {
            params: Object.assign(data),
        }).toPromise();
    }

    isLicenceValid(): Promise<string[]> {
        return this.http.get<string[]>('/license/test').toPromise();
    }

    getCurrentLicence(): Promise<any> {
        return this.http.get<any>('/license').toPromise();
    }

    getLicencedFeatures(): Promise<FeatureNew[]> {
        return this.http.get<FeatureNew[]>('/license/features/all').toPromise();
    }

    uploadLicence(data: FormData): Promise<void> {
        return this.http.post<void>('/license/upload', data, {
            headers: {
                hideAnyError: 'true',
            }
        }).toPromise();
    }

    getSMA(): Promise<LicenseSMAParams> {
        return this.http.get<LicenseSMAParams>('/license/sma').toPromise();
    }

    async getLicenseExpired(): Promise<LicenseSMAData> {
        const smaData = await this.getSMA();
        const roleMessage = smaData.sma ? 'Support and Maintenance Agreement' : 'licence';
        sessionStorage.setItem('isSMA', JSON.stringify(smaData.sma));
        const expireDate = smaData.expireDate ? dayjs(smaData.expireDate).format('YYYY-MM-DD HH:mm:ss') : '';
        return {
            roleMessage,
            expireDate,
            smaData
        };
    }

    getLicenceBaseInfo(): Promise<LicenceBaseInfo> {
        return this.http.get<LicenceBaseInfo>('/license/baseinfo').toPromise();
    }

    getLicenseItem(licenceName: string): FeatureNew {
        const licences = JSON.parse(localStorage.getItem('licences') || '[]');
        const licenceItem = licences.find((x: FeatureNew) => x.featureName === licenceName);
        return licenceItem;
    }

    isLicenceExpired(licenceName: string): boolean {
        const licenceItem = this.getLicenseItem(licenceName);
        return this.judgeLicenseExpired(licenceItem);
    }

    judgeLicenseExpired(licenceItem: FeatureNew): boolean {
        const expiryDate = licenceItem?.expiryDate;
        return !!expiryDate && dayjs().valueOf() > expiryDate;
    }

    isLicenceNameValid(licenceName: string): boolean {
        const licenceItem = this.getLicenseItem(licenceName);
        return licenceItem ? !this.judgeLicenseExpired(licenceItem) : false;
    }

    getFeatureGroup(res: FeatureNew[]): FeatureGroup[] {
        const findFeatureInGroup = (featureName: string, group: SubFeature) => {
            if (group.name === featureName) {
                return group;
            }

            if (group.subFeatures) {
                for (const child of group.subFeatures) {
                    const found: any = findFeatureInGroup(featureName, child);
                    if (found) {
                        return found;
                    }

                }
            }
            return null;
        };

        return res.map((x: FeatureNew) => {
            for (const group of featureGroup) {
                const found = findFeatureInGroup(x.featureName, group);

                if (found) {
                    return found;
                }
            }
            return undefined;
        }).filter((x: any) => x);
    }

    getExpiredFeatures(res: FeatureNew[]): FeatureNew[] {
        return res.filter(x => dayjs(x.expiryDate).isBefore(dayjs()));
    }

    getActiveFeatures(res: FeatureNew[]): FeatureNew[] {
        return res.filter(x => !dayjs(x.expiryDate).isBefore(dayjs()));
    }

    getSubFeatures(res: FeatureNew[]): SubFeature[] {
        let subFeatures: SubFeature[] = [];
        const featureGroup = this.getFeatureGroup(res);
        featureGroup.forEach((el: any) => {
            const isExpired = this.isLicenceExpired(el.name);
            const sf = (el.subFeatures || []).map((x: FeatureGroup) => {
                    return {
                        ...x,
                        expired: isExpired
                    };
                },
            );
            subFeatures = [...subFeatures, ...sf];
        });
        return subFeatures;
    }

    // getListFeatureKey(res: FeatureNew[]): Set<string> {
    //     const subFeatures = this.getSubFeatures(res);
    //     return new Set(subFeatures.map((el) => el.key).filter(x =>  x));
    // }

    getListFeatureKey(res: FeatureNew[]): {
        allKeys: Set<string>,
        expiredKeys: string[]
    } {
        const subFeatures = this.getSubFeatures(res);
        return {
            allKeys: new Set(subFeatures.map((el) => el.key).filter(x => x)),
            expiredKeys: subFeatures?.filter(x => x.expired)?.map(it => it.key)
        };
    }

    filterFeaturesByKeys(features: Feature[], keyList: Set<string>): Feature[] {
        return features.filter(feature => {
            const isValidFeature = keyList.has(feature.key as string);

            if (feature.children) {
                const children = this.filterFeaturesByKeys(feature.children, keyList);
                return isValidFeature || children.length > 0;
            }

            return isValidFeature;
        });
    }

    getAllFeatures(): Feature[] {
        const enablePages = environment.enablePages;
        return features.filter(item => !item.key || enablePages.includes(item.key));
    }

    getFeatureEndpoints(allowedKeys: Set<string>): Set<string> {
        const endpoints = new Set<string>();

        // Filtering features and child features based on allowed keys
        const featuresCopy = JSON.stringify(this.getAllFeatures());

        // const filteredFeatures = features.filter(feature => allowedKeys.has(feature.key as string));
        const filteredFeatures = this.filterFeaturesByKeys(JSON.parse(featuresCopy), allowedKeys);
        // Process permissions on filtered features
        const processedFeatures = this.addChildPermission(filteredFeatures, allowedKeys);

        // Collecting endpoints from processed features
        processedFeatures.forEach(feature => {
            const featureEndpoints = this.collectChildEndpoints(feature);
            featureEndpoints.forEach(endpoint => endpoints.add(endpoint));
        });
        return endpoints;
    }

    getExpiredFeatureEndpoints(expiredKeys: Set<string>): { [key: string]: string[] } {
        const result: { [key: string]: string[] } = {};

        // Recursive function to process each feature and its children
        const processFeature = (feature: Feature) => {
            if (expiredKeys.has(feature.key as string)) {
                if (!result[feature.name]) {
                    result[feature.name] = feature.scope ? [feature.scope] : [];
                } else if (feature.scope && !result[feature.name].includes(feature.scope)) {
                    result[feature.name].push(feature.scope);
                }
            }

            feature.children?.forEach(child => processFeature(child));
        };

        this.getAllFeatures().forEach(feature => processFeature(feature));
        return result;
    }


    addChildPermission(features: Feature[], allowedKeys: Set<string>) {
        if (!features || features.length === 0) {
            return [];
        }

        const copyFeatures = features.map(x => x);
        const processPermission = (feature: Feature) => {
            if (feature.children) {
                // Filter the children array
                feature.children = feature.children.filter(child => {
                    // Process the child if it has further children
                    if (child.children) {
                        processPermission(child);
                    }
                    if ('key' in child) {
                        return allowedKeys.has(child.key as string);
                    }

                    return true;
                });
            }
            return feature;
        };

        return copyFeatures.map(processPermission).filter(feature => feature !== null);
    }

    collectChildEndpoints(feature: any): Set<string> {
        let endpoints = new Set<string>();
        feature.children.map((x: any) => {
            const name: string = x.name;
            endpoints.add(name);
            if (x.children) {
                endpoints = new Set([...endpoints, ...this.collectChildEndpoints(x)]);
            }
        });
        return endpoints;
    }
}
