import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Port } from '@app/interfaces/rm/matrix';
import { InputData, PortSelection } from '@app/interfaces/rm/radio-test';
import {
    ApprovalSetting, AuditRequest, EditRequest,
    CalendarParams, EditApprovalSetting, OverwriteOneParams, RequestParams, RequestWithPage,
    ReservationAdd,
    ReservationCalendar, ReservationCancel, ReservationRole, ResourceCalendarParams,
    ResourceTree,
    ResourceCheckRequestParams, CalendarType,
} from '@app/interfaces/rm/resource-reservation';
import { AuthHttp } from '@services/sys/auth.service';
import { LicenceService } from '@services/sys/licence.service';
import { SettingsService } from '@services/sys/settings.service';
import { TokenService } from '@services/sys/token.service';
import dayjs from 'dayjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ResourceReservationService {
    private baseUrl = '/calendar';
    private requestUrl = '/request';
    resourceReservationEventEmitter = new EventEmitter();    

    constructor(private http: HttpClient,
                private tokenService: TokenService,
                private settingService: SettingsService,
                private licenceService: LicenceService) {
    }

    getResourceTypes(): ResourceTree[] {
        const types = [
            {
                title: 'Chamber',
                key: 'CHAMBER',
            },
            {
                title: 'Radio',
                key: 'RADIO',
            },
            {
                title: 'UE',
                key: 'UE',
            }
        ];
        return types;
    }

    async getCalendarList(params: CalendarParams): Promise<ReservationCalendar[]> {
        return this.http.get<ReservationCalendar[]>(`${this.baseUrl}/list`, {
            params: Object.assign(params),
        }).toPromise();
    }

    async getCalendarListSelf(params: CalendarParams): Promise<ReservationCalendar[]> {
        return this.http.get<ReservationCalendar[]>(`${this.baseUrl}/list/self`, {
            params: Object.assign(params),
        }).toPromise();
    }

    async addReservation(params: ReservationAdd): Promise<void> {
        return this.http.post<void>(`${this.baseUrl}`, params).toPromise();
    }

    async cancelReservation(params: ReservationCancel): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}/cancel`, params).toPromise();
    }

    async editReservation(params: ReservationCancel): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}`, params).toPromise();
    }

    async getApprovalSetting(): Promise<ApprovalSetting[]> {
        return this.http.get<ApprovalSetting[]>(`${this.baseUrl}/approval-setting`).toPromise();
    }

    async editApprovalSetting(params: EditApprovalSetting): Promise<ApprovalSetting> {
        return this.http.post<ApprovalSetting>(`${this.baseUrl}/approval-setting`, params).toPromise();
    }

    async getRequest(params: RequestParams): Promise<RequestWithPage> {
        return this.http.get<RequestWithPage>(`${this.requestUrl}`, {
            params: Object.assign(params)
        }).toPromise();
    }

    async auditRequest(params: AuditRequest): Promise<void> {
        return this.http.post<void>(`${this.requestUrl}`, params).toPromise();
    }

    async forceEditThenApproveRequest(params: EditRequest): Promise<void> {
        return this.http.post<void>(`${this.requestUrl}/edit-approve`, params).toPromise();
    }

    async deleteReservation(id: number): Promise<void> {
        return this.http.delete<void>(`${this.baseUrl}`, {
            body: {
                id
            }
        }).toPromise();
    }

    async overwriteOne(params: OverwriteOneParams): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}/edit`, params).toPromise();
    }

    getSelfChamberAssign(params: ResourceCalendarParams): Promise<void> {
        return this.http.get<void>(`/chambers/selfCalendarChamberList`, {
            params: Object.assign(params)
        }).toPromise();
    }


    async checkResourceAvailable(params: ResourceCheckRequestParams): Promise<void> {
        const assignmentPermission: boolean = this.settingService.hasPermission(['Resource Assignment Chamber View', 'Resource Assignment Radio View']);
        const reservationPermission: boolean = this.settingService.hasPermission(['Calendar View']);
        const reservationLicenseValid = this.licenceService.isLicenceNameValid('Lab Management');

        params.checkAssignment = assignmentPermission;
        params.checkReservation = reservationLicenseValid && reservationPermission;

        return this.http.post<void>(`${this.baseUrl}/check-available`, params).toPromise();
    }

    getReservedRange(params: {intervalSeconds?: number; startTime?: number; endTime?: number}): ResourceCalendarParams {
        const currentTimeStamp = dayjs();
        return {
            startTime: params.startTime || currentTimeStamp.valueOf(),
            endTime: params.endTime || (params.intervalSeconds ? currentTimeStamp.add(params.intervalSeconds, 'second').valueOf() : currentTimeStamp.endOf('d').valueOf())
        };
    }

    publishEvent(name: string, value?: any): void {
        this.resourceReservationEventEmitter.emit({ name, value });
    }

    subscribeEvent(name: string): Observable<any> {
        return this.resourceReservationEventEmitter.pipe(
            filter((item) => item.name === name),
            map((item) => item.value)
        );
    }

    getDeviceControlParams(list: Port[], inputList: Array<InputData>, outputList: Array<InputData>): ResourceCheckRequestParams {
        const radioPortIndexArr: number[] = [];
        const chamberPortIndexArr: number[] = [];
        list.forEach(it => {
            const { inputIndex, outputIndex } = it;
            if (inputIndex) {
                radioPortIndexArr.push(inputIndex);
            }
            if (outputIndex) {
                chamberPortIndexArr.push(outputIndex);
            }
        });
        const chamberPortIds = outputList.filter(it => chamberPortIndexArr.includes(it.portIndex)).map(x => x.id);
        const radioPortIds = inputList.filter(it => radioPortIndexArr.includes(it.portIndex)).map(x => x.id);
        console.log('checkAssignAndReservation', radioPortIds, chamberPortIds);
        const params = {
            resourceAvailableCheckList: [
                {
                    resourceType: CalendarType.CHAMBER,
                    chamberPortIdList: chamberPortIds
                },
                {
                    resourceType: CalendarType.RADIO,
                    radioPortIdList: radioPortIds
                }
            ],
            checkAssignment: true,
            checkReservation: true
        };
        return params;
    }

    getArrayList(data: any[][]): number[] {
        const result: number[] = [];
        function extractStrings(arr: any[]): void {
            arr.forEach((item) => {
                if (Array.isArray(item)) {
                    extractStrings(item);
                } else {
                    result.push(item);
                }
            });
        }
        extractStrings(data);
        return result;
    }

    getExperimentParams(outputList: number[], stationList: Array<Array<number | null>> | Array<Array<Array<number | null>>>, start?: string | null, end?: string | null): ResourceCheckRequestParams {
        const startTime = start ? dayjs(start).valueOf() : null;
        const endTime = end ? dayjs(end).valueOf() : null;
        const inputList = this.getArrayList(stationList);
        const params = {
            resourceAvailableCheckList: [
                {
                    resourceType: CalendarType.CHAMBER,
                    startTime,
                    endTime,
                    chamberPortIdList: outputList as number[],
                },
                {
                    resourceType: CalendarType.RADIO,
                    startTime,
                    endTime,
                    radioPortIdList: inputList,
                },
            ],
            checkAssignment: true,
            checkReservation: true,
        };
        return params;
    }
}
