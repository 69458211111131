import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dict, Option, RemoteOptionWithPage, RemoteParams } from '@app/interfaces/sys/dict';

@Injectable({
    providedIn: 'root',
})
export class DictService {
    private baseUrl = '/dicts';
    private dict: Dict[] = [];

    constructor(private http: HttpClient) {
        // this.find().then(data => {
        //     this.dict = data;
        // });
    }

    async ready(): Promise<void> {
        this.dict = await this.find();
    }

    find(): Promise<Dict[]> {
        return this.http.get<Dict[]>(`${this.baseUrl}`).toPromise();
    }

    async findDictList(type: string): Promise<Option[]> {
        if (!(this.dict && this.dict.length > 0)) {
            this.dict = await this.find();
        }
        return this.dict.filter((item: Dict) => item.type === type).map((item: Dict) => {
            return {
                id: item.key,
                name: item.value,
            };
        });
    }

    async findDictListValue(type: string): Promise<string[]> {
        if (!(this.dict && this.dict.length > 0)) {
            this.dict = await this.find();
        }
        return this.dict.filter((item: Dict) => item.type === type).map((item: Dict) => item.value);
    }

    findValue(type: string, key: string|number): string {
        const option = this.dict.find((item: Dict) => item.type === type && item.key === key?.toString());
        return option ? option.value : '';
    }

    async fetchRemoteOption(params: RemoteParams, requestName: string): Promise<RemoteOptionWithPage> {
        return this.http.get<RemoteOptionWithPage>(`${requestName}`, {
            params: Object.assign(params)
        }).toPromise();
    }

}
