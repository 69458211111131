<div *ngFor="let item of data">
    <app-notification-item
        [item]="item"
        [showProjectName]="showProjectName"
        [showUserName]="showUserName"
        [canResponse]="canResponse"
        (detail)="handleDetail($event)"
    ></app-notification-item>
</div>

<app-notification-detail
    #notificationDetail
    [canResponse]="canResponse"
    (responseSuccess)="onResponseSuccess()"
></app-notification-detail>
