import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, LoginBody } from '@app/interfaces/sys/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthHttp {
    constructor(private http: HttpClient) {}

    login(data: LoginBody): Promise<Auth> {
        return this.http.post<Auth>('/auth?_allow_anonymous=true', data).toPromise();
    }

    async logout(): Promise<object> {
        return this.http.delete('/auth').toPromise();
    }

    async current(): Promise<Auth> {
        return this.http.get<Auth>('/auth').toPromise();
    }
}
