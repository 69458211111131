<div *ngIf="item" class="item-container">
    <div class="user-info">
        <div class="avatar">
            <app-avatar [info]="item.user"></app-avatar>
        </div>

        <div class="user-name" (click)="handleClickName()">{{ item.user.firstName }} {{ item.user.lastName }}</div>
    </div>
    <div *ngIf="item.projectList && item.projectList.length > 0" class="project-list">
        <div *ngFor="let project of item.projectList; let i = index" class="project-item">
            <app-progress [percent]="project.progress"></app-progress>
            <div class="project-info">
                <div class="project-name">
                    {{ project.name }}
                </div>
                <div class="project-progress">Progress: {{ project.progress }}%</div>
            </div>
        </div>
    </div>
</div>
