import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '@app/interfaces/sys/dict';

@Component({
    selector: 'app-form-multiple-input',
    templateUrl: './form-multiple-input.component.html',
    styleUrls: ['./form-multiple-input.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormMultipleInputComponent),
            multi: true,
        },
    ],
})
export class FormMultipleInputComponent implements OnInit {
    @Input('options') set set_source(arr: SelectOption[]) {
        if (arr) {
            this.typeOptions = [
                ...arr,
                {
                    value: 'Other',
                    label: 'Other'
                }
            ];
        }
    }
    @Input() leftPlaceholder = '';
    @Input() splitPlaceholder = '/';
    @Input() rightPlaceholder = '';
    typeOptions: SelectOption[] = [];
    type = '';
    value = '';
    leftValue = '';
    rightValue = '';

    onChange: any = () => {
    }
    onTouch: () => void = () => null;

    constructor() {
    }

    ngOnInit(): void {
    }

    writeValue(obj: any): void {
        const item = this.typeOptions.find(it => it.value === obj);
        if (!obj || item) {
            this.type = obj;
        } else {
            const arr = (obj || '').split('/');
            this.type = 'Other';
            this.leftValue = arr?.[0] || '';
            this.rightValue = arr?.[1] || '';
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    changeLeftValue(): void {
        this.getValue();
    }

    changeRightValue(): void {
        this.getValue();
    }

    getValue(): void {
        const isOther = this.type === 'Other';
        this.value = isOther ? `${this.leftValue}/${this.rightValue}` : this.type;
        console.log('value', this.value);
        this.onChange(this.value);
    }

    changeSelect(): void {
        this.getValue();
    }
}
