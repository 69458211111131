<nz-modal
    [(nzVisible)]="isVisible"
    [nzWidth]="750"
    [nzOkLoading]="submitLoading"
    [nzMaskClosable]="false"
    (nzOnCancel)="close()"
    (nzOnOk)="onSubmit()"
    nzTitle="Task"
>
    <ng-container *nzModalContent>
        <app-task-form #taskForm [editData]="editData"></app-task-form>
    </ng-container>
</nz-modal>
