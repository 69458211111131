import {
    Component, ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '@app/interfaces/sys/dict';
import { CommonService } from '@services/sys/common.service';

@Component({
    selector: 'app-auto-form-input',
    templateUrl: './auto-form-input.component.html',
    styleUrls: ['./auto-form-input.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutoFormInputComponent),
            multi: true,
        },
    ],
})
export class AutoFormInputComponent implements OnInit, OnChanges, ControlValueAccessor {
    @ViewChild('selectRef') selectRef!: ElementRef;
    @Output() change = new EventEmitter();
    @Output() selectOptionWidthChange = new EventEmitter();
    @Input() placeHolder = '';
    @Input() selectDefaultWidth = 250;
    @Input() autoWidth = true;
    @Input() loading = false;
    @Input() mode = 'multiple';
    @Input() showArrow = true;
    @Input() allowClear = false;
    @Input() options: SelectOption[] = [];
    value = '';
    selectOptions: SelectOption[] = [];
    selectMaxWidth = 250;
    onChange: any = () => {
    };
    onTouch: () => void = () => null;

    constructor(private commonService: CommonService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            this.selectOptions = [...this.options];
            this.getSelectOptionWidth();
        }
    }

    getSelectOptionWidth(): void {
        this.selectMaxWidth = Math.max(this.commonService.getOptionWidth(this.selectOptions) , this.selectDefaultWidth);
        this.selectOptionWidthChange.emit(this.selectMaxWidth);
    }

    ngOnInit(): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    clearValue(): void {
        this.value = '';
        this.changeValue();
    }

    changeValue(): void {
        this.onChange(this.value);
        this.change.emit();
    }

    getSelectWidth(): number {
        const selectWidth = this.selectRef.nativeElement.clientWidth;
        return selectWidth;
    }

}
