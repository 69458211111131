<div class="menu-container">
    <div class="item title">
        {{ menuTitle }}
    </div>
    <div
        *ngFor="let menu of menus; let i = index"
        class="item menu-item"
        [ngClass]="i === selectIndex ? 'menu-item-active' : ''"
        (click)="handleChange(i)"
    >
        {{ menu.name }}
    </div>
</div>
