import { Component, EventEmitter, Output } from '@angular/core';
import { ApplicationService } from '@services/lm/application.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Application } from '@app/interfaces/lm/application';

@Component({
    selector: 'app-application-detail',
    templateUrl: './application-detail.component.html',
    styleUrls: ['./application-detail.component.less'],
})
export class ApplicationDetailComponent {
    @Output() success = new EventEmitter();

    isVisible = false;
    detail: Application | undefined;

    constructor(private message: NzMessageService, private modal: NzModalService, private applicationService: ApplicationService) {}

    open(row: Application): void {
        this.isVisible = true;
        this.detail = {
            ...row,
        };
    }

    close(): void {
        this.isVisible = false;
        console.log('close', this.isVisible);
    }

    handleShare(): void {
        console.log('handleShare');
        this.modal.confirm({
            nzTitle: 'You will share this conflict !',
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: { top: '40vh' },
            nzOnOk: async () => {
                console.log('OK');
                await this.handleChange('share');
                this.message.success('Shared successfully');
            },
        });
    }

    handleApprove(): void {
        console.log('handleApprove');
        this.modal.confirm({
            nzTitle: 'You will approve this conflict !',
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: { top: '40vh' },
            nzOnOk: async () => {
                console.log('OK');
                await this.handleChange('approved');
                this.message.success('Approved successfully');
            },
        });
    }

    handleReject(): void {
        console.log('handleReject');
        this.modal.confirm({
            nzTitle: 'You will reject this conflict !',
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: { top: '40vh' },
            nzOnOk: async () => {
                console.log('OK');
                await this.handleChange('rejected');
                this.message.success('Rejected successfully');
            },
        });
    }

    async handleChange(action: string): Promise<void> {
        console.log('handleChange', action, this.detail);
        const id = this.detail?.id;
        if (id) {
            const data = {
                id,
                status: action,
            };
            await this.applicationService.changeStatus(data);
            this.close();
            this.success.emit();
        }
    }
}
