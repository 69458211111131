import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task, TaskFile } from '@app/interfaces/lm/task';
import { ProjectService } from '@services/lm/project.service';
import { TaskService } from '@services/lm/task.service';
import { DictService } from '@services/sys/dict.service';
import { TokenService } from '@services/sys/token.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
    styleUrls: ['./task-detail.component.less'],
})
export class TaskDetailComponent {
    @Input() projectNotFinish = true;
    @Input() primeUserId: number | undefined;
    @Input() showProjectDetail = false;
    @Input() canEdit = true;

    @Output() success = new EventEmitter();

    isVisible = false;
    taskId = 0;
    taskDetail: Task | undefined;
    modalTitle = '';
    loading = false;
    fileList: TaskFile[] = [];

    canEditFinishedTestCase = false;
    finishedTestCase = 0;
    editLoading = false;

    filePagination = {
        page: 0,
        size: 10,
    };
    fileTotal = 0;

    constructor(private taskService: TaskService, private modal: NzModalService, private projectService: ProjectService,
                private dictService: DictService,
                private tokenService: TokenService) {
    }

    get canArchiveTask(): boolean {
        // project prime and task owner can archive task
        const userId = this.tokenService.get()?.id;
        return userId === this.primeUserId || userId === this.taskDetail?.ownerId;
    }

    open({ id, type, name }: Task): void {
        this.taskId = id;
        const typeName = this.dictService.findValue('PROJECT_TASK_SELECT_TYPE', type);
        this.modalTitle = typeName + ': ' + name;
        this.isVisible = true;
        this.fetchDetail();
    }

    close(): void {
        this.isVisible = false;
    }

    async fetchDetail(): Promise<void> {
        this.taskDetail = await this.taskService.detail(this.taskId);
    }

    startWork(): void {
        this.modal.confirm({
            nzTitle: 'You will start this task!',
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: { top: '40vh' },
            nzOnOk: async () => {
                const id = this.taskDetail?.id;
                if (id !== undefined) {
                    await this.changeStatus('WORKING');
                }
            },
        });
    }

    archiveTask(): void {
        this.modal.confirm({
            nzTitle: 'You will delete this task!',
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: { top: '40vh' },
            nzOnOk: async () => {
                const id = this.taskDetail?.id;
                if (id !== undefined) {
                    await this.changeStatus('ARCHIVED');
                }
            },
        });
    }

    finishTask(): void {
        this.modal.confirm({
            nzTitle: 'You will finish this task!',
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: { top: '40vh' },
            nzOnOk: async () => {
                const id = this.taskDetail?.id;
                if (id !== undefined) {
                    await this.changeStatus('FINISHED');
                }
            },
        });
    }

    async changeStatus(status: string): Promise<void> {
        if (!this.taskDetail) {
            return;
        }
        const data = {
            ...this.taskDetail,
            status,
        };
        await this.taskService.edit(data);
        this.close();
        this.success.emit();
    }

    startEdit(): void {
        this.canEditFinishedTestCase = true;
        this.finishedTestCase = this.taskDetail?.finishedTestcase ?? 0;
    }

    cancelEdit(): void {
        this.canEditFinishedTestCase = false;
    }

    async confirmEdit(): Promise<void> {
        try {
            if (this.editLoading || !this.taskDetail) {
                return;
            }
            this.editLoading = true;
            const data = await this.taskService.edit({
                ...this.taskDetail,
                finishedTestcase: this.finishedTestCase,
            });
            this.taskDetail = {
                ...data,
            };
        } finally {
            this.canEditFinishedTestCase = false;
            this.editLoading = false;
        }
    }
}
