import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Application, ApplicationParams, ApplicationStatus } from '@app/interfaces/lm/application';

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    private baseUrl = environment.api.mockUrl + '/application';

    constructor(private http: HttpClient) {}

    async find(params: ApplicationParams): Promise<Application[]> {
        return this.http
            .get<Application[]>(`${this.baseUrl}`, {
                params: Object.assign(params),
            })
            .toPromise();
    }

    async changeStatus(data: ApplicationStatus): Promise<void> {
        return this.http.post<void>(`${this.baseUrl}/status`, data).toPromise();
    }
}
