import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpResponseBase,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { TokenService } from '@services/sys/token.service';
import dayjs from 'dayjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { empty, Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, mergeMap, timeout } from 'rxjs/operators';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
    private toLoginModelVisible = false;

    constructor(private injector: Injector,
                private tokenService: TokenService,
                private router: Router) {
    }

    private get notification(): NzNotificationService {
        return this.injector.get(NzNotificationService);
    }

    private get msg(): NzMessageService {
        return this.injector.get(NzMessageService);
    }

    private get modal(): NzModalService {
        return this.injector.get(NzModalService);
    }

    private goTo(url: string): void {
        setTimeout(() => this.injector.get(Router).navigateByUrl(url));
    }

    showMsg(type: string = 'warning', text: string): void {
        const requestUrls = sessionStorage.getItem('requestUrls');
        const arr = JSON.parse(requestUrls || '[]');
        const currentUrl = this.router.url || '';
        const isShowError = !arr.includes(currentUrl);
        if (isShowError) {
            type === 'error' ? this.msg.error(text) : this.msg.warning(text);
            arr.push(currentUrl);
            sessionStorage.setItem('requestUrls', JSON.stringify(arr));
        }
    }

    getEmailError(key: string): void {
        const msg = this.getInValidTpl(key);
        this.showMsg('error', msg);
    }

    // 403 ---> Forbidden(warning)  404 ---> Not Found(error)
    getErrorMessage(ev: HttpResponseBase, reqUrl: string, message: string): void {
        const isNEApi = reqUrl?.startsWith('/api/v1/nes');
        const isScannerApi = reqUrl?.startsWith('/api/v1/lab-monitor');
        const isUEApi = reqUrl?.startsWith('/api/v1/sm');
        const isExperimentApi = reqUrl?.startsWith('/api/v1/experiments');
        const isNotificationApi = reqUrl?.startsWith('/api/v1/notification');
        const apiType = isNEApi ? 'ne' : 'scanner';
        const is404 = ev.status === 404;
        const isSystemError = (is404 || ev.status === 500) && (message === 'Internal server error' || message === 'Error');
        if ((isNEApi || isScannerApi) && is404) {
            this.getEmailError(apiType);
        } else {
            // expired code is 403
            if ((isUEApi || isScannerApi || isExperimentApi || isNotificationApi) && message.includes('expired')) {
                const moduleType = isScannerApi ? 'scannerModule' : (isUEApi ? 'ueModule' : (isExperimentApi ? 'experimentModule' : 'notificationModule'));
                this.msg.warning(this.getExpiredTpl(moduleType));
            } else {
                const msg = isSystemError ? this.getSystemErrorMsg() : message;
                ev.status === 403 ? this.msg.warning(msg) : this.msg.error(msg);
            }
        }
    }

    private checkStatus(ev: HttpResponseBase, reqUrl: string): void {
        if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
            return;
        }

        if (ev instanceof HttpErrorResponse) {
            const isScannerApi = reqUrl?.startsWith('/api/v1/lab-monitor');
            const isError = !ev.ok;
            const message = ev.error.messages?.[0]?.message ?? 'Error';
            // Only show detailed top-right window when unexpected error occured in backend.
            if (isError) {
                if (ev.status === 500 && isScannerApi) {
                    this.getEmailError('scanner');
                } else {
                    this.getErrorMessage(ev, reqUrl, message);
                }
            } else {
                this.msg.warning(message);
            }
        }
    }

    // #endregion

    private toLogin(): void {
        if (this.toLoginModelVisible) {
            return;
        }
        this.toLoginModelVisible = true;
        this.notification.remove();
        this.modal.closeAll();
        this.modal.error({
            nzTitle: `Your session has expired. Please login.`,
            nzOnOk: () => {
                this.toLoginModelVisible = false;
                this.goTo('/login');
            },
            nzOnCancel: () => {
                this.toLoginModelVisible = false;
                console.log('nzOnCancel');
            },
        });
    }

    getInValidTpl(key: string = 'scanner'): string {
        const types: {
            [key: string]: string
        } = {
            scanner: 'Scanning Service',
            ne: 'NE Control Service',
        };
        const currentType = types?.[key] as string;
        const msg = `${currentType} is not available,`;
        return this.getEmailMsg(msg);
    }

    getEmailMsg(msg: string): string {
        return `<ng-template #tpl>
                    <span>${msg} please contact <a href="mailto: support@acentury.co">support@acentury.co</a>.</span>
                </ng-template>`;
    }

    getExpiredTpl(key: string): string {
        const types: {
            [key: string]: string
        } = {
            scannerModule: 'RF Monitoring',
            ueModule: 'UE Control',
            experimentModule: 'Advanced Mobility',
            notificationModule: 'High Availability',
        };
        const currentType = types?.[key] as string;
        const msg = `The licence for ${currentType} has expired!`;
        return this.getEmailMsg(msg);
    }

    getSystemErrorMsg(): string {
        return `<ng-template #tpl>
                    <span>System Error. Please contact <a href="mailto: support@acentury.co">support@acentury.co</a> for assistance.</span>
                </ng-template>`;
    }

    private handleData(ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // 失败时不弹出message
        this.checkStatus(ev, req.url);
        const field = 'name';
        if (ev[field as keyof HttpResponseBase] === 'TimeoutError') {
            return throwError(ev);
        }
        switch (ev.status) {
            case 200:
                sessionStorage.setItem('lastRequestTime', dayjs().toISOString());
                break;
            case 401:
                this.toLogin();
                break;
            default:
                break;
        }
        if (ev instanceof HttpErrorResponse) {
            return throwError(ev);
        } else if (ev instanceof HttpResponse) {
            // = 0 : no error, no warning
            // > 0 : warning, reserved for future use, such as calling deprecated API, etc.
            // < 0 : error
            // seems the current system use -1 only as error code, this change should not break and cause problem
            if (ev.body.code >= 0) {
                const response: object = Object.assign(ev, { body: ev.body.data });
                return of(new HttpResponse(response));
            } else { // implies < 0
                const message = ev.body.messages?.[0]?.message ?? 'Error';
                if (ev.body.messages?.[0]?.type === 'ERROR') {
                    this.msg.error(message);
                } else {
                    this.msg.warning(message);
                }
                if (ev.body.code === -1) { // keep the same logic to avoid breaking changes
                  const response: object = Object.assign(ev);
                  return throwError(new HttpErrorResponse(response));
                }
                return throwError({...ev.body}) // forward the error response to API caller, then they can handle it further
            }
        } else {
            return of(ev);
        }
    }

    private handleTimeoutError(err: TimeoutError, req: HttpRequest<any>): Observable<any> {
        if (req.headers.get('showEmailError') === 'true') {
            this.msg.warning(this.getInValidTpl());
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = req.url;
        if (!url.startsWith('https://') && !url.startsWith('http://') && !url.startsWith(environment.api.mockUrl)) {
            url = environment.api.baseUrl + url;
        }

        const token = this.tokenService.getToken();
        const newReq = req.clone({
            url,
            headers: token ? req.headers.set('token', token) : req.headers,
        });

        const timeoutNumber: number = req.headers.get('timeout')
            ? Number(req.headers.get('timeout'))
            : url.includes('/scanner')
                ? environment.scannerApiTimeout
                : 10 * 60 * 1000;
        return next.handle(newReq).pipe(
            timeout(timeoutNumber),
            mergeMap((ev) => {
                if (ev instanceof HttpResponseBase) {
                    return this.handleData(ev, newReq, next);
                }
                return of(ev);
            }),
            catchError((err: HttpErrorResponse) => {
                if (err instanceof TimeoutError) {
                    return this.handleTimeoutError(err, newReq);
                } else {
                    const skipApis = ['/version/config', '/switchservice/api/v1/version/config'];
                    const skipError = req.headers.get('skipError') === 'true';
                    if (skipApis.includes(req.url) || skipError) {
                        return empty();
                    }
                    const needSkip = this.skipApiResponse(req, err.status);
                    return needSkip ? throwError(err) : this.handleData(err, newReq, next);
                }
            }),
        );
    }

    skipApiResponse(req: HttpRequest<any>, statusCode: number): boolean {
        const url = req.url;
        const hideErrorMessage = req.headers.get('hideErrorMessage');
        const hideAnyError = req.headers.get('hideAnyError') === 'true';
        const needHide = !hideErrorMessage || hideErrorMessage === 'true';
        const apis = ['/sm', '/lab-monitor', '/nes', '/advanced-mobility', '/notification', '/switchservice'];
        const errorSkip = apis.some(it => url?.startsWith(it)) && needHide && (statusCode === 403 || statusCode === 400);
        return errorSkip || hideAnyError;
    }
}
