// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ENABLE } from '@env/./enable';
import { VERSIONS } from './versions';

export const environment = {
    production: false,
    useHash: true,
    api: {
        baseUrl: '/api/v1',
        mockUrl: '/mock',
    },
    commit: VERSIONS[0].commit,
    version: VERSIONS[0].version,
    enablePages: ENABLE,
    maintenance: true,
    showFrontVersion: true,
    rbwInterval: 10000,
    scannerApiTimeout: 1000,
    resource: {
        chamberMaxLength: 50,
        radioMaxLength: 50,
        matrixMaxLength: 50,
        ueServerMaxLength: 50,
        ueMaxLength: 50
    },
    dashboard: {
        diffX: 500,
        diffY: 500
    },
    showBackEndVersion: true
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
