import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AddTaskData,
    Task,
    TaskParams,
    TaskWithPage,
} from '@app/interfaces/lm/task';

@Injectable({
    providedIn: 'root',
})
export class TaskService {
    private baseUrl = '/project-tasks';

    constructor(private http: HttpClient) {}

    async find(params: TaskParams): Promise<TaskWithPage> {
        return this.http
            .get<TaskWithPage>(`${this.baseUrl}`, {
                params: Object.assign(params),
            })
            .toPromise();
    }

    async detail(id: number): Promise<Task> {
        return this.http
            .get<Task>(`${this.baseUrl}/one`, {
                params: Object.assign({ id }),
            })
            .toPromise();
    }

    async add(data: AddTaskData): Promise<void> {
        return this.http.post<void>(`${this.baseUrl}`, data).toPromise();
    }

    async edit(data: AddTaskData): Promise<Task> {
        return this.http.put<Task>(`${this.baseUrl}`, data).toPromise();
    }
}
