import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

interface AvatarInfo {
    avatar?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
}

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
})
export class AvatarComponent implements OnInit {
    @Input() info: AvatarInfo | undefined;
    @Input() size = 34;
    bgColor = '';
    colorArr: {
        [key: string]: string
    } = {
        A: '#EF5B9B',
        B: '#F7B77B',
        C: '#efe466',
        D: '#AFF469',
        E: '#5AFCC3',
        F: '#5FC3FC',
        G: '#BD5FFC',
        H: '#FC5F77',
        I: '#23f7ee',
        J: '#0A6D3A',
        K: '#6B2EAD',
        L: '#AFAF07',
        M: '#80aa65',
        N: '#AAFF56',
        O: '#6c4d63',
        P: '#FFDEAD',
        Q: '#c7ed98',
        R: '#8AC153',
        S: '#cf6453',
        T: '#8893b8',
        U: '#216ae3',
        V: '#e9d2ca',
        W: '#bbc730',
        X: '#d36b91',
        Y: '#e0aff7',
        Z: '#FFD700',
    };

    ngOnInit(): void {
        const firstCode = this.userName.slice(0, 1).toUpperCase();
        this.bgColor = this.colorArr[firstCode];
    }

    get userName(): string {
        return this.info?.name ? this.info.name : this.info?.firstName + ' ' + this.info?.lastName;
    }

    get avatarImage(): string {
        const avatar = this.info?.avatar;
        if (!avatar || avatar.startsWith('http')) {
            return avatar || '';
        }
        return `${environment.api.baseUrl}/files?path=${avatar}`;
    }
}
