<div class="input-container">
    <nz-input-group nzCompact class="input-row">
        <nz-select
            [(ngModel)]="type"
            class="select"
            [class]="type === 'Other' ? 'input-select' : ''"
            (ngModelChange)="changeSelect()"
        >
            <nz-option *ngFor="let option of typeOptions" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
        </nz-select>
        <div class="input-item" *ngIf="type === 'Other'">
            <input
                [(ngModel)]="leftValue"
                type="text"
                nz-input
                class="input-left"
                (ngModelChange)="changeLeftValue()"
                [placeholder]="leftPlaceholder"
            />
            <input
                type="text"
                disabled
                nz-input
                [placeholder]="splitPlaceholder"
                class="input-split"
            />
            <input
                type="text"
                [(ngModel)]="rightValue"
                nz-input="Maximum"
                [placeholder]="rightPlaceholder"
                (ngModelChange)="changeRightValue()"
                class="input-right"
            />
        </div>
    </nz-input-group>
</div>
