<nz-modal
    [(nzVisible)]="visible"
    nzTitle=""
    (nzOnCancel)="close()"
    nzClassName="confirm-dialog"
    [nzFooter]="null"
    [nzMaskClosable]="false"
>
    <div *nzModalContent class="content">
        <div class="title">
            <i nz-icon nzType="question-circle" nzTheme="twotone" [nzTwotoneColor]="'#ff902b'" class="icon"></i>
            <div class="message">
                {{ content }}
            </div>
        </div>

        <div class="action">
            <button nz-button class="text-btn-danger" (click)="handleCancel()">{{ cancelText }}</button>
            <button nz-button class="text-btn-green" *ngIf="showKeepBtn" (click)="handleSubmit()">{{ submitText }}</button>
            <button nz-button class="text-btn-primary" (click)="handleOK()">{{ okText }}</button>
        </div>
    </div>
</nz-modal>
