<div #calendarTable class="calendar-container">
    <div class="filter-container">
        <div class="switch-day">
            <ng-container *ngIf="calendarMode !== 'day'">
                <nz-button-group class="btn-group">
                    <button nz-button nzType="default" (click)="changeCalendarRange('pre')">
                        <i nz-icon nzType="left"></i>
                        Previous {{ calendarMode === 'month' ? 'Month' : 'Week' }}
                    </button>
                    <button nz-button nzType="default" (click)="changeCalendarRange('next')">
                        Next {{ calendarMode === 'month' ? 'Month' : 'Week' }}
                        <i nz-icon nzType="right"></i>
                    </button>
                </nz-button-group>
            </ng-container>
            <ng-container *ngIf="calendarMode === 'day'">
                <nz-button-group class="btn-group">
                    <button nz-button nzType="default" [disabled]="getDisabled('pre')" (click)="changeDay('pre')">
                        <i nz-icon nzType="left"></i>
                        Previous Day
                    </button>
                    <button nz-button nzType="default" [disabled]="getDisabled('next')" (click)="changeDay('next')">
                        Next Day
                        <i nz-icon nzType="right"></i>
                    </button>
                </nz-button-group>
            </ng-container>
        </div>
        <div *ngIf="calendarMode === 'day'" class="center-date">
            {{ getDayRange() }}
        </div>

        <div *ngIf="calendarMode !== 'day' && dateList.length > 0" class="center-date">
            {{ dateList[0] | date: 'YYYY-MM-dd' }} to {{ dateList[dateList.length - 1] | date: 'YYYY-MM-dd' }}
        </div>

        <div class="right-container">
            <app-tab-button [currentIndex]="currentIndex" [tabs]="tabs" (change)="changeView($event)"></app-tab-button>
        </div>
    </div>

    <nz-table [nzShowPagination]="false" [nzData]="tableData" nzBordered
        [nzScroll]="tableData.length > 0 ? nzScroll : { x: '900px' }" [nzFrontPagination]="false"
        (mouseleave)="tableMouseLeave()" [nzLoading]="loading" class="table" #table>
        <thead>
            <tr>
                <th [nzLeft]="true" class="un-select head-th" [nzWidth]="maxNameWidth + 'px'">
                    <div class="header-title">
                        <div class="resource-name">
                            Resource
                            <i nz-icon nzType="unordered-list" nzTheme="outline" class="filter-icon"
                               (mouseenter)="changeFilterVisible(true)"></i>
                        </div>
                        <i nz-icon nzType="question-circle" nzTheme="outline" nz-popover
                           nzPopoverOverlayClassName="calendar-popover" style="font-size: 22px;"
                           [nzPopoverContent]="legendTemplate"></i>
                    </div>
                </th>

                <ng-template #legendTemplate>
                    <app-calendar-legend></app-calendar-legend>
                </ng-template>
                <th [nzWidth]="calendarMode === 'week' ? '150px' : '80px'" *ngFor="let item of dateList, let i = index" class="un-select date-th"
                    [class]="getHeaderColor(item)" [nzAlign]="calendarMode === 'day' ? 'left' : 'center'"
                    (click)="changeDayView(item)" [ngClass]="{ 'date-header': calendarMode === 'month' }">
                    <ng-container *ngIf="calendarMode === 'day'">
                        {{item | date: 'h a'}}
                    </ng-container>

                    <ng-container *ngIf="calendarMode !== 'day'">
                        <div>
                            {{item | date: 'E'}}
                        </div>
                        {{item | date: 'MM-dd'}}
                    </ng-container>
                </th>
                <th nzWidth="80px" *ngIf="canEdit && tableData.length > 0" [nzRight]="true"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of tableData; let i = index;" class="table-tr">
                <td [nzLeft]="true" class="un-select" (mousedown)="$event.preventDefault()">
                    <div class="resource-name">
                        <app-overflow-tooltip [class]="'max-resource-text'" [text]="data.name"
                                              [html]="data.name"></app-overflow-tooltip>
                    </div>
                </td>
                <ng-container *ngFor="let cell of data.dateList;">
                    <td [nzAlign]="'center'" nz-popover nzPopoverOverlayClassName="calendar-popover"
                        [nzPopoverContent]="cell?.reservationData?.length > 0 ? contentTemplate : ''"
                        [ngClass]="{
                         'select-cell': selectDate.includes(data.id + '_' + cell.date + '_' + data.type),
                         'weekend-cell': cell.isWeekend,
                         'different-cell': cell.isDiffUserReserved,
                         'conflict-cell': cell.hasConflict,
                         'request-cell': cell.reservedReservations.length === 0 && cell.requestReservations.length > 0
                        }" class="table-td" (mousedown)="mouseDown($event, data, cell)"
                        (mouseenter)="mouseEnter($event, data, cell)" (mouseup)="mouseUp($event)"
                        [class]="getColor(cell)">
                        <div *ngIf="cell.isSomeHour" class="half-cell"></div>
                    </td>
                    <ng-template #contentTemplate>
                        <div class="popover-container">
                            <app-reservation-infos 
                                [data]="cell.reservationData" 
                                [resourceId]="data.id" 
                                #infoCard 
                                (submitSuccess)="editRefresh()" 
                                (lockPage)="loading=true" 
                                (unlockPage)="loading=false">
                            </app-reservation-infos>
                        </div>
                    </ng-template>
                </ng-container>
                <td [nzAlign]="'center'" [nzRight]="true" class="un-select remove-padding"
                    (mousedown)="$event.preventDefault()" *ngIf="canEdit && tableData.length > 0">
                    <button nz-button nzSize="small" class="edit-button text-btn-primary"
                        (click)="edit(data)">Edit</button>
                </td>
            </tr>
        </tbody>
        <div *ngIf="filterVisible" class="filter-menu" (mouseleave)="changeFilterVisible(false)"
            (mouseleave)="changeFilterVisible(false)" (mouseenter)="filterVisible = true">
            <nz-spin [nzSpinning]="filterLoading">
                <app-calendar-filter [nodes]="nodes" (changeNodes)="changeNodes($event)" type="calendar"></app-calendar-filter>
            </nz-spin>
        </div>
    </nz-table>
</div>

<app-edit-reservation #editDialog (submitSuccess)="editRefresh()"></app-edit-reservation>
