import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'highLight',
})
export class HighLightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, keyword: string): any {
        const reg = new RegExp(keyword ? `${keyword}+` : keyword, 'gi');
        if (value && keyword) {
            let res: string = value?.replace(reg, (match) => `<span style="background-color: yellow;">${match}</span>`);
            return this.sanitizer.bypassSecurityTrustHtml(res);
        }
        return value;
    }
}
