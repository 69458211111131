import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddTeamData, Team, TeamParams, TeamWithPage } from '@app/interfaces/lm/team';
import { TimeLine } from '@app/interfaces/lm/time-card';
import { Account, AccountParams } from '@app/interfaces/sys/account';
import { SelectOption } from '@app/interfaces/sys/dict';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class TeamService {
    private tempUrl = environment.api.mockUrl + '/team';
    private baseUrl = '/teams';

    constructor(private http: HttpClient) {}

    async find(params?: TeamParams): Promise<TeamWithPage> {
        return this.http
            .get<TeamWithPage>(`${this.baseUrl}`, {
                params: params ? Object.assign(params) : null,
            })
            .toPromise();
    }

    async findOptions(): Promise<SelectOption[]> {
        return this.http
            .get<SelectOption[]>(`${this.baseUrl}/all-options`)
            .toPromise();
    }

    async add(data: AddTeamData): Promise<Team> {
        return this.http.post<Team>(`${this.baseUrl}`, data).toPromise();
    }

    async edit(data: AddTeamData): Promise<Team> {
        return this.http.put<Team>(`${this.baseUrl}`, data).toPromise();
    }

    async archive(id: number): Promise<void> {
        return this.http
            .post<void>(`${this.tempUrl}/status`, { id })
            .toPromise();
    }

    async findMember(params: AccountParams): Promise<Account[]> {
        return this.http
            .get<Account[]>(`${this.tempUrl}/member`, {
                params: Object.assign(params),
            })
            .toPromise();
    }

    async findMemberByRole(role: string | undefined): Promise<Account[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                const data = new Array(6).fill(null).map((_, index) => {
                    return {
                        id: index + 1,
                        firstName: 'system',
                        lastName: 'sme',
                        name: 'system sme',
                        teamNames: ['test'],
                        roleNames: ['SME'],
                        email: 'tester@test.test',
                        status: 'Active',
                        phone: '13510900000',
                        enable: false
                    };
                });
                resolve(data);
            }, 300);
        });
    }

    async findTimeLine(id: number): Promise<TimeLine[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                const data: TimeLine[] = [
                    {
                        punchTime: '2021-04-05T23:27:44.000-0400',
                        timeCard: [
                            {
                                type: 0,
                                punchTime: '2021-04-05T23:26:36.000-0400',
                            },
                            {
                                type: 1,
                                punchTime: '2021-04-05T23:27:44.000-0400',
                            },
                        ],
                    },
                    {
                        punchTime: '2021-04-06T03:06:26.000-0400',
                        timeCard: [
                            {
                                type: 0,
                                punchTime: '2021-04-06T03:06:26.000-0400',
                            },
                        ],
                    },
                ];
                resolve(data);
            }, 300);
        });
    }
}
