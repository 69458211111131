import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AddTag, Tag } from '@app/interfaces/lm/tag';
import { TagService } from '@services/lm/tag.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.less'],
})
export class TagComponent implements OnChanges {
    @Input() tags: string | undefined;
    @Input() onlySelect: boolean | undefined;
    @Output() tagsChange = new EventEmitter();

    list: Tag[] = [];
    allList: Tag[] = [];

    loading = false;
    filter = {
        nameLike: '',
    };
    isAdd = false;
    addTagForm: AddTag = {
        name: '',
    };
    editTagForm: Tag = {
        id: 0,
        name: '',
    };

    selectOptions: Tag[] = [];
    displayOptions: Tag[] = [];

    submitLoading = false;
    isVisible = false;

    constructor(private tagService: TagService, private modal: NzModalService) {}

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.allList.length === 0) {
            this.allList = await this.tagService.find(this.filter.nameLike);
        }
        console.log('changes', changes, this.tags);
        if (changes.tags) {
            this.init();
        }
    }

    init(): void {
        const ids = this.tags && this.tags.length > 0 ? this.tags.split(',') : [];
        this.selectOptions = this.allList.filter((item) => ids.includes(item.id.toString()));
        console.log('selectOptions', this.allList, ids);
        this.displayOptions = [...this.selectOptions];
    }

    async fetchData(): Promise<void> {
        try {
            this.loading = true;
            this.list = await this.tagService.find(this.filter.nameLike);
            if (this.filter.nameLike) {
                this.allList = await this.tagService.find('');
            } else {
                this.allList = [...this.list];
            }
        } finally {
            this.loading = false;
        }
    }

    async submitForm(): Promise<void> {
        try {
            if (this.submitLoading) {
                return;
            }
            this.submitLoading = true;
            const response = await this.tagService.add(this.addTagForm);
            console.log('response', response);
            if (response) {
                this.cancelAdd();
                await this.fetchData();
            }
        } finally {
            this.submitLoading = false;
        }
    }

    handleFilter(): void {
        this.openModal();
    }

    openModal(): void {
        this.isVisible = true;
        this.fetchData();
    }

    closeModal(): void {
        this.isVisible = false;
        this.resetData();
    }

    resetData(): void {
        this.cancelAdd();
        this.cancelEdit();
        this.init();
    }

    isSelect(tag: Tag): boolean {
        return this.selectOptions.filter((item: Tag) => item.id === tag.id).length > 0;
    }

    onSelect(tag: Tag): void {
        const ids = this.selectOptions.length > 0 ? this.selectOptions.map((item) => item.id) : [];
        const index = ids.indexOf(tag.id);
        if (index === -1) {
            this.selectOptions.push(tag);
        } else {
            this.selectOptions.splice(index, 1);
        }
    }

    removeSelect(index: number): void {
        this.selectOptions.splice(index, 1);
        const tags = this.selectOptions.length > 0 ? this.selectOptions.map((item) => item.id).join(',') : '';
        this.tagsChange.emit(tags);
    }

    onSubmit(): void {
        const tags = this.selectOptions.length > 0 ? this.selectOptions.map((item) => item.id).join(',') : '';
        this.tagsChange.emit(tags);
        this.closeModal();
    }

    editTag(tag: Tag, event: Event): void {
        event.stopPropagation();
        this.editTagForm = {
            ...tag,
        };
    }

    cancelEdit(): void {
        this.editTagForm = {
            id: 0,
            name: '',
        };
    }

    cancelAdd(): void {
        this.isAdd = false;
        this.addTagForm = {
            name: '',
        };
    }

    async updateTag(): Promise<void> {
        try {
            if (this.submitLoading) {
                return;
            }
            this.submitLoading = true;
            const response = await this.tagService.edit(this.editTagForm);
            console.log('response', response);
            if (response) {
                this.cancelEdit();
                await this.fetchData();
            }
        } finally {
            this.submitLoading = false;
        }
    }

    removeTag(tag: Tag, event: Event): void {
        event.stopPropagation();
        console.log('remove');
        this.modal.confirm({
            nzTitle: 'You will delete this tag !',
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: { top: '40vh' },
            nzOnOk: async () => {
                console.log('OK');
                await this.tagService.remove(tag.id);
                await this.fetchData();
            },
        });
    }
}
