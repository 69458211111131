<nz-collapse [nzBordered]="false" class="panel-container">
    <nz-collapse-panel nzHeader="Project Detail" [(nzActive)]="active" [nzExpandedIcon]="expandedIcon">
        <div class="detail-container">
            <div nz-row class="row">
                <div nz-col nzSpan="24" class="item">
                    <div class="item-label">Project Name</div>
                    <div class="item-value">{{ projectDetail.name }}</div>
                </div>
            </div>
            <div nz-row class="row">
                <div nz-col nzSpan="12" class="item">
                    <div class="item-label">Prime</div>
                    <div class="item-value">{{ projectDetail.primeUserName }}</div>
                </div>
                <div nz-col nzSpan="12" class="item">
                    <div class="item-label">Engineer</div>
                    <div class="item-value"></div>
                </div>
            </div>

            <div nz-row class="row">
                <div nz-col nzSpan="12" class="item">
                    <div class="item-label">Start Date</div>
                    <div class="item-value">{{ projectDetail.planStartDate | date: 'yyyy-MM-dd' }}</div>
                </div>
                <div nz-col nzSpan="12" class="item">
                    <div class="item-label">End Date</div>
                    <div class="item-value">{{ projectDetail.planEndDate | date: 'yyyy-MM-dd' }}</div>
                </div>
            </div>

            <div nz-row class="row">
                <div nz-col nzSpan="24" class="item">
                    <div class="item-label">Description</div>
                    <div class="item-value">{{ projectDetail.description }}</div>
                </div>
            </div>
        </div>

        <div class="hardware-container">
            <div class="hardware-title">
                Recauses
            </div>
        </div>

        <ng-template #expandedIcon>
            <em nz-icon [nzType]="active ? 'minus' : 'plus'" nzTheme="outline" class="expand-icon"></em>
        </ng-template>
    </nz-collapse-panel>
</nz-collapse>
