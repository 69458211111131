<div class="st-table-container" [class]="page && page.show ? 'pagination-table' : 'no-pagination-table'">
    <nz-table
        #dataTable
        [nzSize]="size"
        [nzData]="data"
        id="stTable"
        [nzFrontPagination]="pagination"
        [nzLoading]="loading"
        [nzPageSize]="ps"
        [nzPageIndex]="pi"
        [nzScroll]="data && data.length ? scroll : { x: scroll?.x }"
        class="st-table"
        [class]="dragColumn ? 'resize-table ' : '' + (pagination ? 'front-pagination-table' : '')"
    >
        <thead>
        <tr>
            <th *ngIf="ifIndex" nzWidth="60px" scope="col"></th>
            <ng-container *ngFor="let column of columns; let columnIndex = index">
                <ng-container *ngIf="column.type === 'selection'; else thTemplate">
                    <th
                        scope="col"
                        [nzWidth]="column?.realWidth || column.width"
                        [nzChecked]="checked"
                        [nzIndeterminate]="indeterminate"
                        (nzCheckedChange)="onAllChecked($event)"
                        nz-resizable
                        nzBounds="window"
                        nzPreview
                        [nzDisabled]="!dragColumn"
                        [nzMinWidth]="resizeMinWidth"
                        (nzResizeStart)="resizeStart($event, columnIndex)"
                        (nzResizeEnd)="resizeEnd($event, columnIndex)"
                    >
                        <nz-resize-handle *ngIf="dragColumn" nzDirection="right" class="drag-trigger">
                            <div class="line"></div>
                        </nz-resize-handle>
                    </th>
                </ng-container>
                <ng-template #thTemplate>
                    <th
                        scope="col"
                        *ngIf="!column.iif || column.iif(column)"
                        [nzWidth]="column?.realWidth || column.width"
                        [nzShowSort]="column.sort"
                        [nzSortDirections]="column.sort"
                        (nzSortOrderChange)="handleSort($event, column)"
                        [nzAlign]="column.nzAlign"
                        [nzFilters]="column.listOfFilter"
                        [nzFilterMultiple]="column.nzFilterMultiple"
                        [nzFilterFn]="column.nzFilterFn"
                        [class]="column.className + (dragColumn ? ' resize-cell' : '')"
                        (nzFilterChange)="handleFilterChange($event, column?.filterKey || column.index)"
                        class="header-cell"
                        nz-resizable
                        nzBounds="window"
                        nzPreview
                        [nzDisabled]="!dragColumn"
                        [nzMinWidth]="column?.dragMinWidth || resizeMinWidth"
                        [nzMaxWidth]="column?.dragMaxWidth"
                        (nzResizeStart)="resizeStart($event, columnIndex)"
                        (nzResizeEnd)="resizeEnd($event, columnIndex)"
                    >
                        <i
                            *ngIf="dragColumn && columnIndex === 0"
                            nz-icon
                            nz-tooltip="Reset Column Width"
                            nzType="sync"
                            class="close-icon resize-reset-icon"
                            (click)="resetTablePosition(); $event.stopPropagation()"
                        ></i>
                        {{ column.title }}
                        <nz-resize-handle *ngIf="dragColumn" nzDirection="right" class="drag-trigger">
                            <div class="line"></div>
                        </nz-resize-handle>
                    </th>
                </ng-template>
            </ng-container>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="getClassName(data, i)"
            class="st-font {{highlightId && data.id === highlightId ? 'click-row': ''}}" (click)="handleClickRow(data)">
            <td *ngIf="ifIndex" [nzAlign]="align">{{ i + 1 }}</td>
            <ng-container *ngFor="let column of columns">
                <ng-container *ngIf="!column.iif || column.iif(column)">
                    <ng-container *ngIf="!column.render; else render">
                        <ng-container [ngSwitch]="column.type">
                            <td
                                *ngSwitchCase="'selection'"
                                [nzChecked]="setOfCheckedId.has(data.id)"
                                [nzDisabled]="data.disabled"
                                [nzAlign]="column.nzAlign" [class]="column.className"
                                (nzCheckedChange)="onItemChecked(data.id, $event)"
                            ></td>

                            <td *ngSwitchCase="'link'" [nzAlign]="column.nzAlign" [class]="column.className">
                                <a (click)="column.click(data)">
                                    {{ getValueByPath(data, column.index, column, i) }}
                                </a>
                            </td>

                            <td *ngSwitchCase="'date'" [nzAlign]="column.nzAlign" [class]="column.className">
                                {{ getValueByPath(data, column.index, column, i) | date: column.dateFormat }}
                            </td>

                            <td *ngSwitchDefault [nzAlign]="column.nzAlign" [class]="column.className">
                                <ng-container *ngIf="column.highlight; else textTemplate">
                                    <div [innerHTML]="getValueByPath(data, column.index, column, i) | highLight: keyword"></div>
                                    <!--                                <app-overflow-tooltip-->
                                    <!--                                    [text]="getValueByPath(data, column.index, column, i) | highLight: keyword"-->
                                    <!--                                ></app-overflow-tooltip>-->
                                </ng-container>

                                <ng-template #textTemplate>
                                    <div [class]="column.className">{{ getValueByPath(data, column.index, column, i) }}</div>
                                    <!--                                <app-overflow-tooltip-->
                                    <!--                                    [className]="column.className"-->
                                    <!--                                    [text]="getValueByPath(data, column.index, column, i)"-->
                                    <!--                                ></app-overflow-tooltip>-->
                                </ng-template>
                            </td>
                        </ng-container>
                    </ng-container>
                    <ng-template #render>
                        <td [nzAlign]="column.nzAlign" [class]="column.className">
                            <ng-container
                                *ngTemplateOutlet="templates[column.render]; context: { $implicit: data }"></ng-container>
                        </td>
                    </ng-template>
                </ng-container>
            </ng-container>
        </tr>
        </tbody>
    </nz-table>

    <div class="pagination-container" [ngClass]="{'with-total-text': paginationText}">
        <nz-pagination
            *ngIf="page && page.show"
            [nzPageIndex]="pi"
            [nzTotal]="total"
            [nzPageSize]="ps"
            [nzShowTotal]="showTotal ? totalTemplate : null"
            nzShowSizeChanger
            (nzPageIndexChange)="handleSearch($event)"
            (nzPageSizeChange)="handleChangeSize($event)"
        ></nz-pagination>

        <ng-template
            #totalTemplate
            let-total
        >
            {{paginationText || 'Total'}} {{ total }}
        </ng-template>
    </div>
</div>
