import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadResult } from '@app/interfaces/lm/file';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FileService {

    private baseUrl = '/files';

    constructor(private http: HttpClient) {
    }

    upload(data: FormData): Observable<HttpEvent<UploadResult>> {
        return this.http.post<UploadResult>(`${this.baseUrl}`, data, {
            reportProgress: true,
            observe: 'events'
        });
    }
}
