import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Account,
    AccountParams,
    AccountWithPage,
    AddAccountData,
    EnableData,
    SkillData,
    UpdatePassword,
} from '@app/interfaces/sys/account';
import { SelectOption } from '@app/interfaces/sys/dict';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private baseUrl = '/users';

    constructor(private http: HttpClient) {}

    async find(params?: AccountParams): Promise<AccountWithPage> {
        return this.http
            .get<AccountWithPage>(`${this.baseUrl}`, {
                params: params ? Object.assign(params) : null,
            })
            .toPromise();
    }

    async findList(params?: AccountParams): Promise<SelectOption[]> {
        return this.http.get<SelectOption[]>(`${this.baseUrl}/all-options`, {
                params: params ? Object.assign(params) : null,
            })
            .toPromise();
    }

    async add(data: AddAccountData): Promise<Account> {
        return this.http.post<Account>(`${this.baseUrl}`, data).toPromise();
    }

    async edit(data: Account): Promise<Account> {
        return this.http.put<Account>(`${this.baseUrl}`, data).toPromise();
    }

    async detail(id: number): Promise<Account> {
        return this.http
            .get<Account>(`${this.baseUrl}/one`, {
                params: Object.assign({ id }),
            })
            .toPromise();
    }

    async resetPassword(id: number): Promise<void> {
        return this.http.delete<void>(`${this.baseUrl}/password?id=${id}`).toPromise();
    }

    async remove(id: number): Promise<void> {
        return this.http
            .delete<void>(`${this.baseUrl}`, {
                params: Object.assign({ id }),
            })
            .toPromise();
    }

    async enable(data: EnableData): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}/enable`, data).toPromise();
    }

    async addSkill(data: SkillData): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 200);
        });
    }

    async removeSkill(data: SkillData): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 200);
        });
    }

    async updatePassword(data: UpdatePassword): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}/password`, data).toPromise();
    }
}
