<nz-modal
    [(nzVisible)]="isVisible"
    [nzMaskClosable]="false"
    [nzTitle]="title"
    (nzOnCancel)="close()"
    [nzOkLoading]="submitLoading || loading"
    nzWidth="800px"
    (nzOnOk)="submit()"
>
    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="loading">
            <div class="form-container">
                <div class="card-container" [class]="list[0].length > 0 && list[1].length > 0 ? 'request-container' : ''" *ngFor="let listData of list; let i = index;">
                    <div class="card-title" *ngIf="listData.length > 0">{{ i === 0 ? 'Request' : 'Reservation' }}</div>
                    <div *ngFor="let item of listData; let j = index;" class="row-item" nz-row>
                        <div nz-col nzSpan="3" style="display: flex; align-items: center">
                            # {{ j + 1 }} <span *ngIf="item.isOthers" style="margin-left: 3px; color: red">{{ calendarEditPermissionAll ? '(' + getUsernameById(item.userId) + ')' : '(Others)' }}</span>
                        </div>
                        <div nz-col nzSpan="10" class="select-row">
                            <nz-form-label nzSpan="8" nzRequired>StartTime</nz-form-label>
                            <nz-form-control nzSpan="16" nzErrorTip="Please select startTime">
                                <nz-date-picker
                                    [nzDisabled]="item.startDisabled"
                                    [nzDisabledDate]="disabledDate"
                                    [nzDisabledTime]="disabledTime"
                                    [nzShowTime]="{ nzFormat: 'hh:mm a', nzHideSeconds: true, nzUse12Hours: true }"
                                    nzFormat="yyyy-MM-dd hh:mm a"
                                    [(ngModel)]="item.startTime"
                                ></nz-date-picker>
                            </nz-form-control>
                        </div>

                        <div nz-col nzSpan="10" class="select-row">
                            <nz-form-label nzSpan="8" nzRequired>endTime</nz-form-label>
                            <nz-form-control nzSpan="16" nzErrorTip="Please select endTime">
                                <nz-date-picker
                                    [nzDisabled]="item?.endDisabled"
                                    [nzDisabledDate]="disabledDate"
                                    [nzDisabledTime]="disabledTime"
                                    [nzShowTime]="{ nzFormat: 'hh:mm a', nzHideSeconds: true, nzUse12Hours: true }"
                                    nzFormat="yyyy-MM-dd hh:mm a"
                                    [(ngModel)]="item.endTime"
                                ></nz-date-picker>
                            </nz-form-control>
                        </div>

                        <div nz-col nzSpan="1">
                            <i *ngIf="!disabledRemove(i, j)" nz-icon nzType="minus-circle-o" class="dynamic-delete-button"
                               (click)="removeField(i, j, $event)"></i>
                        </div>

                        <div *ngIf="hasRequestApprovalPermission && calendarEditPermissionAll && !item.id" class="user-select-container">
                            <div class="user-select-title">Reserve for others</div>
                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choose to reserve resource for others" [(ngModel)]="item.assigneeUserId">
                                <nz-option *ngFor="let user of users" [nzLabel]="user.label" [nzValue]="user.value"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                </div>
                
                <button nz-button nzType="primary" class="add-btn" (click)="addField()">
                    <i nz-icon nzType="plus"></i>
                    Add
                </button>
            </div>

        </nz-spin>
    </ng-container>
</nz-modal>
