import {
    Component,
    HostListener,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Task, TaskParams } from '@app/interfaces/lm/task';
import { Option } from '@app/interfaces/sys/dict';
import { STChange, STColumn, STData, STScroll } from '@app/interfaces/sys/st';
import { TaskService } from '@services/lm/task.service';
import { DictService } from '@services/sys/dict.service';
import { SettingsService } from '@services/sys/settings.service';
import { AddTaskComponent } from './components/add-task/add-task.component';
import { EditTaskComponent } from './components/edit-task/edit-task.component';
import { TaskDetailComponent } from './components/task-detail/task-detail.component';

@Component({
    selector: 'app-task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.less'],
})
export class TaskListComponent implements OnInit {
    @ViewChild('addTask') addTask: AddTaskComponent | undefined;
    @ViewChild('editTask') editTask: EditTaskComponent | undefined;
    @ViewChild('taskDetail') taskDetail: TaskDetailComponent | undefined;

    @Input() canAdd = true;
    @Input() canEdit = true;
    @Input() showProjectDetail = false;
    @Input() projectNotFinish = true;
    @Input() primeUserId: number | undefined;
    @Input() taskFilter: TaskParams = {} as TaskParams;

    loading = false;
    data: Task[] | undefined;
    params: {
        [key: string]: string
    } = {};
    columns: STColumn[] = [
        {
            title: 'Project',
            index: 'projectName',
            type: 'link',
            width: '100px',
            iif: () => this.taskFilter?.projectId === undefined || this.taskFilter?.projectId === null,
            click: (record: STData) => {
                console.log('record', record);
                this.router.navigate(['/project/detail', record.projectId]);
            },
        },
        {
            title: 'Type',
            index: 'type',
            width: '120px',
            format: (item) => {
                return this.dictService.findValue('PROJECT_TASK_SELECT_TYPE', item.type);
            },
            nzFilterMultiple: false,
            nzFilterFn: true
        },
        {
            title: 'Name',
            index: 'name',
            width: '100px',
            sort: true
        },
        {
            title: 'Start Date',
            index: 'planStartDate',
            type: 'date',
            dateFormat: 'yyyy-MM-dd',
            width: '120px',
            sort: true
        },
        {
            title: 'End Date',
            index: 'planEndDate',
            type: 'date',
            dateFormat: 'yyyy-MM-dd',
            width: '120px',
            sort: true
        },
        {
            title: 'Status',
            index: 'status',
            width: '100px',
            format: (item) => {
                const statusName = this.dictService.findValue('PROJECT_TASK_STATUS', item.status);
                let percent = 0;
                if (item.type === 'TEST_CASE_DEVELOPMENT' && item.finishedTestcase > 0) {
                    percent = Math.round((item.finishedTestcase / item.totalTestcase) * 100);
                }
                return percent > 0 ? `${statusName}(${percent}%)` : statusName;
            },
            listOfFilter: [
                {
                    value: 'CREATED',
                    text: 'Created',
                },
                {
                    value: 'WORKING',
                    text: 'Working',
                },
                {
                    value: 'FINISHED',
                    text: 'Finished',
                },
                {
                    value: 'APPROVED',
                    text: 'Approved',
                },
                {
                    value: 'ARCHIVED',
                    text: 'Archived',
                }
            ],
            nzFilterMultiple: false,
            nzFilterFn: true
        },
        {
            title: 'Action',
            render: 'action',
            width: '150px',
            className: 'remove-padding'
        },
    ];
    taskColumns: STColumn[] = [];

    page = 0;
    size = 10;
    total = 0;
    nzScroll: STScroll = {};
    typeOptions: Option[] = [];

    constructor(private router: Router,
                private taskService: TaskService,
                private dictService: DictService,
                private settingService: SettingsService) {
    }

    async ngOnInit(): Promise<void> {
        this.setScroll();
        await this.fetchTaskType();
        this.columns.forEach(item => {
            if (item.index === 'type') {
                item.listOfFilter = this.typeOptions.map(option => this.formatTypeOption(option));
            }
        });
        this.taskColumns = [...this.columns];
        this.fetchData();
    }

    formatTypeOption(option: Option): {
        text: string;
        value: number | string
    } {
        return {
            text: option.name,
            value: option.id,
        };
    }

    async fetchTaskType(): Promise<void> {
        this.typeOptions = await this.dictService.findDictList('PROJECT_TASK_SELECT_TYPE');
        console.log('typeOptions', this.typeOptions);
    }

    @HostListener('window:resize')
    _resize(): void {
        this.setScroll();
    }

    setScroll(): void {
        this.settingService.setLayout();
        const { y } = this.settingService.layout;
        this.nzScroll = {
            x: '840px',
            y: `${y}px`,
        };
    }

    async fetchData(): Promise<void> {
        try {
            this.loading = true;
            const query = {
                ...this.taskFilter,
                ...this.params,
                page: this.page,
                size: this.size,
            };
            if (!query.status) {
                delete query.status;
            }
            if (!query.type) {
                delete query.type;
            }
            const { content, totalElements } = await this.taskService.find(query);
            this.data = content;
            this.total = totalElements;
        } finally {
            this.loading = false;
        }
    }

    handleRefresh(): void {
        this.page = 0;
        this.fetchData();
    }

    handleChange($event: STChange): void {
        const { type, pi, sort, params } = $event;
        if (type === 'pi') {
            this.page = pi - 1;
            this.fetchData();
        }
        if (type === 'sort') {
            this.params = {
                ...this.params,
                sort
            };
            this.handleRefresh();
        }
        if (type === 'filter') {
            this.params = {
                ...this.params,
                ...params
            };
            this.handleRefresh();
        }
    }

    handleAdd(): void {
        const {
            projectId
        } = this.taskFilter;
        if (projectId) {
            this.addTask?.open(projectId);
        }
    }

    handleDetail(row: Task): void {
        console.log('Detail', row);
        this.taskDetail?.open(row);
    }

    handleEdit(row: Task): void {
        console.log('Edit', row);
        this.editTask?.open(row);
    }
}
