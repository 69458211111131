<div *ngIf="canAdd && projectNotFinish" class="button-group">
    <button class="text-btn-primary" *appPermission="['Project Task Create']" nz-button (click)="handleAdd()">
        <em nz-icon nzType="plus"></em>
        Add Task
    </button>
</div>

<div class="table-container">
    <app-st
        #st
        [loading]="loading"
        [columns]="taskColumns"
        [data]="data"
        [page]="{ show: true }"
        [scroll]="nzScroll"
        [templates]="{ action: action }"
        [ps]="size"
        [pi]="page + 1"
        [total]="total"
        (changes)="handleChange($event)"
    >
        <ng-template #action let-row>
            <ng-container *appPermission="['Project Task Update', 'Ongoing Task Update']">
                <button
                    class="text-btn-primary"
                    *ngIf="(row.status === 'CREATED' || row.status === 'WORKING') && projectNotFinish && canEdit"
                    nz-button
                    nzSize="small"
                    (click)="handleEdit(row)"
                >
                    Edit
                </button>
            </ng-container>
            <button class="text-btn-primary" *appPermission="['Task Detail']" nz-button nzSize="small" (click)="handleDetail(row)">Detail</button>
        </ng-template>
    </app-st>
</div>

<app-add-task #addTask (success)="fetchData()"></app-add-task>
<app-edit-task #editTask (success)="fetchData()"></app-edit-task>
<app-task-detail
    #taskDetail
    [projectNotFinish]="projectNotFinish"
    [primeUserId]="primeUserId"
    [showProjectDetail]="showProjectDetail"
    [canEdit]="canEdit"
    (success)="fetchData()"
></app-task-detail>
