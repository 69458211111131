<nz-modal
    [(nzVisible)]="isVisible"
    [nzWidth]="750"
    [nzOkLoading]="submitLoading"
    [nzMaskClosable]="false"
    (nzOnCancel)="close()"
    (nzOnOk)="onSubmit()"
    nzTitle="Task"
    nzOkText="OK"
    nzCancelText="Cancel"
>
    <ng-container *nzModalContent>
    </ng-container>
</nz-modal>
