import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-status-tag',
    templateUrl: './status-tag.component.html',
    styleUrls: ['./status-tag.component.less']
})
export class StatusTagComponent implements OnInit {
    @Input() status = '';

    constructor() {
    }

    statusDict: {
        [propName: string]: {
            label: string;
            color: string;
        }
    } = {
        START_FAILED: {
            label: 'Start Failed',
            color: 'red',
        },
        STARTING: {
            label: 'Starting',
            color: 'blue',
        },
        RUNNING: {
            label: 'Running',
            color: 'green',
        },
        STOPPING: {
            label: 'Stopping',
            color: 'blue',
        },
        STOPPED: {
            label: 'Stopped',
            color: 'red',
        },
        RECONNECTING: {
            label: 'Reconnecting',
            color: 'gray',
        },
        ONLINE: {
            label: 'Online',
            color: 'green',
        },
        OFFLINE: {
            label: 'Offline',
            color: 'red',
        },
        ABORT: {
            label: 'Abort',
            color: 'volcano',
        },
        SUCCESS: {
            label: 'Success',
            color: 'cyan',
        },
        0: {
            label: 'Success',
            color: 'green',
        },
        '-1': {
            label: 'Failure',
            color: 'orange',
        },
        '-2': {
            label: 'Reject',
            color: 'red',
        },
        SCHEDULED: {
            label: 'Scheduled',
            color: 'orange',
        },
        INFO: {
            label: 'INFO',
            color: 'green',
        },
        WARNING: {
            label: 'WARNING',
            color: 'orange',
        },
        ERROR: {
            label: 'ERROR',
            color: 'red',
        },
        UNREAD: {
            label: 'Unread',
            color: 'red',
        },
        READ: {
            label: 'Read',
            color: 'green',
        },
        DISCONNECTED: {
            label: 'Disconnected',
            color: 'red',
        },
        CONNECTED: {
            label: 'Connected',
            color: 'green',
        },
        UNKNOWN: {
            label: 'Unknown',
            color: 'red',
        },
    };

    ngOnInit(): void {
    }

    getStatusLabel(status: string|number): string|number {
        if (!status && status !== 0) {
            return '';
        }
        return this.statusDict[(typeof status === 'string') ? status.toUpperCase() : status]?.label || status;
    }

    getStatusColor(status: string|number): string {
        if (!status && status !== 0) {
            return '';
        }
        return this.statusDict[(typeof status === 'string') ? status.toUpperCase() : status]?.color || '';
    }

}
