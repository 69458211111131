<ng-container *ngIf="nzType === 'line'">
    <nz-progress
        [nzPercent]="percent"
        [nzShowInfo]="showInfo"
        [nz-tooltip]="percent + '%'"
        [nzStrokeColor]="nzStrokeColor"
        [nzStrokeWidth]="strokeWidth"
        nzSize="small"
        nzStatus="active"
    ></nz-progress>
</ng-container>

<ng-container *ngIf="nzType === 'circle'">
    <nz-progress
        nzType="circle"
        [nzPercent]="percent"
        [nzShowInfo]="showInfo"
        [nzStrokeColor]="nzStrokeColor"
        [nzStrokeWidth]="strokeWidth"
        nzSize="small"
        nzStatus="active"
    ></nz-progress>
</ng-container>
