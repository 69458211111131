import {
    AfterViewInit,
    Component, ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output, Renderer2,
    SimpleChanges,
    TemplateRef, ViewChild,
} from '@angular/core';
import {
    STColumn,
    STData,
    STPage,
    STRowClassName,
    STScroll,
    TablePosition,
} from '@app/interfaces/sys/st';
import { HighLightPipe } from '@app/pipe/high-light.pipe';
import { CommonService } from '@services/sys/common.service';

@Component({
    selector: 'app-st',
    templateUrl: './st.component.html',
    styleUrls: ['./st.component.less'],
    providers: [HighLightPipe],
})
export class StComponent implements OnInit, OnChanges {
    @ViewChild('dataTable') dataTable!: ElementRef;
    @Input() data: STData[] = [];
    @Input() loading = false;
    @Input() ifIndex = false;
    @Input() keyword = '';
    @Input() columns: STColumn[] = [];
    @Input() initColumns!: STColumn[];
    @Input() page!: STPage;
    @Input() scroll!: STScroll;
    @Input() size = 'small';
    @Input() ps = 10;
    @Input() pi = 1;
    @Input() pagination = false;
    @Input() total = 0;
    @Input() templates: {
        [key: string]: TemplateRef<void>;
    } = {};
    @Input() rowClassName!: STRowClassName;
    @Input() highlightId?: number;
    @Input() showTotal = false;
    @Input() paginationText?: string;
    @Input() resizeKey!: string;
    @Input() savePageSize = false;
    @Input() dragColumn = false;
    @Input() resizeMinWidth = this.commonService.stResizeMinWidth;

    @Output() changes = new EventEmitter();
    @Output() clickRow = new EventEmitter();
    @Output() selectChange = new EventEmitter();
    @Output() initData = new EventEmitter();

    isClick = false;
    align = 'center';

    sorts: string[] = [];
    sortMap: {
        [key: string]: string
    } = {
        descend: 'desc',
        ascend: 'asc',
    };

    setOfCheckedId = new Set<number>();
    checked = false;
    indeterminate = false;
    resizing = false;
    tableWidth = 100;
    saveData: TablePosition = {} as TablePosition;
    tableColumnObj!: any;
    savePosition: any;
    lastColumnIndex!: number;
    autoColumns: STColumn[] = [];
    isGetTableHistory = false;

    constructor(private commonService: CommonService) {
    }

    ngOnInit(): void {
    }

    initTable(): void {
        this.isGetTableHistory = false;
        this.initTablePosition();
    }

    async initTablePosition(): Promise<void> {
        if (!this.isGetTableHistory) {
            this.resetTableHistory();
            await this.getTableHistory();
        }
        this.updateTableWidth();
    }

    resetTableHistory(): void {
        this.isGetTableHistory = false;
        this.tableColumnObj = {};
        this.savePosition = {};
        this.saveData = {} as TablePosition;
    }

    async getTableHistory(): Promise<void> {
        try {
            if (!this.resizeKey) {
                return;
            }
            this.loading = true;
            this.isGetTableHistory = true;
            const res = await this.commonService.getTablePosition(this.resizeKey);
            if (res && res.length > 0) {
                const saveData = res?.[0];
                this.saveData = saveData;
                const { size } = this.formatSavedData(saveData);
                if (this.savePageSize) {
                    this.ps = size || this.ps || 10;
                    this.initData.emit(this.ps);
                }
            } else {
                if (!this.savePageSize) {
                    return;
                }
                this.initData.emit();
            }
        } catch (e) {
            if (!this.savePageSize) {
                return;
            }
            this.ps = this.ps || 10;
            this.initData.emit(this.ps);
        } finally {
            this.loading = false;
        }
    }

    formatSavedData(tablePositions?: TablePosition): {
        size: number,
        positions: any
    } {
        const { positions, formatterColumnsObj, size } = this.commonService.formatSavedData(tablePositions);
        this.savePosition = positions;
        this.tableColumnObj = formatterColumnsObj;
        return {
            size,
            positions,
        };
    }

    @HostListener('window:resize')
    _resize(): void {
        this.refreshTableWidth();
    }

    getTableWidth(): void {
        const tableElement = document.getElementById('stTable');
        this.tableWidth = tableElement?.clientWidth || 100;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.refreshCheckedStatus();
            if (JSON.stringify(changes.data.currentValue) === JSON.stringify(changes.data.previousValue)) {
                return;
            }
            this.refreshTableWidth();
        }
    }

    refreshTableWidth(): void {
        this.getTableInitColumns();
        this.initTablePosition();
    }

    getTableInitColumns(): void {
        const initColumns = this.initColumns || this.columns || [];
        const hasAutoWidth = initColumns.some(it => it.autoWidth);
        this.getTableWidth();
        const columns = hasAutoWidth || this.dragColumn ? this.commonService.getContentWidth(initColumns, this.data, this.tableWidth) : initColumns;
        this.autoColumns = columns;
    }

    handleSort(event: string, { index }: STColumn): void {
        const sortIndex = this.sorts.findIndex(item => item === index + ',desc' || item === index + ',asc');
        const sort = index + ',' + this.sortMap[event];
        if (!event) {
            this.sorts.splice(sortIndex, 1);
        } else if (event && sortIndex !== -1) {
            this.sorts[sortIndex] = sort;
        } else if (sortIndex === -1) {
            this.sorts.push(sort);
        }
        this.changes.emit({
            type: 'sort',
            sort: this.sorts,
        });
    }

    handleSearch(event: number): void {
        this.changes.emit({
            type: 'pi',
            pi: event,
        });
    }

    handleChangeSize(event: number): void {
        this.changes.emit({
            type: 'ps',
            ps: event,
        });
        if (this.savePageSize) {
            this.saveTable({
                columns: this.savePosition?.columns,
                size: event,
            });
        }
    }

    getClassName(data: STData, index: number): string {
        if (!this.rowClassName) {
            return '';
        }
        return this.rowClassName(data, index);
    }

    handleClickRow(data: STData): void {
        this.isClick = true;
        this.clickRow.emit(data);
    }

    getValueByPath(data: STData, key: string, column: STColumn, i: number): any {
        let temp: any = data;
        if (key) {
            for (const p of key.split('.')) {
                temp = temp[p];
            }
        }

        if (column?.format) {
            temp = column.format(data, column, i);
        }
        return this.formatHtml(temp);
    }

    formatHtml(value: string): string {
        return value?.toString().replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }

    handleFilterChange(data: number | number[], key: string): void {
        this.changes.emit({
            type: 'filter',
            params: {
                [key]: data,
            },
        });
    }

    updateCheckedSet(id: number, checked: boolean): void {
        if (checked) {
            this.setOfCheckedId.add(id);
        } else {
            this.setOfCheckedId.delete(id);
        }
    }

    onAllChecked(checked: boolean): void {
        this.data.forEach(item => this.updateCheckedSet(item.id, checked));
        this.refreshCheckedStatus();
    }

    onItemChecked(id: number, checked: boolean): void {
        this.updateCheckedSet(id, checked);
        this.refreshCheckedStatus();
    }

    refreshCheckedStatus(): void {
        this.checked = this.data?.length > 0 && this.data.every(item => this.setOfCheckedId.has(item.id));
        this.indeterminate = !this.checked && this.data?.some(item => this.setOfCheckedId.has(item.id));
        this.selectChange.emit(this.setOfCheckedId);
    }

    resizeEnd(event: any, index: number): void {
        const resizedItem = this.columns[index];
        const dragBeforeWidth = parseFloat(resizedItem?.realWidth || resizedItem?.width as string);
        const { width } = event;
        const columns = this.commonService.resizeColumn({
            columns: this.columns,
            resizeIndex: index,
            resizedWidth: width,
            tableWidth: this.tableWidth,
            lastColumnIndex: this.lastColumnIndex,
            resizeMinWidth: this.resizeMinWidth,
        });
        if (parseFloat(columns[index].realWidth) !== dragBeforeWidth) {
            this.changeTableColumn(columns);
            this.saveColumns();
        }
        if (resizedItem?.hasSort) {
            this.columns[index].sort = true;
        }
    }

    saveColumns(): void {
        setTimeout(() => {
            this.saveTable({
                columns: this.columns,
                size: this.savePosition?.size,
            });
        }, 300);
    }

    async saveTable(position: any): Promise<void> {
        if (!this.resizeKey) {
            return;
        }
        const params = {
            positions: JSON.stringify(position),
            filter: '{}',
            type: this.resizeKey,
        };
        const id = this.saveData?.id;
        const res = id ? await this.commonService.editTablePosition({
            id,
            ...params,
        }) : await this.commonService.addTablePosition(params);
        this.saveData = res;
        this.formatSavedData(res);
    }

    resizeStart(event: any, index: number): void {
        this.resizing = true;
        const sort = this.columns[index].sort;
        if (sort) {
            this.columns[index].hasSort = true;
            this.columns[index].sort = false;
        }
    }

    changeTableColumn(compareColumns: STColumn[]): void {
        this.columns.forEach((item, index) => {
            const currentItem = compareColumns[index];
            if (!currentItem) {
                return;
            }
            const { dragMaxWidth, dragMinWidth, width, realWidth, contentWidth, titleWidth } = currentItem;
            const w = realWidth || width as string;
            const widthNum = parseFloat(w);
            item.realWidth = w;
            item.width = w;
            item.dragMinWidth = dragMinWidth || widthNum;
            item.dragMaxWidth = dragMaxWidth || widthNum;
            item.contentWidth = contentWidth;
            item.titleWidth = titleWidth;
        });
    }

    async resetTablePosition(): Promise<void> {
        await this.saveTable({
            columns: [],
            size: this.savePosition?.size,
        });
        this.updateTableWidth();
    }

    updateTableWidth(): void {
        let arr: STColumn[] = [];
        if (this.dragColumn) {
            const data = this.commonService.formatTableRealWidth(this.autoColumns, this.tableWidth, this.tableColumnObj);
            this.lastColumnIndex = data.lastColumnIndex;
            arr = data.columns || [];
        }
        this.changeTableColumn(arr || this.columns || []);
    }
}
