<nz-spin [nzSpinning]="loading">
    <app-st #st [columns]="columns" [data]="data" [loading]="loading" [page]="pages" [templates]="{ action: action }" size="small">
        <ng-template #action let-row>
            <button nz-button (click)="handleDetail(row)">
                <em nz-icon nzType="search" nzTheme="outline"></em>
            </button>
        </ng-template>
    </app-st>
</nz-spin>

<app-application-detail #applicationDetail (success)="fetchData()"></app-application-detail>
