<div class="input-group">
    <div class="form-control tags">
        <ng-container *ngFor="let tag of displayOptions; let i = index">
            <div class="display-tag">
                {{ tag.name }}
                <em nz-icon nzType="close-circle" (click)="removeSelect(i)" class="remove-icon"></em>
            </div>
        </ng-container>
    </div>
    <div class="input-group-append">
        <button nz-button class="text-btn-gray" (click)="handleFilter()">Add</button>
    </div>
</div>

<nz-modal
    [(nzVisible)]="isVisible"
    [nzOkLoading]="submitLoading"
    [nzMaskClosable]="false"
    (nzOnCancel)="closeModal()"
    (nzOnOk)="onSubmit()"
    nzTitle="Select Tag"
>
    <ng-container *nzModalContent>
        <div class="card-body">
            <div class="card">
                <div class="filter-container input-group">
                    <input [(ngModel)]="filter.nameLike" type="text" nz-input />

                    <button class="search-button" nz-button nzType="default" nzSearch (click)="fetchData()">
                        <em nz-icon nzType="search"></em>
                        Search
                    </button>
                </div>

                <div class="list-container">
                    <ng-container *ngFor="let tag of list; let i = index">
                        <div *ngIf="editTagForm.id && editTagForm.id === tag.id; else showTag" class="edit-tag">
                            <div class="edit-input">
                                <input nz-input type="text" class="form-control" [(ngModel)]="editTagForm.name" />
                            </div>
                            <div class="action">
                                <span class="action-text" (click)="updateTag()">OK</span>
                                <span class="action-text" (click)="cancelEdit()">Cancel</span>
                            </div>
                        </div>
                        <ng-template #showTag>
                            <div class="tag-item" (click)="onSelect(tag)">
                                <div class="icon">
                                    <em *ngIf="isSelect(tag)" nz-icon nzType="check"></em>
                                </div>
                                <div class="name">
                                    {{ tag.name }}
                                </div>
                                <div *ngIf="!onlySelect" class="action">
                                    <span class="action-text" (click)="editTag(tag, $event)">Edit</span>
                                    <span class="action-text action-delete" (click)="removeTag(tag, $event)">Delete</span>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="!onlySelect" class="add-container">
                <ng-container *ngIf="!isAdd; else add">
                    <button nz-button class="add-tag text-btn-primary" (click)="isAdd = true">Create Tag</button>
                </ng-container>
                <ng-template #add>
                    <input nz-input type="text" [(ngModel)]="addTagForm.name" />
                    <button nz-button class="add-tag-button text-btn-primary" (click)="submitForm()">OK</button>
                    <button nz-button class="text-btn-danger" (click)="cancelAdd()">Cancel</button>
                </ng-template>
            </div>
        </div>
    </ng-container>
</nz-modal>
