<div *ngIf="data" class="matrix-container" #matrixContainer>
  <nz-table
    [style]="tableStyle"
    #fixedTable
    [nzData]="data.xLabels"
    [nzLoading]="loading"
    [nzScroll]="tableScroll"
    class="table-container"
    [nzFrontPagination]="false"
  >
    <thead>
    <tr>
      <th nzLeft class="label-cell" [style]="cellStyle" [nzWidth]="data.itemWidth" scope="col"></th>
      <th
        scope="col"
        *ngFor="let xLabel of data.xLabels; let xIndex = index"
        [style]="cellStyle"
        [nzWidth]="data.itemWidth"
        [ngClass]="['label-cell x-label', xIndex === hoverPosition[0] ? 'cell-active' : '']"
      >
        {{ xLabel }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let yLabel of data.yLabels; let yIndex = index">
      <td nzLeft [style]="cellStyle" class="label-cell y-label"
          [ngClass]="yIndex === hoverPosition[1] ? 'cell-active' : ''">
        {{ yLabel }}
      </td>
      <td
        *ngFor="let xLabel of data.xLabels; let xIndex = index"
        [ngClass]="['cell', isSelect(xIndex, yIndex) ? 'cell-active' : '']"
        (mouseenter)="mouseEnter(xIndex, yIndex)"
        (mouseleave)="mouseLeave()"
        (click)="selectCell(xIndex, yIndex, $event)"
      >
        <div [style]="cellStyle">
          <ng-container
            *ngTemplateOutlet="contentTemplate; context: { $implicit: getCellData(xIndex, yIndex) }"
          ></ng-container>
        </div>
      </td>
    </tr>
    </tbody>
  </nz-table>
</div>
