import { Auth } from '@app/interfaces/sys/auth';
import { ChartPort } from '@app/interfaces/rm/dashboard';

export enum CalendarType {
    MATRIX = 'MATRIX',
    CHAMBER = 'CHAMBER',
    RADIO = 'RADIO',
    UE = 'UE'
}

export interface ResourceTypeOption {
    label: string;
    value: string;
    checked: boolean;
}

export interface ResourceFilter {
    CHAMBER?: string[];
    RADIO?: string[];
    MATRIX?: string[];
    UE?: string[];
}

export interface CalendarResource {
    id: string;
    type?: string;
    name: string;
    calendarVOList?: CalendarVOList[];
}

export interface CalendarTableData {
    resources: CalendarResource[];
    reservations: any[];
    reservationInfo?: any[];
}

export interface ResourceType {
    title: string;
    key: string;
    loading?: boolean;
}

export interface ResourceTree {
    title: string;
    key: string;
    expanded?: boolean;
    children?: ResourceTree[];
    id?: string;
    isLeaf?: boolean;
    checked?: boolean;
    selected?: boolean;
    halfChecked?: boolean;
    ports?: ChartPort[];
}

export interface ResourceParams {
    typeList?: string[];
    statusList?: string[];
    chamberIdList?: string[];
    radioIdList?: string[];
    ueIdList?: string[];
    matrixIdList?: string[];
    removeIdList?: string[];
}

export interface CalendarParams extends ResourceParams {
    startTime: number;
    endTime: number;
}

export interface CalendarVOList {
    id: string;
    createdById: number;
    createdByName: string;
    createdDate: string;
    chamberName: string;
    radioName: string;
    matrixName: string;
    chamberId: string;
    radioId: string;
    ueId: string;
    matrixId: string;
    startTime: number;
    endTime: number;
    type: string;
    userName: string;
    userId: number;
    userEmail: string;
    isSomeHour?: boolean;
    status?: string;
    startDisabled?: boolean;
    endDisabled?: boolean;
    approvedUserName: string;
    approvedUserId: number;
}

export interface ResourceCalendar {
    id: string;
    name: string;
    calendarVOList: CalendarVOList[];
}

export interface ReservationCalendar {
    type: string;
    resourceCalendarList: ResourceCalendar[];
}

export interface ReservationAdd {
    startTime: number;
    endTime: number;
    type: string;
    description?: string;
    chamberId?: number;
    radioId?: number;
    ueId?: number;
    assigneeUserId?: number;
}

export interface ReservationCancel {
    id: number;
    startTime: number;
    endTime: number;
    description?: string;
}

export interface ApprovalSetting {
    id: number;
    approval: boolean;
    type: string;
}

export interface ApprovalSettingItem {
    title: string;
    approval: boolean;
    key: string;
    loading?: boolean;
}

export interface ApprovalType {
    CHAMBER?: boolean;
    RADIO?: boolean;
    MATRIX?: boolean;
    UE?: boolean;
}

export interface EditApprovalSetting {
    type: string;
    approval: boolean;
}

export interface Request {
    id: number;
    type: string;
    startTime: number;
    endTime: number;
    resourceId: number;
    resourceName: string;
    status: string;
    createdDate: string;
    createdById: number;
    createdByName: string;
    applicantUserName: string;
    approvedDate: string;
    approvedUser: number;
}

export interface CalendarRequest {
    resourceId?: string;
    calendarId?: string;
    type?: string;
}

export interface RequestParams extends CalendarRequest {
    page: number;
    size: number;
    status: string;
    sort?: string;
    keyword?: string;
}

export interface RequestWithPage {
    content: Request[];
    totalElements: number;
}

export interface AuditRequest {
    id: number;
    status: string;
    comment?: string;
}

export interface EditRequest {
    id: number;
    status: string;
    startTime: number;
    endTime: number;
    comment?: string;
}

export interface CalendarOneData {
    date: string;
    reservationData: CalendarVOList[];
    requestReservations?: CalendarVOList[];
    reservedReservations?: CalendarVOList[];
    isSomeHour: boolean;
    disabled: boolean;
    isWeekend: boolean;
}

export interface CalendarTableRow {
    id: number;
    name: string;
    type: string;
    dateList: CalendarOneData[];
    calendarVOList: CalendarVOList[];
}


export interface OverwriteOneParams {
    chamberId?: number;
    radioId?: number;
    ueId?: number;
    type: string;
    description?: string;
    startTimeBoundary?: number;
    endTimeBoundary?: number;
    times: {
        startTime: number;
        endTime: number;
        assigneeUserId?: number;
    }[];
}

export interface ResourceCalendarParams {
    startTime: number;
    endTime: number;
}

export interface ReservationRole {
    info: Auth;
    isTester: boolean;
    isLabAdmin: boolean;
}

export interface CellSelfReservation {
    selfReservations: CalendarVOList[];
    selfReserved: CalendarVOList[];
    selfRequest: CalendarVOList[];
}

export interface CellOtherReservation {
    otherReservations: CalendarVOList[];
    otherReserved: CalendarVOList[];
    otherRequest: CalendarVOList[];
}

export interface CellReservation extends CellSelfReservation, CellOtherReservation {}


export interface TimeSegment {
    id?: string;
    startTime: number;
    endTime: number;
    userId?: number;
    startDisabled?: boolean;
    endDisabled?: boolean;
    status?: string;
    isOthers?: boolean;
    assigneeUserId?: number;
}

export interface ResourceCheckParams {
    resourceType: string;
    startTime?: number|null;
    endTime?: number|null;
    radioPortIdList?: number[];
    chamberPortIdList?: number[];
    matrixIdList?: number[];
    ueIdList?: number[];
}

// checkAssignment & checkReservation backend default value: true.
export interface ResourceCheckRequestParams {
    resourceAvailableCheckList: ResourceCheckParams[];
    checkAssignment?: boolean;
    checkReservation?: boolean;
}
