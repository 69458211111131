import {
    Component,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Account, AccountParams } from '@app/interfaces/sys/account';
import { Auth } from '@app/interfaces/sys/auth';
import { SelectOption } from '@app/interfaces/sys/dict';
import { STChange, STColumn, STData, STPage, STScroll } from '@app/interfaces/sys/st';
import { TeamService } from '@services/lm/team.service';
import { AccountService } from '@services/sys/account.service';
import { RoleService } from '@services/sys/role.service';
import { SettingsService } from '@services/sys/settings.service';
import { TokenService } from '@services/sys/token.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: ' app-account-list',
    templateUrl: './account-list.component.html',
})
export class AccountListComponent implements OnInit, OnChanges {
    @Input() searchKeyword = '';
    @Input() resizeKey!: string;
    @Input() teamId?: number;

    @Output() changePageSize = new EventEmitter();

    roleList: SelectOption[] = [];
    teamList: SelectOption[] = [];
    sort = '';
    sortItems: string[] = [];
    nzScroll: STScroll = {};

    accountColumns: STColumn[] = [];
    loading = false;
    columns: STColumn[] = [
        {
            title: 'Avatar',
            render: 'avatar',
            width: '90px',
        },
        {
            title: 'Username',
            index: 'username',
            highlight: true,
            width: '100px',
            sort: true
        },
        {
            title: 'First Name',
            index: 'name',
            highlight: true,
            width: '120px',
            sort: true
        },
        {
            title: 'Last Name',
            index: 'lastName',
            highlight: true,
            width: '100px',
            sort: true
        },
        // {
        //     title: 'Team',
        //     index: 'teamNames',
        //     highlight: true,
        //     width: '90px',
        //     format: (data: STData) => {
        //         return data?.teamNames?.join(',') || '';
        //     },
        //     nzFilterMultiple: false,
        //     listOfFilter: [],
        //     nzFilterFn: true,
        //     filterKey: 'teamIdIn'
        // },
        {
            title: 'Role',
            index: 'roleNames',
            format: (data: STData) => {
                return data?.roleNames?.join(',') || '';
            },
            highlight: true,
            width: '100px',
            nzFilterMultiple: false,
            nzFilterFn: true,
            filterKey: 'roleId'
        },
        {
            title: 'Email',
            index: 'email',
            highlight: true,
            width: '150px',
            sort: true
        },
        {
            title: 'Phone',
            index: 'phone',
            highlight: true,
            width: '150px',
        },
        {
            title: 'Status',
            index: 'status',
            render: 'status',
            width: '80px',
            filterKey: 'enable',
            listOfFilter: [
                {
                    value: true,
                    text: 'Enable',
                },
                {
                    value: false,
                    text: 'Disable ',
                },
            ],
            nzFilterMultiple: false,
            nzFilterFn: true,
            className: 'remove-padding'
        },
        {
            title: 'Action',
            render: 'action',
            width: '100px',
            className: 'remove-padding'
        },
    ];
    page = 0;
    size = 10;
    total = 0;
    params: AccountParams = {} as AccountParams;
    data: Account[] = [];

    get canEnable(): boolean {
        return this.settingService.hasPermission(['Account Enable']);
    }

    get userInfo(): Auth {
        return this.tokenService.get();
    }

    get needTeamFilter(): boolean {
        const info = this.tokenService.get();
        return info.features?.['Account View']?.includes('All');
    }

    constructor(
        private modal: NzModalService,
        private router: Router,
        private accountService: AccountService,
        private message: NzMessageService,
        private settingService: SettingsService,
        private tokenService: TokenService,
        private roleService: RoleService,
        private teamService: TeamService,
        private injector: Injector
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.setScroll();
        await this.fetchOptions();
        this.columns.forEach(item => {
            if (item.index === 'roleNames') {
                item.listOfFilter = this.roleList.map(option => this.formatOption(option));
            }
        });
        this.accountColumns = [...this.columns];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.teamId) {
            this.params.teamIdIn = this.teamId ? [this.teamId] : undefined;
            this.handleRefresh();
        }
    }

    initData(size?: number): void {
        this.params = {};
        this.size = size || 10;
        this.fetchData();
    }

    @HostListener('window:resize')
    _resize(): void {
        this.setScroll();
    }

    setScroll(): void {
        this.settingService.setLayout();
        const { y } = this.settingService.layout;
        this.nzScroll = {
            x: '1120px',
            y: `${y}px`,
        };
    }

    async fetchData(): Promise<void> {
        try {
            if (this.page !== 0 && Math.ceil(this.total / this.size) <= this.page) {
                return;
            }
            if (this.loading) {
                return;
            }
            this.loading = true;
            const { teamIdIn } = this.params;
            const query: AccountParams = {
                ...this.params,
                teamIdIn: teamIdIn && teamIdIn.length > 0 ? teamIdIn : (teamIdIn ? [teamIdIn] : undefined) as any,
                page: this.page,
                size: this.size,
                keyword: this.searchKeyword,
            };
            if (!query.roleId) {
                delete query.roleId;
            }
            if (!query.teamIdIn) {
                delete query.teamIdIn;
            }
            if (!(query.enable === true || query.enable === false)) {
                delete query.enable;
            }
            const { content, totalElements } = await this.accountService.find(query);
            this.data = content;
            this.total = totalElements;
        } finally {
            this.loading = false;
        }
    }

    formatOption(option: SelectOption): {
        text: string;
        value: number | string
    } {
        return {
            text: option.label,
            value: option.value,
        };
    }

    async fetchOptions(): Promise<void> {
        if (this.needTeamFilter) {
            const [roles] = await Promise.all([
                this.roleService.findOptions(),
            ]);
            this.roleList = roles;
        } else {
            this.roleList = await this.roleService.findOptions();
        }
    }

    handleEdit(row: Account): void {
        this.router.navigate(['/accounts/detail', row.id]);
    }

    handleResetPassword(row: Account): void {
        this.modal.confirm({
            nzTitle: 'This operation will reset the password!',
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: {top: '40vh'},
            nzOnOk: async () => {
                await this.accountService.resetPassword(row.id).then(() => {
                    this.modal.success({
                        nzTitle: 'Reset successfully',
                        nzContent: 'The new password is: password'
                    });
                    if (this.userInfo.id === row.id) {
                        this.modal.error({
                            nzTitle: `Your password has been changed. Please login.`,
                            nzOnOk: () => this.injector.get(Router).navigateByUrl('/login')
                        });
                    }
                });
            },
        });
    }

    changeStatus(row: Account, enable: boolean): void {
        if (!this.canEnable || row.id === this.userInfo.id) {
            return;
        }
        const title = `This operation will ${enable ? 'enable' : 'disable'} the account!`;
        this.modal.confirm({
            nzTitle: title,
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: {top: '40vh'},
            nzOnOk: async () => {
                console.log('OK');
                const data = {
                    id: row.id,
                    enable,
                };
                await this.accountService.enable(data);
                row.enable = enable;
                this.message.success('Status updated successfully');
            },
        });
    }

    handleRefresh(): void {
        this.page = 0;
        this.fetchData();
    }

    handleChange(event: STChange): void {
        const { type, pi, sort, ps, params } = event;
        if (type === 'filter') {
            this.params = {
                ...this.params,
                ...params,
            };
            this.handleRefresh();
        }
        if (type === 'sort') {
            this.params = {
                ...this.params,
                sort,
            };
            this.handleRefresh();
        }
        if (type === 'pi') {
            this.page = pi - 1;
            this.fetchData();
        }
        if (type === 'ps') {
            this.size = ps;
            this.fetchData();
        }
    }
}
