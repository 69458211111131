import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-calendar-legend',
    templateUrl: './calendar-legend.component.html',
    styleUrls: ['./calendar-legend.component.less'],
})
export class CalendarLegendComponent implements OnInit {

    legends: {
        color?: string,
        label: string,
        isHalf?: boolean
    }[] = [
        {
            color: '#978989',
            label: 'Reserved by other user'
        },
        {
            color: '#f15a5a',
            label: 'Pending approval'
        },
        {
            color: '#427cb6',
            label: 'My reservation'
        },
        {
            isHalf: true,
            label: 'Partial day reservation'
        }
    ];
    constructor() {
    }

    ngOnInit(): void {
    }

}
