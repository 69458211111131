<div class="container">
    <div class="wrap">
        <div class="login">
            <div class="logo">
                <img src="./assets/img/logo.png" alt="" />
            </div>
            <div class="desc">SIGN IN TO CONTINUE</div>

            <form nz-form [formGroup]="form" (ngSubmit)="submit()" role="form" [nzAutoTips]="autoTips">
                <nz-alert *ngIf="error" [nzType]="'error'" [nzMessage]="error" [nzShowIcon]="true" class="mb-lg"></nz-alert>
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzSize="large" nzSuffixIcon="mail">
                            <input nz-input formControlName="userName" placeholder="Enter Username" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-input-group nzSize="large" nzSuffixIcon="lock">
                            <input nz-input type="password" formControlName="password" placeholder="Password" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item class="mb-remember">
                    <nz-col [nzSpan]="12">
                        <label nz-checkbox formControlName="remember">Remember Me</label>
                    </nz-col>
                </nz-form-item>
                <nz-form-item>
                    <button nz-button type="submit" nzType="primary" nzSize="large" [nzLoading]="loading" nzBlock>Login</button>
                </nz-form-item>
            </form>
        </div>
        <div class="footer-container">
            Version {{ versionInfo.version }}
<!--            Build {{ versionInfo.commit }} <br /><em class="anticon anticon-copyright"></em> ©{{ currentYear }}-Acentury. All rights reserved.-->
        </div>
    </div>
</div>
