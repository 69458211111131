import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VERSION as VERSION_ZORRO } from 'ng-zorro-antd/version';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    template: ` <router-outlet></router-outlet> `,
})
export class AppComponent implements OnInit {
    constructor(el: ElementRef, renderer: Renderer2, private router: Router, private modalSrv: NzModalService) {
        renderer.setAttribute(el.nativeElement, 'ng-zorro-version', VERSION_ZORRO.full);
    }

    ngOnInit(): void {
        this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe(() => {
            this.modalSrv.closeAll();
        });
    }
}
