import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'selectShow',
})
export class SelectShowPipe implements PipeTransform {
    transform(nzLabel: string, showNum: number = 4, total: number = 20): string {
        let str1 = '';
        let str2 = '';
        if (!nzLabel || nzLabel.length <= total) {
            return nzLabel;
        }
        str1 = nzLabel.substr(0, showNum);
        str2 = nzLabel.substr(nzLabel.length - showNum, nzLabel.length);
        return str1 + '...' + str2;
    }
}
