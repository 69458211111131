import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormField } from '@app/interfaces/rm/radio';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-custom-field-form',
    templateUrl: './custom-field-form.component.html',
    styleUrls: ['./custom-field-form.component.less'],
})
export class CustomFieldFormComponent implements OnInit, OnChanges {
    @Input() formSpan = 24;
    @Input() loading = false;
    @Input() formFields: FormField[] = [];
    @Input() data?: {
        [key: string]: string|number|boolean|null;
    };
    @Input() labelSpan = 5;
    @Input() valueSpan = 24 - this.labelSpan;
    @Output() changeCheck = new EventEmitter();
    @Output() changeValue = new EventEmitter();

    validateForm!: FormGroup;
    selectFieldType = ['select', 'autocomplete'];

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formFields) {
            this.initForm();
        } else if (this.data && this.validateForm) {
            this.validateForm.reset(this.data);
        }
    }

    initForm(): void  {
        this.validateForm = this.fb.group({});
        this.formFields.forEach(async field => {
            const fieldValidateRule = [];
            if (field.required) {
                fieldValidateRule.push(Validators.required);
            }
            if (field.validators) {
                fieldValidateRule.push(...field?.validators);
            }
            // if (field.maxLength) {
            //     fieldValidateRule.push(Validators.maxLength(field.maxLength));
            // }
            // if (field.max) {
            //     fieldValidateRule.push(Validators.max(field.max));
            // }
            this.validateForm.addControl(field.key, new FormControl(field?.initialValue, fieldValidateRule));
            if (field.remoteOptions && field.cascadeByKey) {
                field.options =  await field.remoteOptions(this.validateForm.value);
            }
        });
        if (this.data) {
            this.validateForm.reset(this.data);
        }
    }

    getErrTipByField(fieldItem: FormField): string {
        if (!this.validateForm) {
            return '';
        }
        const fieldName = fieldItem.key;
        const type = fieldItem?.type || '';
        const errors = this.validateForm.controls[fieldName].errors;
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                return !fieldItem?.errorMessage && key === 'required' ?
                    `${this.selectFieldType.includes(type) ? 'Select' : 'Input'} is required` : `${fieldItem?.errorMessage?.[key] || ''}`;
            }
        }
        return '';
    }

    getFieldTip(fieldItem: FormField): string {
        const type = fieldItem?.type || '';
        return 'Please ' + (this.selectFieldType.includes(type) ? 'select' : 'input') + ' ' + fieldItem.label;
    }

    changeCheckBox(checked: boolean): void {
        this.changeCheck.emit(checked);
    }

    validForm(): boolean {
        for (const control of Object.values(this.validateForm.controls)) {
            control.markAsDirty();
            control.updateValueAndValidity();
        }
        if (!this.validateForm.valid) {
            console.log('not valid');
            return false;
        }
        return true;
    }

    async changeFormValue(fieldItem: FormField): Promise<void> {
        const changeFieldKey = fieldItem.key;
        for (const item of this.formFields) {
            if (item.cascadeByKey === changeFieldKey && item.remoteOptions) {
                item.options = await item.remoteOptions(this.validateForm.value);
                this.validateForm.value[item.key] = null;
                this.validateForm.get(item.key)?.setValue(null);
            }
        }
        this.changeValue.emit(fieldItem.key);
    }

    setValue(controlName: string, value: string | null): void {
        this.validateForm.get(controlName)?.setValue(value);
    }

    resetFormValue(): void {
        this.validateForm.reset(this.data);
    }
}
