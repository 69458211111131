import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PermissionDirective } from '@app/directive/permission.directive';
import { FormMultipleInputComponent } from '@app/shared/components/form-multiple-input/form-multiple-input.component';
import { RemoteSelectComponent } from '@app/shared/components/remote-select/remote-select.component';
import { SearchKeywordComponent } from '@app/shared/components/search-keyword/search-keyword.component';
import { CalendarFilterComponent } from '@app/shared/components/table-calendar/component/calendar-filter/calendar-filter.component';
import { CalendarLegendComponent } from '@app/shared/components/table-calendar/component/calendar-legend/calendar-legend.component';
import { EditReservationComponent } from '@app/shared/components/table-calendar/component/edit-reservation/edit-reservation.component';
import { ReservationInfosComponent } from '@app/shared/components/table-calendar/component/reservation-infos/reservation-infos.component';
import { ReserveDescComponent } from '@app/shared/components/table-calendar/component/reserve-desc/reserve-desc.component';
import { ReserveUserSelectComponent } from './components/table-calendar/component/reserve-user-select/reserve-user-select.component';
import { TableCalendarComponent } from '@app/shared/components/table-calendar/table-calendar.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTreeModule } from 'ng-zorro-antd/tree';

import { AvatarModule } from 'ngx-avatar';
import { SHARED_ZORRO_MODULES } from './shared-zorro.module';

import { CustomFieldFormComponent } from '@app/shared/components/custom-field-form/custom-field-form.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { HighLightPipe } from '../pipe/high-light.pipe';
import { SelectShowPipe } from '../pipe/select-show.pipe';
import { AccountListComponent } from './components/account-list/account-list.component';
import { ApplicationComponent } from './components/application/application.component';
import { ApplicationDetailComponent } from './components/application/components/application-detail/application-detail.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CardComponent } from './components/card/card.component';
import { CustomConfirmModalComponent } from './components/custom-confirm-modal/custom-confirm-modal.component';
import { EngineerItemComponent } from './components/engineer-item/engineer-item.component';
import { MatrixComponent } from './components/matrix/matrix.component';
import { MenuComponent } from './components/menu/menu.component';
import { NotificationDetailComponent } from './components/notification-list/components/notification-detail/notification-detail.component';
import { NotificationItemComponent } from './components/notification-list/components/notification-item/notification-item.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { OverflowTextComponent } from './components/overflow-text/overflow-text.component';
import { OverflowTooltipComponent } from './components/overflow-tooltip/overflow-tooltip.component';
import { ProgressComponent } from './components/progress/progress.component';
import { StComponent } from './components/st/st.component';
import { StatusTagComponent } from './components/status-tag/status-tag.component';
import { TabButtonComponent } from './components/tab-button/tab-button.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TagComponent } from './components/tag/tag.component';
import { AddTaskComponent } from './components/task-list/components/add-task/add-task.component';
import { EditTaskComponent } from './components/task-list/components/edit-task/edit-task.component';
import { FileListComponent } from './components/task-list/components/file-list/file-list.component';
import { TaskDetailComponent } from './components/task-list/components/task-detail/task-detail.component';
import { TaskFormComponent } from './components/task-list/components/task-form/task-form.component';
import { TaskProjectDetailComponent } from './components/task-list/components/task-project-detail/task-project-detail.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { UploadComponent } from './components/upload/upload.component';
import { AutoFormInputComponent } from '@app/shared/components/auto-form-input/auto-form-input.component';
import { FormInputComponent } from '@app/shared/components/form-input/form-input.component';
import { ScrollCardTabComponent } from '@app/shared/components/scroll-card-tab/scroll-card-tab.component';

const CustomComponents = [
    CardComponent,
    ApplicationComponent,
    TabsComponent,
    OverflowTooltipComponent,
    ProgressComponent,
    EngineerItemComponent,
    NotificationItemComponent,
    NotificationListComponent,
    NotificationDetailComponent,
    AvatarComponent,
    MenuComponent,
    UploadComponent,
    TabButtonComponent,
    TaskListComponent,
    MatrixComponent,
    AccountListComponent,
    ApplicationDetailComponent,
    AddTaskComponent,
    EditTaskComponent,
    TaskDetailComponent,
    TaskFormComponent,
    TaskProjectDetailComponent,
    FileListComponent,
    TagComponent,
    StComponent,
    SelectShowPipe,
    UploadFileComponent,
    HighLightPipe,
    RemoteSelectComponent,
    StatusTagComponent,
    CustomFieldFormComponent,
    TableCalendarComponent,
    CalendarLegendComponent,
    CalendarFilterComponent,
    EditReservationComponent,
    ReservationInfosComponent,
    ReserveDescComponent,
    ReserveUserSelectComponent,
    SearchKeywordComponent,
    FormMultipleInputComponent,
    AutoFormInputComponent,
    FormInputComponent,
    ScrollCardTabComponent
];

const directives = [PermissionDirective];
// #region third libs

const THIRDMODULES: Type<any>[] = [];

// #endregion

// #region your componets & directives

const COMPONENTS: Type<any>[] = [];
const DIRECTIVES: Type<any>[] = [];

// #endregion

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ...SHARED_ZORRO_MODULES,
        ...THIRDMODULES,
        AvatarModule,
        NzPaginationModule,
        NzCollapseModule,
        NzAutocompleteModule,
        NzTreeModule,
        NzResizableModule
    ],
    declarations: [...COMPONENTS, ...DIRECTIVES, ...CustomComponents, ...directives, OverflowTextComponent, CustomConfirmModalComponent],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ...SHARED_ZORRO_MODULES,
        ...THIRDMODULES,
        ...COMPONENTS,
        ...DIRECTIVES,
        ...CustomComponents,
        ...directives,
        OverflowTextComponent,
        CustomConfirmModalComponent,
        NzPaginationModule,
        NzResizableModule
    ],
})
export class SharedModule {}
