import { Component, Input, OnInit } from '@angular/core';
import { SelectOption } from '@app/interfaces/sys/dict';
import { AccountService } from '@services/sys/account.service';

@Component({
    selector: 'app-reserve-user-select',
    templateUrl: './reserve-user-select.component.html',
    styleUrls: ['./reserve-user-select.component.less'],
})
export class ReserveUserSelectComponent implements OnInit {
    users: SelectOption[] = [];
    chosenUserId = undefined;
    hasRequestApprovalPermission: boolean = false;
    calendarEditPermissionAll: boolean = false;

    constructor(private accountService: AccountService) {
    }

    async ngOnInit(): Promise<void> {
        if (this.hasRequestApprovalPermission && this.calendarEditPermissionAll) {
            this.users = (await this.accountService.findList());
        }
    }

}
