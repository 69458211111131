import { Injectable } from '@angular/core';
import { basicRoleFeatures, features } from '@app/core/startup/permission';
import { Auth } from '@app/interfaces/sys/auth';
import { Feature } from '@app/interfaces/sys/permission';
import { environment } from '@env/environment';
import { AuthHttp } from '@services/sys/auth.service';
import { PermissionService } from '@services/sys/permission.service';
import { LicenceService } from './licence.service';
import { FeatureNew } from '@app/interfaces/sys/licence';


@Injectable({
    providedIn: 'root',
})
export class TokenService {
    login_url = '/login';

    constructor(private authService: AuthHttp, private licenceService: LicenceService, private permissionService: PermissionService) {
    }

    set(data: Auth): void {
        localStorage.setItem('tokenData', JSON.stringify(data));
    }

    get(): Auth {
        const info = localStorage.getItem('tokenData');
        return info ? JSON.parse(info) : null;
    }

    getToken(): string {
        const info = localStorage.getItem('tokenData');
        const userInfo: Auth = info ? JSON.parse(info) : null;
        return userInfo?.token;
    }

    clear(): void {
        localStorage.removeItem('tokenData');
    }

    async refresh(): Promise<void> {
        const data: Auth = await this.authService.current();
        const tokenData = this.get();

        if(!this.isAdmin(tokenData.roleNames || [])) {
            const res: FeatureNew[] = await this.licenceService.getLicencedFeatures();
            localStorage.setItem('licences', JSON.stringify(res));

            const expired: FeatureNew[] = this.licenceService.getExpiredFeatures(res);

            const expiredApiList = this.getExpiredApiList(expired);
            // API lists including Expired features. but not included features that are not in the licence feature list.
            const allApiList = this.getApiList(res);

            if(expiredApiList) {
                data.expiredFeatures = expiredApiList;
            }

            // GET ACTIVE FEATURES AND DELETE NOT ACTIVE
            Object.keys(data.features).forEach((key: string) =>{
              if(!allApiList.has(key)) {
                delete data.features[key];
              }
            })
        }
        this.set(data);
    }

    getExpiredApiList(res: FeatureNew[]) {
        const keyList = (this.licenceService.getListFeatureKey(res)).allKeys;
        const apiList = this.licenceService.getExpiredFeatureEndpoints(keyList);
        return apiList;
    }

    getApiList(allLicenseList: FeatureNew[]): Set<string> {
        const keyList = (this.licenceService.getListFeatureKey(allLicenseList)).allKeys;
        const endpoints = this.licenceService.getFeatureEndpoints(keyList);
        return endpoints;
    }

    isAdmin(roleNames: string[]): boolean {
        const arr = roleNames?.map(item => item?.toLowerCase());
        return arr?.includes('admin');
    }

    getUserPermissions(): string[] {
        const tokenData: Auth = this.get();
        const userPermissions = tokenData && tokenData.features ? tokenData.features : {};
        let allPermissions = {};
        const adminRole = 'Admin';
        if (this.isAdmin(tokenData.roleNames || [])) {
            allPermissions = basicRoleFeatures[adminRole?.toLowerCase()]?.features ?? {};
        } else {
            allPermissions = {
                ...userPermissions,
            };
        }
        return allPermissions ? Object.keys(allPermissions) : [];
    }

    // back features need filter expired licence part
    getFilterPermissions(userPermissions: {
        [key: string]: string[];
    }): {
        [key: string]: string[];
    } {
        const expiredParts = this.permissionService.getExpiredPermissions();
        let filterFeatures: {
            [key: string]: string[];
        } = {};
        Object.keys(userPermissions).forEach(key => {
            const value = userPermissions[key];
            if (expiredParts.includes(key)) {
                return;
            }
            filterFeatures = {
                ...filterFeatures,
                [key]: value
            };
        });
        return filterFeatures;
    }

    getExpiredPermissions(): string[] {
        const tokenData: Auth = this.get();
        const expiredPermissions = tokenData.expiredFeatures ?? {};
        return this.isAdmin(tokenData.roleNames || []) ? [] : Object.keys(expiredPermissions);
    }

    getAllFeatures(): Feature[] {
        const enablePages = environment.enablePages;
        return features.filter(item => !item.key || enablePages.includes(item.key));
    }

    IsSelfPermission(permission: string): boolean {
        const info = this.get();
        const scopes = info.features?.[permission];
        const isSelf = scopes?.length !== 0 && scopes?.includes('Self') && !scopes?.includes('All');
        return isSelf;
    }
}
