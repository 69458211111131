<div class="card-container" [class]="showShadow ? 'card-shadow' : ''">
    <h4 *ngIf="cardTitle" class="header">
        {{ cardTitle }}
    </h4>

    <div class="content">
        <ng-content></ng-content>
    </div>

    <div *ngIf="showMore" class="more-button" (click)="handleMore()">
        VIEW MORE
        <em nz-icon nzType="right" nzTheme="outline"></em>
    </div>
</div>
