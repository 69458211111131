import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectOption } from '@app/interfaces/sys/dict';
import { Feature } from '@app/interfaces/sys/permission';
import { AddRole, EditPermission, Role, RoleParams, RoleWithPage } from '@app/interfaces/sys/role';

@Injectable({
    providedIn: 'root',
})
export class RoleService {
    private baseUrl = '/roles';

    constructor(private http: HttpClient) {
    }

    async find(params?: RoleParams): Promise<RoleWithPage> {
        return this.http
            .get<RoleWithPage>(this.baseUrl, {
                params: params ? Object.assign(params) : null,
            })
            .toPromise();
    }

    async findOptions(): Promise<SelectOption[]> {
        return this.http.get<SelectOption[]>(`${this.baseUrl}/all-options`).toPromise();
    }

    async detail(id: number): Promise<Role> {
        return this.http
            .get<Role>(`${this.baseUrl}/one`, {
                params: Object.assign({ id }),
            })
            .toPromise();
    }

    async add(data: AddRole): Promise<void> {
        return this.http.post<void>(this.baseUrl, data).toPromise();
    }

    async edit(data: Role): Promise<void> {
        return this.http.put<void>(this.baseUrl, data).toPromise();
    }

    async editPermission(data: EditPermission): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}/features`, data).toPromise();
    }

    async syncFeature(data: { features: Feature[] }): Promise<void> {
        return this.http.put<void>('/feature-apis', data).toPromise();
    }

    async remove(id: number): Promise<void> {
        return this.http
            .delete<void>(`${this.baseUrl}`, {
                params: Object.assign({ id }),
            })
            .toPromise();
    }
}
