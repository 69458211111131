import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AddProjectData,
    AllProjectParams,
    EditProjectData,
    Project,
    ProjectParams,
    ProjectWithPage,
    UpdateStatusData,
} from '@app/interfaces/lm/project';
import { AddTaskFile, TaskFileParams, TaskFileWithPage } from '@app/interfaces/lm/task';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    private baseUrl = '/projects';

    constructor(private http: HttpClient) {
    }

    async find(params: ProjectParams): Promise<ProjectWithPage> {
        return this.http
            .get<ProjectWithPage>(`${this.baseUrl}`, {
                params: Object.assign(params),
            })
            .toPromise();
    }

    async findALl(params: AllProjectParams): Promise<Project[]> {
        return this.http
            .get<Project[]>(`${this.baseUrl}/all`, {
                params: Object.assign(params),
            })
            .toPromise();
    }

    async detail(id: number): Promise<Project> {
        return this.http
            .get<Project>(`${this.baseUrl}/one`, {
                params: Object.assign({ id }),
            })
            .toPromise();
    }

    async add(data: AddProjectData): Promise<void> {
        return this.http.post<void>(`${this.baseUrl}`, data).toPromise();
    }

    async edit(data: EditProjectData): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}`, data).toPromise();
    }

    async remove(id: number): Promise<void> {
        return this.http.delete<void>(this.baseUrl, {
            params: Object.assign({ id }),
        }).toPromise();
    }

    async status(data: UpdateStatusData): Promise<void> {
        return this.http.put<void>(`${this.baseUrl}/status`, data).toPromise();
    }

    async findFile(params: TaskFileParams): Promise<TaskFileWithPage> {
        return this.http
            .get<TaskFileWithPage>(`/project-task-files`, {
                params: Object.assign(params),
            })
            .toPromise();
    }

    async addFile(data: AddTaskFile): Promise<void> {
        return this.http.post<void>('/project-task-files', data).toPromise();
    }
}
