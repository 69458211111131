<div *ngIf="taskFileFilter && taskFileFilter.projectTaskId" class="header-container">
    <div class="left">Files</div>
    <div class="right" *appPermission="['Upload Task File']">
        <app-upload
            *ngIf="canUpload"
            [path]="uploadPath"
            (uploadSuccess)="uploadSuccess($event)"
        ></app-upload>
    </div>
</div>

<app-st
    #st
    [loading]="loading"
    [columns]="columns"
    [data]="data"
    [page]="{ show: true }"
    [ps]="size"
    [pi]="page + 1"
    [total]="total"
    [templates]="{ action: action, type: type }"
    (changes)="handleChange($event)"
    [scroll]="nzScroll"
>
    <ng-template #type let-row>
        <div class="file-type">
            <em nz-icon [nzType]="getFileType(row.type)"></em>
        </div>
    </ng-template>
    <ng-template #action let-row>
        <button *appPermission="['Project File Download']" nz-button nzSize="small" nzType="default" (click)="handleDownload(row)">
            <em nz-icon nzType="download" nzTheme="outline"></em>
        </button>
    </ng-template>
</app-st>
