import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from '@app/interfaces/lm/system';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.less'],
})
export class MenuComponent {
    @Input() menuTitle: string | undefined;
    @Input() menus: Tab[] | undefined;
    @Input() selectIndex = 0;

    @Output() selectIndexChange = new EventEmitter();

    handleChange(index: number): void {
        this.selectIndex = index;
        this.selectIndexChange.emit(this.selectIndex);
    }
}
