import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarVOList } from '@app/interfaces/rm/resource-reservation';
import { Router } from '@angular/router';
import { SettingsService } from '@services/sys/settings.service';
import { ResourceReservationService } from '@services/rm/resource-reservation.service';
import { Request } from '@app/interfaces/rm/resource-reservation';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TokenService } from '@services/sys/token.service';

@Component({
    selector: 'app-reservation-infos',
    templateUrl: './reservation-infos.component.html',
    styleUrls: ['./reservation-infos.component.less'],
})
export class ReservationInfosComponent implements OnInit {
    @Input() data!: CalendarVOList[];
    @Input() resourceId!: number;
    @Output() submitSuccess = new EventEmitter();
    @Output() lockPage = new EventEmitter();
    @Output() unlockPage = new EventEmitter();
    canLinkTo = false;
    approveButtonLoading = false;
    rejectButtonLoading = false;

    constructor(private router: Router,
                private settingService: SettingsService,
                private resourceReservationService: ResourceReservationService,
                private msgService: NzMessageService,
                private tokenService: TokenService) {
    }

    ngOnInit(): void {
        const hasRequestApprovalPermission = this.settingService.hasPermission(['Approval Edit']) && !this.settingService.hasExpiredPermission(['Approval Edit']);
        const calendarEditPermissionAll =  this.settingService.hasPermission(['Calendar Edit']) && !this.settingService.hasExpiredPermission(['Calendar Edit']) && this.tokenService.get().features['Calendar Edit'].includes('All');
        this.canLinkTo = this.settingService.hasPermission(['Request and Approvals']) && hasRequestApprovalPermission && calendarEditPermissionAll;
    }

    toRequest(row: CalendarVOList): void {
        if (!this.resourceId) {
            return;
        }
        const params = {
            resourceId: this.resourceId,
            type: row.type,
            calendarId: row.id
        };
        this.router.navigate(['/resource-request/pending']);
        localStorage.setItem('resourceRequestParams', JSON.stringify(params));
    }

    async changeRequestStatus(row: CalendarVOList, status: 'APPROVED' | 'REJECTED'): Promise<void> {
        try {
            this.lockPage.emit();
            if (!this.resourceId) {
                this.msgService.error(`${status[0] + status.slice(1).toLowerCase()} failed.`);
                this.unlockPage.emit();
                this.resourceReservationService.publishEvent('calendarRequestStatusChangeFinish');
                return;
            }
            status === "APPROVED" ? this.approveButtonLoading = true : this.rejectButtonLoading = true;
            const params = {
                page: 0,
                size: 10,
                status: "PENDING",
                resourceId: this.resourceId.toString(),
                type: row.type,
                calendarId: row.id
            };

            const requests: Request[] = (await this.resourceReservationService.getRequest(params)).content;
            if (!requests || requests.length === 0) {
                this.msgService.error(`${status[0] + status.slice(1).toLowerCase()} failed.`);
                this.unlockPage.emit();
                this.resourceReservationService.publishEvent('calendarRequestStatusChangeFinish');
                return;
            }
            const requestId: number = requests[0].id;
            await this.resourceReservationService.auditRequest({ id: requestId, status});
            this.msgService.success(`${status[0] + status.slice(1).toLowerCase()}.`);
        } catch {
            this.msgService.error(`${status[0] + status.slice(1).toLowerCase()} failed.`);
        } finally {
            this.approveButtonLoading = false;
            this.rejectButtonLoading = false;
            this.unlockPage.emit();
            this.submitSuccess.emit();
            this.resourceReservationService.publishEvent('calendarRequestStatusChangeFinish');
        }
    }

}
