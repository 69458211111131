import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Task } from '@app/interfaces/lm/task';
import { TaskService } from '@services/lm/task.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TaskFormComponent } from '../task-form/task-form.component';

@Component({
    selector: 'app-edit-task',
    templateUrl: './edit-task.component.html',
})
export class EditTaskComponent {
    @ViewChild('taskForm') taskForm: TaskFormComponent | undefined;
    @Output() success = new EventEmitter();

    isVisible = false;

    submitLoading = false;
    editData: Task | undefined;

    constructor(private taskService: TaskService, private message: NzMessageService) {}

    open(row: Task): void {
        this.isVisible = true;
        this.editData = {
            ...row,
        };
    }

    close(): void {
        this.taskForm?.resetForm();
        this.isVisible = false;
    }

    async onSubmit(): Promise<void> {
        try {
            this.submitLoading = true;
            const form = this.taskForm?.validForm();
            if (form) {
                const data = {
                    ...this.editData,
                    ...form,
                    status: 'CREATED'
                };
                await this.taskService.edit(data);
                this.message.success('Edited successfully');
                this.close();
                this.success.emit();
            }
        } finally {
            this.submitLoading = false;
        }
    }
}
