import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from '@app/interfaces/lm/system';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.less'],
})
export class TabsComponent {
    @Input() currentIndex: number | undefined;
    @Input() tabs: Tab[] | undefined;

    @Output() change = new EventEmitter();
    @Output() currentIndexChange = new EventEmitter();

    changeTab(item: Tab, i: number): void {
        this.change.emit(item);
        this.currentIndexChange.emit(i);
    }
}
