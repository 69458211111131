<app-st
    #st
    [loading]="loading"
    [columns]="accountColumns"
    [data]="data"
    [page]="{ show: true }"
    [ps]="size"
    [pi]="page + 1"
    [total]="total"
    [scroll]="nzScroll"
    [keyword]="searchKeyword"
    [templates]="{ avatar: avatar, action: action, status: status }"
    (changes)="handleChange($event)"
    [resizeKey]="resizeKey"
    [savePageSize]="true"
    (initData)="initData($event)"
>
    <ng-template #avatar let-row>
        <app-avatar [size]="20" [info]="row"></app-avatar>
    </ng-template>

    <ng-template #status let-row>
        <nz-switch
            [ngModel]="row.enable"
            [nzControl]="true"
            [nzDisabled]="!canEnable || userInfo.id === row.id"
            (click)="changeStatus(row, !row.enable)"
        ></nz-switch>
    </ng-template>
    <ng-template #action let-row>
        <button *appPermission="['Account Update']" nzSize="small" nz-button nz-tooltip="Edit" (click)="handleEdit(row)"
                [disabled]="!row.enable">
            <em nz-icon nzType="edit" nzTheme="outline"></em>
        </button>

        <button *appPermission="['Account Reset Password']" nzSize="small" nz-button nz-tooltip="Reset Password"
                (click)="handleResetPassword(row)">
            <em nz-icon nzType="svg:reset"></em>
        </button>
    </ng-template>
</app-st>
