import {
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit, Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

interface Tab {
    key: string;
    tab: string;
    permissions?: string[];
}
@Component({
    selector: 'app-scroll-card-tab',
    templateUrl: './scroll-card-tab.component.html',
    styleUrls: ['./scroll-card-tab.component.less'],
})
export class ScrollCardTabComponent implements OnInit, OnChanges {
    @ViewChild('tab') tabEl!: ElementRef;
    @ViewChild('tabRow') tabRow!: ElementRef;
    @Input() tabs: Tab[] = [];
    @Input() selectTabIndex!: number;
    @Output() selectedIndexChange = new EventEmitter();
    showArrow = false;
    constructor(private renderer: Renderer2) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectTabIndex) {
            this.selectTabIndex = changes.selectTabIndex.currentValue || 0;
            this.scrollToCurrentTab();
        }
    }

    @HostListener('window:resize')
    _resize(): void {
        this.scrollToCurrentTab();
    }


    selectTab(tabIndex: number): void {
        this.selectTabIndex = tabIndex;
        this.selectedIndexChange.emit(this.selectTabIndex);
    }

    scrollLeft(): void {
        if (this.selectTabIndex > 0) {
            this.selectTabIndex--;
            this.selectedIndexChange.emit(this.selectTabIndex);
        }
    }

    scrollRight(): void {
        if (this.selectTabIndex < this.tabs.length - 1) {
            this.selectTabIndex++;
            this.selectedIndexChange.emit(this.selectTabIndex);
        }
    }

    scrollToCurrentTab(): void {
       setTimeout(() => {
           // 53 is two arrow width
           const tabsMaxWidth = this.tabRow?.nativeElement?.clientWidth - 52;
           const tabs = this.tabEl?.nativeElement;
           const tabsWidth = tabs.clientWidth;
           const currentTab = tabs.querySelector('.active');
           this.showArrow = tabsWidth > tabsMaxWidth;
           if (currentTab) {
               const currentTabWidth = currentTab.clientWidth;
               const offsetLeft = currentTab.offsetLeft;
               const containerScrollLeft = tabs.scrollLeft;
               const isFullyVisible = offsetLeft >= containerScrollLeft && (offsetLeft + currentTabWidth) <= (containerScrollLeft + tabsWidth);
               if (!isFullyVisible) {
                   const newScrollLeft = offsetLeft - (tabsWidth - currentTabWidth) / 2;
                   this.renderer.setProperty(tabs, 'scrollLeft', newScrollLeft);
               }
           }
       }, 200);
    }

}
