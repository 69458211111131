import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Notification } from '@app/interfaces/lm/notification';
import { NotificationService } from '@services/lm/notification.service';
import { NotificationDetailComponent } from './components/notification-detail/notification-detail.component';

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
})
export class NotificationListComponent implements OnChanges {
    @Input() data: Notification[] = [];
    @Input() showUserName = false;
    @Input() showProjectName = true;
    @Input() showDetail = true;
    @Input() canResponse = true;

    @Output() responseSuccess = new EventEmitter();

    @ViewChild('notificationDetail') notificationDetail: NotificationDetailComponent | undefined;

    constructor(private notificationService: NotificationService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.setReadStatus();
        }
    }

    handleDetail(item: Notification): void {
        console.log('handleDetail', item);
        this.notificationDetail?.open(item);
    }

    async setReadStatus(): Promise<void> {
        const unReadList = this.data.filter(item => !item.readed);
        console.log('setReadStatus', unReadList);
        if (unReadList.length === 0) {
            return;
        }
        const unReadIds = unReadList.map(item => item.id);
        await this.notificationService.batchRead({
            ids: unReadIds
        });
    }

    onResponseSuccess(): void {
        this.responseSuccess.emit();
    }
}
