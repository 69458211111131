import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from '@app/interfaces/lm/system';

@Component({
    selector: 'app-tab-button',
    templateUrl: './tab-button.component.html',
    styleUrls: ['./tab-button.component.less'],
})
export class TabButtonComponent {
    @Input() currentIndex: number | undefined;
    @Input() tabs: Tab[] | undefined;

    @Output() change = new EventEmitter();

    changeTab(item: Tab): void {
        this.change.emit(item);
    }
}
