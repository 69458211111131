<nz-modal
    [(nzVisible)]="isVisible"
    [nzWidth]="750"
    [nzOkText]="null"
    [nzCancelText]="null"
    [nzTitle]="modalTitle"
    [nzMaskClosable]="false"
    (nzOnCancel)="close()"
>
    <ng-container *nzModalContent>
        <div *ngIf="taskDetail">
            <div *ngIf="taskDetail" class="info">
                <div class="info-item">
                    <div class="item-label">Start Date</div>
                    <div class="item-value">
                        {{ taskDetail.planStartDate | date: 'yyyy-MM-dd' }}
                    </div>
                </div>

                <div class="info-item">
                    <div class="item-label">Due Date</div>
                    <div class="item-value">
                        {{ taskDetail.planEndDate | date: 'yyyy-MM-dd' }}
                    </div>
                </div>

                <div *ngIf="taskDetail.type !== 'TEST_ENVIRONMENT_REQUIREMENTS'" class="info-item">
                    <div class="item-label">Owner</div>
                    <div class="item-value">
                        {{ taskDetail.ownerName }}
                    </div>
                </div>

                <ng-container *ngIf="taskDetail.type === 'TEST_CASE_DEVELOPMENT'">
                    <div class="info-item">
                        <div class="item-label">Total Testcase</div>
                        <div class="item-value">
                            {{ taskDetail.totalTestcase }}
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="item-label">Finished Testcase</div>

                        <div *ngIf="!canEditFinishedTestCase; else edit" class="item-value">
                            {{ taskDetail.finishedTestcase || 0 }}
                            <ng-container *appPermission="['Edit Task Testcase']">
                                <button
                                    *ngIf="taskDetail.status === 'WORKING' && projectNotFinish && canEdit"
                                    nz-button
                                    class="item-value-button text-btn-gray"
                                    (click)="startEdit()"
                                >
                                    Edit
                                </button>
                            </ng-container>
                        </div>

                        <ng-template #edit>
                            <div class="item-value edit-case button-group">
                                <input nz-input type="number" size="mini" class="edit-case-input" [(ngModel)]="finishedTestCase" />

                                <button nz-button (click)="confirmEdit()">Update Testcase</button>

                                <button nz-button (click)="cancelEdit()">Cancel</button>
                            </div>
                        </ng-template>
                    </div>
                </ng-container>

                <div class="info-item">
                    <div class="item-label">Description</div>
                    <div class="item-value">
                        {{ taskDetail.description }}
                    </div>
                </div>
            </div>

            <div class="button-group">
                <ng-container *ngIf="canEdit">
                    <ng-container *appPermission="['Start Work']">
                        <button *ngIf="taskDetail.status === 'CREATED'" nz-button class="text-btn-primary" (click)="startWork()">
                            Start Work
                        </button>
                    </ng-container>

                    <ng-container *appPermission="['Finish Task']">
                        <button *ngIf="taskDetail.status === 'WORKING'" nz-button class="text-btn-primary" (click)="finishTask()">
                            Finish
                        </button>
                    </ng-container>

                    <ng-container *appPermission="['Archive Task']">
                        <button
                            *ngIf="taskDetail.status !== 'ARCHIVED' && canArchiveTask"
                            nz-button
                            class="text-btn-primary"
                            (click)="archiveTask()"
                        >
                            Archive
                        </button>
                    </ng-container>
                </ng-container>

                <button nz-button class="text-btn-danger" (click)="close()">Cancel</button>
            </div>

            <div class="file-container">
                <app-file-list
                    [canUpload]="taskDetail.status !== 'ARCHIVED'"
                    [taskFileFilter]="{ projectTaskId: taskDetail.id }"
                    [uploadPath]="'task/' + taskDetail.name"
                ></app-file-list>
            </div>
        </div>

        <ng-container *appPermission="['Preparation Task Detail']">
            <app-task-project-detail
                *ngIf="showProjectDetail && taskDetail && taskDetail.projectId"
                [projectId]="taskDetail.projectId"
            ></app-task-project-detail>
        </ng-container>
    </ng-container>
</nz-modal>
