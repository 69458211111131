import { Component, Input, OnInit } from '@angular/core';
import { Project } from '@app/interfaces/lm/project';
import { ProjectService } from '@services/lm/project.service';

@Component({
    selector: 'app-task-project-detail',
    templateUrl: './task-project-detail.component.html',
    styleUrls: ['./task-project-detail.component.less'],
})
export class TaskProjectDetailComponent implements OnInit {
    @Input() projectId!: number;

    projectDetail: Project = {} as Project;
    active = false;

    constructor(private projectService: ProjectService) {
    }

    ngOnInit(): void {
        this.fetchProjectDetail();
    }

    async fetchProjectDetail(): Promise<void> {
        this.projectDetail = await this.projectService.detail(this.projectId);
        console.log('project detail', this.projectDetail);
    }

}
