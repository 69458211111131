<div class="filter-popover" [class]="isFormDashboard ? 'dashboard-drawer-tree' : ''" [ngStyle]="{ 'max-height': getMaxHeight() }">
    <nz-input-group [nzSuffix]="suffixIcon">
        <input type="text" nz-input placeholder="Search" [(ngModel)]="searchValue" />
    </nz-input-group>
    <ng-template #suffixIcon>
        <span nz-icon nzType="search"></span>
    </ng-template>
    <div class="all-row">
        <label
            style="margin-left: 23px"
            nz-checkbox
            [(ngModel)]="allChecked"
            (ngModelChange)="updateAllChecked($event)"
            [nzIndeterminate]="indeterminate"
        >
            All
        </label>
    </div>
    <nz-tree
        class="tree"
        [nzData]="treeData"
        nzCheckable
        [nzSearchValue]="searchValue"
        nzMultiple
        [nzHideUnMatched]="true"
        (nzClick)="onNodeClick($event)"
        [nzCheckedKeys]="defaultCheckedKeys"
        (nzExpandChange)="onNodeClick($event, true)"
        (nzCheckBoxChange)="onCheckBoxChange($event)"
    ></nz-tree>
    <div class="button-group" *ngIf="type !== 'dashboard'">
        <button class="text-btn-primary search-btn" nz-button (click)="apply()">Apply</button>
    </div>
</div>
