<div class="overflow-select" #overflowText>
    <nz-select
        [nzCustomTemplate]="defaultPort"
        [ngModel]="item.id"
        (ngModelChange)="changeSelect($event, index)"
        style="min-width: 100px; max-width: 100%"
        *ngIf="type === 'select'"
        nzSize="small"
    >
        <nz-option *ngFor="let p of options" [nzValue]="p.id" [nzLabel]="p.name" nzCustomContent [nzDisabled]="p.disabled">
            {{ allowSelectShow && overflowWidth > 0 ? (p.name | selectShow: showNum : showTotal) : p.name }}
        </nz-option>
    </nz-select>
    <ng-template #defaultPort let-selected>
        <div #optionEl>
            {{ allowSelectShow && overflowWidth > 0 ? (selected.nzLabel | selectShow: showNum : showTotal) : selected.nzLabel }}
        </div>
    </ng-template>

    <div class="port-title-tips" #spanBox *ngIf="type !== 'select'">
        <span nz-popover [nzPopoverTitle]="item.name" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="top">
            {{ allowSelectShow && overflowWidth > 0 ? (item.name | selectShow: showNum : showTotal) : item.name }}
        </span>
    </div>
    <ng-template #contentTemplate>
        <div class="tips-temp">
            <p>{{ options[index]?.matrixPortDesc }}</p>
        </div>
    </ng-template>
</div>
