<ng-container>
    <nz-input-group [nzSuffix]="keyword ? inputClearTpl : suffixIconSearch">
        <input type="text" nz-input [(ngModel)]="keyword" (keydown)="enterSearch($event)" (ngModelChange)="changeValue($event)" placeholder="Search..." />
    </nz-input-group>
    <ng-template #suffixIconSearch>
        <em nz-icon nzType="search" (click)="onSearch()"></em>
    </ng-template>
    <ng-template #inputClearTpl>
        <em nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="keyword"
            (click)="clearKeyword()"></em>
    </ng-template>

    <button class="text-btn-primary search-button" nz-button (click)="onSearch()">Search</button>
</ng-container>
