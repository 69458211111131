import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ResourceTree, ResourceType } from '@app/interfaces/rm/resource-reservation';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd/tree';

@Component({
    selector: 'app-calendar-filter',
    templateUrl: './calendar-filter.component.html',
    styleUrls: ['./calendar-filter.component.less'],
})
export class CalendarFilterComponent implements OnInit {
    @Input() nodes: ResourceTree[] = [];
    @Output() changeNodes = new EventEmitter<ResourceTree[]>();
    @Input() type!: string;
    @Input() immediateChange = false;
    treeData: ResourceTree[] = [];
    defaultCheckedKeys: string[] = [];
    allChecked = false;
    indeterminate = false;
    searchValue = '';

    constructor() {
    }

    get isFormDashboard(): boolean {
        return this.type === 'dashboard';
    }

    ngOnInit(): void {
        this.treeData.length = 0;
        this.defaultCheckedKeys = [];
        this.nodes.forEach(item => {
            const { key } = item;
            if (item.checked) {
                this.defaultCheckedKeys.push(key);
            }
            const children = item.children || [];
            const childSelectKeys = children?.filter(x => x.checked).map(xx => xx.key);
            this.defaultCheckedKeys.push(...childSelectKeys);
        });
        this.treeData = JSON.parse(JSON.stringify(this.nodes));
        this.getAllStatus();
    }

    getAllStatus(): void {
        const allChecked = this.treeData.every(item => item.checked);
        const someChecked = this.treeData.some(item => item.checked);
        this.allChecked = allChecked;
        this.indeterminate = someChecked && !allChecked;
    }

    onNodeClick(event: NzFormatEmitEvent, isExpend: boolean = false): void {
        const node = event.node;
        if (!node) {
            return;
        }
        if (!isExpend) {
            node.isChecked = !node.isChecked;
        }
        this.updateChildNodes(node, isExpend);
        this.updateParentNodes(node, isExpend);
        this.getAllStatus();
    }

    onCheckBoxChange(event: NzFormatEmitEvent): void {
        const node = event.node;
        if (!node) {
            return;
        }
        this.updateChildNodes(node);
        this.updateParentNodes(node);
        this.getAllStatus();
        this.immediateChangeFn();
    }

    updateParentNodes(node: NzTreeNode, isExpend: boolean = false): void {
        const parentNode = node.getParentNode();
        if (node && parentNode) {
            this.updateNodeState(parentNode, isExpend);
        }
    }

    updateChildNodes(node: NzTreeNode, isExpend: boolean = false): void {
        if (node.getChildren()) {
            node.getChildren().forEach(child => {
                if (!isExpend) {
                    child.isChecked = node.isChecked;
                }
                this.updateNodeState(child);
            });
        }
    }

    updateNodeState(node: NzTreeNode, isExpend: boolean = false): void {
        if (!isExpend && node.getChildren().length > 0) {
            const allChecked = node.getChildren().every(child => child.isChecked);
            const someChecked = node.getChildren().some(child => child.isChecked || child.isHalfChecked);

            node.isChecked = allChecked;
            node.isHalfChecked = !allChecked && someChecked;
        }
    }

    apply(): void {
        this.changeNodes.emit(this.treeData);
    }

    updateAllChecked(checked: boolean): void {
        if (checked) {
            this.indeterminate = false;
        }
        this.treeData = this.treeData.map(item => {
            const children = item.children || [];
            return {
                ...item,
                checked,
                isLeaf: children.length === 0,
                children: children.map(x => {
                    return {
                        ...x,
                        checked,
                    };
                })
            };
        });
        this.immediateChangeFn();
    }

    immediateChangeFn(): void {
        if (this.immediateChange) {
            this.changeNodes.emit(this.treeData);
        }
    }

    getMaxHeight(): string {
        if (this.isFormDashboard) {
            return '100%';
        } else if (this.type === 'calendar') {
            return '300px';
        } else {
            // Default value or handle other types as needed
            return 'calc(100% - 30px)';
        }
    }
}
