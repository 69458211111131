import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-custom-confirm-modal',
    templateUrl: './custom-confirm-modal.component.html',
    styleUrls: ['./custom-confirm-modal.component.less'],
})
export class CustomConfirmModalComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() submit = new EventEmitter();
    @Output() ok = new EventEmitter();
    @Input() content = '';
    @Input() cancelText = 'Cancel';
    @Input() okText = 'OK';
    @Input() submitText = 'Submit';
    @Input() showKeepBtn = true;
    visible = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    open(content?: string): void {
        if (content) {
            this.content = content;
        }
        this.visible = true;
    }

    close(): void {
        this.visible = false;
    }

    handleCancel(): void {
        this.cancel.emit();
        this.close();
    }

    handleSubmit(): void {
        this.submit.emit();
        this.close();
    }

    handleOK(): void {
        this.ok.emit();
        this.close();
    }

}
