import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
    CalendarTableRow,
    CalendarVOList,
    OverwriteOneParams,
    TimeSegment,
} from '@app/interfaces/rm/resource-reservation';
import { ResourceReservationService } from '@services/rm/resource-reservation.service';
import { TokenService } from '@services/sys/token.service';
import { AccountService } from '@services/sys/account.service';
import { SettingsService } from '@services/sys/settings.service';
import { differenceInCalendarDays } from 'date-fns';
import dayjs from 'dayjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SelectOption } from '@app/interfaces/sys/dict';

@Component({
    selector: 'app-edit-reservation',
    templateUrl: './edit-reservation.component.html',
    styleUrls: ['./edit-reservation.component.less'],
})
export class EditReservationComponent implements OnInit {
    @Output() submitSuccess = new EventEmitter();
    isVisible = false;
    submitLoading = false;
    loading = false;
    title = 'Reserve By Date';
    timeSegmentArr: TimeSegment[] = [];
    editData: any[] = [];
    resourceId!: string;
    type!: string;
    currentUserId!: number;
    otherRequestReservation: CalendarVOList[] = [];
    editAllCalendarList: CalendarVOList[] = [];
    list: Array<TimeSegment[]> = [];
    pendingKey = 'PENDING';
    approvedKey = 'APPROVED';
    isApproval = false;
    hasRequestApprovalPermission: boolean = false;
    calendarEditPermissionAll: boolean = false;
    users: SelectOption[] = [];
    startTimeBoundary: number = 0;
    endTimeBoundary: number = dayjs().add(1, 'year').valueOf();


    constructor(private fb: FormBuilder,
                private tokenService: TokenService,
                private resourceReservationService: ResourceReservationService,
                private message: NzMessageService,
                private accountService: AccountService,
                private settingService: SettingsService
            ) {
    }

    async ngOnInit(): Promise<void> {
        this.hasRequestApprovalPermission = this.settingService.hasPermission(['Approval Edit']) && !this.settingService.hasExpiredPermission(['Approval Edit']);
        this.calendarEditPermissionAll =  this.settingService.hasPermission(['Calendar Edit']) && !this.settingService.hasExpiredPermission(['Calendar Edit']) && this.tokenService.get().features['Calendar Edit'].includes('All');
    }

    disabledPicker(time: number): boolean {
        const today = new Date();
        return !time || dayjs(time).isBefore(today);
    }

    disabledDate = (current: Date): boolean => {
        return differenceInCalendarDays(current, new Date()) < 0;
    }

    disabledTime = (date: Date): any => {
        let disabledHours: number[] = [];
        let disabledMinutes: number[] = [];
        if (date) {
            const today = dayjs().format('YYYY-MM-DD');
            const now = new Date();
            const selectDate = dayjs(date as Date).format('YYYY-MM-DD');
            if (dayjs(selectDate).isSame(today)) {
                disabledHours = this.getDisabledHours(now);
                if (date.getHours() === now.getHours()) {
                    disabledMinutes = this.getDisabledMinutes(now);
                }
            }
        }
        return {
            nzDisabledHours: () => disabledHours,
            nzDisabledMinutes: () => disabledMinutes,
            nzDisabledSeconds: () => [],
        };
    }

    getDisabledHours(date: Date): number[] {
        if (date) {
            const currentHour = date.getHours();
            const hours = [];
            for (let i = 0; i < currentHour; i++) {
                hours.push(i);
            }
            return hours;
        }
        return [];
    }

    getDisabledMinutes(date: Date): number[] {
        if (date) {
            const currentMinute = date.getMinutes();
            const minutes = [];
            for (let i = 0; i < currentMinute; i++) {
                minutes.push(i);
            }
            return minutes;
        }
        return [];
    }

    addField(e?: MouseEvent): void {
        if (e) {
            e.preventDefault();
        }
        const cardIndex = this.isApproval ? 0 : 1;
        this.list[cardIndex].push({
            startTime: undefined,
            endTime: undefined,
            assigneeUserId: undefined
        } as any);
    }

    async fetchData(): Promise<void> {
        try {
            this.loading = true;
            if (this.hasRequestApprovalPermission && this.calendarEditPermissionAll) {
                this.users = (await this.accountService.findList());
            }
            const allReservations = this.editAllCalendarList;
            const reservedReservation = allReservations.filter((item: CalendarVOList) => item.status === this.approvedKey);
            const requestReservation = allReservations.filter((item: CalendarVOList) => item.status === this.pendingKey);
            const selfRequestReservation: CalendarVOList[] = [];
            const otherRequestReservation: CalendarVOList[] = [];
            requestReservation.forEach(it => {
                const isSelf = this.currentUserId === it.userId;
                isSelf ? selfRequestReservation.push(it) : otherRequestReservation.push(it);
            });
            this.otherRequestReservation = otherRequestReservation;
            const reservedArr = this.formatReservation(reservedReservation);
            const requestArr = this.calendarEditPermissionAll ? this.formatReservation([...selfRequestReservation, ...otherRequestReservation]) : this.formatReservation(selfRequestReservation);
            this.list = [requestArr, reservedArr];
            this.editData = reservedReservation;
        } finally {
            this.loading = false;
        }
    }

    open(
        row: CalendarTableRow, 
        isApproval: boolean,
        startTimeBoundary: number,
        endTimeBoundary: number
    ): void {
        this.timeSegmentArr = [];
        const info = this.tokenService.get();
        this.currentUserId = info?.id;
        this.title = row.name;
        this.resourceId = row.id.toString();
        this.type = row.type;
        this.editAllCalendarList = row.calendarVOList || [];
        this.isApproval = isApproval;
        this.startTimeBoundary = startTimeBoundary;
        this.endTimeBoundary = endTimeBoundary;
        this.fetchData();
        this.isVisible = true;
    }

    formatReservation(reservations: CalendarVOList[]): TimeSegment[] {
        const arr: TimeSegment[] = [];
        reservations.forEach((rangItem: CalendarVOList) => {
            const endTime = dayjs(rangItem.endTime).endOf('minute').valueOf();
            const isOthers = this.currentUserId !== rangItem.userId;
            arr.push({
                ...rangItem,
                endTime,
                startDisabled: isOthers && !(this.hasRequestApprovalPermission && this.calendarEditPermissionAll),
                endDisabled: isOthers && !(this.hasRequestApprovalPermission && this.calendarEditPermissionAll),
                userId: rangItem.userId,
                isOthers
            });
        });
        return arr;
    }

    close(): void {
        this.isVisible = false;
    }

    removeField(cardIndex: number, listIndex: number, e: MouseEvent): void {
        e.preventDefault();
        this.list[cardIndex].splice(listIndex, 1);
    }

    disabledRemove(cardIndex: number, index: number): boolean {
        const cardList = this.list[cardIndex];
        const listItem = cardList[index];
        const rowHasDisabled = listItem?.startDisabled || listItem?.endDisabled || false;
        return rowHasDisabled;
    }

    validForm(request: TimeSegment[], reserved: TimeSegment[]): boolean {
        const fieldArray = [...reserved, ...request];
        const hasEmpty = fieldArray.some(it => !it.startTime || !it.endTime);
        if (hasEmpty) {
            this.message.warning('StartTime and EndTime can not be empty.');
            return false;
        }
        if (!fieldArray.every((row) => row.endTime > row.startTime)) {
            this.message.warning('EndTime should later than StartTime.');
            return false;
        }
        // request self overlap
        const requestOverlaps = this.checkOverlap(request || []);
        // reserved self overlap
        const reservedOverlaps = this.checkOverlap(reserved);
        // reserved with request overlap
        const mixedOverlaps = this.checkRequestOverlap(request, reserved);
        const requestArr = this.formatOverlapStr(requestOverlaps, 'Request', 'Request');
        const reservedArr = this.formatOverlapStr(reservedOverlaps, 'Reservation');
        const mixedArr = this.formatOverlapStr(mixedOverlaps);
        const arr = [...requestArr, ...reservedArr, ...mixedArr];
        if (arr.length > 0) {
            const text = `Time overlap found among time period ${arr.join(', ')}.`;
            this.message.warning(text);
            return false;
        }
        return true;
    }

    formatOverlapStr(overlaps: Array<number[]>, type1: string = 'Request', type2: string = 'Reservation'): string[] {
        const arr = overlaps.map(it => {
            return `${type1} #${it[0]} and ${type2} #${it[1]}`;
        });
        return arr;
    }

    getListRange(): {
        reserved: TimeSegment[],
        request: TimeSegment[]
    } {
        const requestArray = this.formatParamsRange(this.list?.[0] || []);
        const reservedArray = this.formatParamsRange(this.list?.[1] || []);
        return {
            reserved: reservedArray || [],
            request: requestArray || []
        };
    }

    formatParamsRange(rangeArr: TimeSegment[]): TimeSegment[] {
        return rangeArr.map((item: any) => {
            return {
                id: item?.id,
                startTime: item.startTime ? dayjs(item.startTime).startOf('minute').valueOf() : '',
                endTime: item.endTime ? dayjs(item.endTime).endOf('minute').valueOf() : '',
                assigneeUserId: item.assigneeUserId,
                userId: item.userId,
                isOthers: item.isOthers
            } as any;
        });
    }

    mergeRanges(timeRanges: TimeSegment[]): TimeSegment[] {
        const timeRangesMap: {[key: number]: TimeSegment[]} = {};
        timeRanges.forEach(timeRange => {
            const userId: number = timeRange.userId ?? timeRange.assigneeUserId ?? this.currentUserId;
            if (!timeRangesMap[userId]) {
                timeRangesMap[userId] = [];
            }
            timeRangesMap[userId].push(timeRange);
        })

        const mergedRangesOfAllUsers: TimeSegment[] = [];
        Object.values(timeRangesMap).forEach(timeRanges => {
            if (timeRanges.length <= 1) {
                mergedRangesOfAllUsers.push(...timeRanges);
                return;
            }
            const sortedRanges = timeRanges.sort((a, b) => a.startTime - b.startTime);
            const mergedRanges = [];
    
            let currentStart = sortedRanges[0].startTime;
            let currentEnd = sortedRanges[0].endTime;
            let currentRangeId = sortedRanges[0]?.id;
    
            for (let i = 1; i < sortedRanges.length; i++) {
                const nextStart = sortedRanges[i].startTime;
                const nextEnd = sortedRanges[i].endTime;
                const nextRangeId = sortedRanges[i]?.id;
    
                if ((!currentRangeId || !nextRangeId)
                    && nextStart === currentEnd + 1
                ) {
                    currentEnd = nextEnd > currentEnd ? nextEnd : currentEnd;
                    currentRangeId = currentRangeId || nextRangeId;
                } else {
                    const range = {
                        startTime: currentStart,
                        endTime: currentEnd,
                    };
                    
                    mergedRanges.push(currentRangeId 
                        ? { ...range, id: currentRangeId }
                        : (
                            sortedRanges[i-1].assigneeUserId 
                            ? { ...range, assigneeUserId: sortedRanges[i-1].assigneeUserId } 
                            : range
                        ));
    
                    currentStart = nextStart;
                    currentEnd = nextEnd;
                    currentRangeId = nextRangeId;
                }
            }
    
            const data = {
                startTime: currentStart,
                endTime: currentEnd,
                assigneeUserId: sortedRanges[sortedRanges.length-1].assigneeUserId
            };
            mergedRanges.push(currentRangeId ? { ...data, id: currentRangeId } : data);
            mergedRangesOfAllUsers.push(...mergedRanges);
        });
    
        return mergedRangesOfAllUsers;
    }

    checkOverlap(timeRanges: TimeSegment[]): Array<number[]> {
        const overlaps = [];
        for (let i = 0; i < timeRanges.length - 1; i++) {
            for (let j = i + 1; j < timeRanges.length; j++) {
                const range1Start = timeRanges[i].startTime;
                const range1End = timeRanges[i].endTime;
                const range2Start = timeRanges[j].startTime;
                const range2End = timeRanges[j].endTime;
                if (
                    (range1Start <= range2Start && range1End >= range2Start) ||
                    (range2Start <= range1Start && range2End >= range1Start)
                ) {
                    overlaps.push([i + 1, j + 1]);
                }
            }
        }
        return overlaps;
    }

    getUsernameById(id: number): string {
        if (this.users.length === 0) {
            return "";
        }
        return this.users.find(user => user.value === id)?.label || "";
    } 

    checkRequestOverlap(requestRanges: TimeSegment[], timeRanges: TimeSegment[]): Array<number[]> {
        const overlaps = [];
        for (let i = 0; i < requestRanges.length; i++) {
            for (let j = 0; j < timeRanges.length; j++) {
                const requestItem = requestRanges[i];
                const requestStart = requestItem.startTime;
                const requestEnd = requestItem.endTime;
                const reservedStart = timeRanges[j].startTime;
                const reservedEnd = timeRanges[j].endTime;
                if (
                    (requestStart <= reservedStart && requestEnd >= reservedStart) ||
                    (reservedStart <= requestStart && reservedEnd >= requestStart)
                ) {
                    overlaps.push([i + 1, j + 1]);
                }
            }
        }
        return overlaps;
    }

    async submit(): Promise<void> {
        try {
            const { request, reserved } = this.getListRange();
            if (!this.validForm(request, reserved)) {
                return;
            }
            this.loading = true;
            const requestMerge = this.mergeRanges(request);
            const reservedMerge = this.mergeRanges(reserved);
            /**
             * For tester: requestMerge only contain requests of his/her own.
             * For Labadmin/ResourceManager: requestMerge contain all requests in the system.
             */
            const otherRequest = this.calendarEditPermissionAll 
                ? [] 
                : this.otherRequestReservation.map(item => {
                    const { id, startTime, endTime } = item;
                    return {
                        id,
                        startTime,
                        endTime
                    };
                });
            let params: OverwriteOneParams = {
                type: this.type,
                description: '',
                startTimeBoundary: this.startTimeBoundary,
                endTimeBoundary: this.endTimeBoundary,
                times: [...requestMerge, ...reservedMerge, ...otherRequest],
            };
            if (this.type === 'CHAMBER') {
                params = {
                    chamberId: Number(this.resourceId),
                    ...params,
                };
            } else if (this.type === 'RADIO') {
                params = {
                    radioId: Number(this.resourceId),
                    ...params,
                };
            } else if (this.type === 'UE') {
                params = {
                    ueId: Number(this.resourceId),
                    ...params,
                };
            }
            await this.resourceReservationService.overwriteOne(params);
            this.message.success('Edited successfully.');
            this.close();
            this.submitSuccess.emit();
        } finally {
            this.loading = false;
        }
    }

}
