import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileService } from '@services/lm/file.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.less'],
})
export class UploadComponent {
    @Input() buttonText = 'Upload new file';
    @Input() showProgress = true;
    @Input() path = '/file';
    @Input() maxSize = 0;
    @Output() uploadSuccess = new EventEmitter();

    fileList: NzUploadFile[] = [];
    progress = 0;
    subscription: Subscription | undefined;
    loading = false;

    constructor(private fileService: FileService,
                private message: NzMessageService) {
    }

    beforeUpload = (file: File): boolean => {
        const maxBit = this.maxSize * 1024 * 1024;
        console.log('beforeUpload', file, file.size, maxBit);
        if (file.size === 0) {
            this.message.warning('File content cannot be empty.');
        } else if (this.maxSize && file.size > maxBit) {
            this.message.warning(`File size cannot exceed ${this.maxSize}M`);
        } else {
            this.uploadFile(file);
        }
        return false;
    }

    async uploadFile(file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);
        formData.append('path', this.path);
        console.log('formData', formData);
        this.subscription = this.fileService.upload(formData).subscribe((httpEvent: HttpEvent<any>) => {
            console.log('subscription', httpEvent);
            switch (httpEvent.type) {
                case HttpEventType.Sent:
                    break;
                case HttpEventType.ResponseHeader:
                    break;
                case HttpEventType.UploadProgress:
                    this.progress = Math.round(httpEvent.loaded / (httpEvent.total || 0) * 100);
                    break;
                case HttpEventType.Response:
                    setTimeout(() => {
                        this.subscription = undefined;
                        this.progress = 0;
                        this.uploadSuccess.emit(httpEvent.body);
                    }, 1000);
            }
            console.log('httpEvent', httpEvent.type, this.progress);
        }, error => {
            this.progress = 0;
        });
    }

    handleRemove = (item: NzUploadFile) => {
        console.log('handleRemove', item);
        const index = this.fileList.findIndex((file) => file.uid === item.uid);
        this.fileList.splice(index, 1);
    }
}
