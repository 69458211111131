import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddTag, Tag } from '@app/interfaces/lm/tag';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class TagService {
    private baseUrl = environment.api.mockUrl + '/tag';

    constructor(private http: HttpClient) {}

    async find(nameLike?: string): Promise<Tag[]> {
        return this.http
            .get<Tag[]>(`${this.baseUrl}`, {
                params: Object.assign({ nameLike }),
            })
            .toPromise();
    }

    async edit(data: Tag): Promise<Tag> {
        return this.http.post<Tag>(`${this.baseUrl}/edit`, data).toPromise();
    }

    async add(data: AddTag): Promise<AddTag> {
        return this.http.post<Tag>(`${this.baseUrl}`, data).toPromise();
    }

    async remove(id: number): Promise<void> {
        return this.http
            .delete<void>(`${this.baseUrl}`, {
                params: Object.assign({ id }),
            })
            .toPromise();
    }

    formatTagLabel(ids: string | undefined, tags: Tag[]): string {
        console.log('ids', ids, tags);
        const tagIds = ids ? ids.split(',') : [];
        if (tagIds && tagIds.length > 0) {
            return tagIds
                .map((item) => {
                    const currentTag = tags.filter((tag) => tag.id.toString() === item)[0];
                    return currentTag ? currentTag.name : '';
                })
                .filter((item) => item !== '')
                .join(', ');
        }
        return '';
    }
}
