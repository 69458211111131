import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
})
export class UploadFileComponent {
    @Input() buttonText = 'Import';
    @Input() disabled = false;
    @Input() accept = 'application/json';
    @Input() buttonSize = 'small';
    @Input() uploadLoading = false;

    @Output() selectFile = new EventEmitter();

    beforeUpload = (file: NzUploadFile): boolean => {
        console.log('beforeUpload', file);
        this.selectFile.emit(file);
        return false;
    };
}
