import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AddTaskFile, TaskFile, TaskFileParams } from '@app/interfaces/lm/task';
import { STChange, STColumn, STData, STScroll } from '@app/interfaces/sys/st';
import { environment } from '@env/environment';
import { ProjectService } from '@services/lm/project.service';
import { SettingsService } from '@services/sys/settings.service';

@Component({
    selector: 'app-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.less'],
})
export class FileListComponent implements OnInit {
    @Input() showTaskColumn = false;
    @Input() canUpload = false;
    @Input() uploadPath = '';
    @Input() taskFileFilter: TaskFileParams = {} as TaskFileParams;

    columns: STColumn[] = [
        {
            title: 'Type',
            render: 'type',
            width: '50px'
        },
        {
            title: 'Task',
            index: 'projectTaskName',
            iif: () => this.showTaskColumn,
            // sort: true,
            width: '80px'
        },
        {
            title: 'File Name',
            index: 'name',
            sort: true,
            width: '100px'
        },
        {
            title: 'File Size',
            index: 'fileSize',
            format: (item: STData) => {
                return item.size + ' B';
            },
            // sort: true,
            width: '100px'
        },
        {
            title: 'Update Date',
            index: 'lastModifiedDate',
            type: 'date',
            dateFormat: 'dd/MM/yy, h:mm a',
            sort: true,
            width: '140px'
        },
        {
            title: 'Update User',
            index: 'lastModifiedByName',
            // sort: true,
            width: '120px'
        },
        {
            title: 'Actions',
            render: 'action',
            className: 'remove-padding',
            width: '100px'
        },
    ];
    page = 0;
    size = 10;
    total = 0;
    data: TaskFile[] = [];
    loading = false;
    params: {
        [key: string]: string
    } = {};
    nzScroll: STScroll = {};

    constructor(private projectService: ProjectService, private settingService: SettingsService) {
    }

    @HostListener('window:resize')
    _resize(): void {
        this.setScroll();
    }

    setScroll(): void {
        this.settingService.setLayout();
        const { y } = this.settingService.layout;
        this.nzScroll = {
            x: '700px',
            y: `${y}px`,
        };
    }

    ngOnInit(): void {
        this.setScroll();
        this.fetchData();
    }

    handleChange(event: STChange): void {
        const { type, pi, sort } = event;
        if (type === 'pi') {
            this.page = pi - 1;
            this.fetchData();
        }
        if (type === 'sort') {
            this.params = {
                ...this.params,
                sort
            };
            this.handleRefresh();
        }
    }

    handleRefresh(): void {
        this.page = 0;
        this.fetchData();
    }

    async uploadSuccess(data: AddTaskFile): Promise<void> {
        const submitData = {
            ...data,
            projectTaskId: this.taskFileFilter.projectTaskId,
        };
        await this.projectService.addFile(submitData);
        this.fetchData();
    }

    async fetchData(): Promise<void> {
        try {
            this.loading = true;
            const query = {
                page: this.page,
                size: this.size,
                ...this.taskFileFilter,
                ...this.params
            };
            const { content, totalElements } = await this.projectService.findFile(query);
            this.data = content;
            this.total = totalElements;
        } finally {
            this.loading = false;
        }
    }

    handleDownload(row: TaskFile): void {
        console.log('handleDownload', row);
        const url = `${environment.api.baseUrl}/files?path=${row.path}`;
        window.open(url);
    }

    getFileType(type: string): string {
        if (!type) {
            return '';
        }
        if (type.indexOf('pdf') >= 0) {
            return 'svg:pdf';
        }

        if (type.indexOf('image') >= 0) {
            return 'svg:image';
        }

        if (type.indexOf('excel') >= 0) {
            return 'svg:excel';
        }

        if (type.indexOf('word') >= 0) {
            return 'svg:word';
        }
        return 'svg:file';
    }
}
