<div class="row-select" #overflowText>
    <nz-select
        [nzCustomTemplate]="defaultTemplate"
        nzPlaceHolder="Please Select"
        nzShowSearch
        nzServerSearch
        [(ngModel)]="currentValue"
        class="remote-select"
        nzAllowClear
        nzSize="small"
        (ngModelChange)="changeSelect($event)"
        (nzOpenChange)="open()"
        [nzDropdownRender]="loading ? renderTemplate : null"
        (nzScrollToBottom)="loadMore()"
        (nzOnSearch)="onSearch($event)"
    >
        <ng-container *ngFor="let option of list">
            <nz-option
                *ngIf="!searchLoading"
                nzCustomContent
                [nzLabel]="option.label"
                [nzValue]="option.value"
            >
                {{ option.label | selectShow: showNum : showTotal }}
            </nz-option>
        </ng-container>

        <nz-option *ngIf="searchLoading" nzDisabled nzCustomContent>
            <i nz-icon nzType="loading" class="loading-icon"></i>
            Loading Data...
        </nz-option>
    </nz-select>

    <ng-template #defaultTemplate let-selected>
        <div #optionEl>
            {{ selected.nzLabel }}
        </div>
    </ng-template>

    <ng-template #renderTemplate>
        <nz-spin></nz-spin>
    </ng-template>
</div>
