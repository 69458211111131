import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl, Title} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ue-screen-mirror',
  templateUrl: './ue-screen-mirror.component.html',
  styleUrls: ['./ue-screen-mirror.component.less']
})
export class UeScreenMirrorComponent implements OnInit {

  title: string = ''
  url: SafeUrl = ''
  chamber: string = ''

  constructor(private route: ActivatedRoute,private sanitizer: DomSanitizer, private titleService: Title) {
    route.queryParams.subscribe(x => {
      if (x && x.title) {
        this.title = x.title
        this.titleService.setTitle(x.title)
      }
      if (x && x.url) {
        this.url = sanitizer.bypassSecurityTrustResourceUrl(x.url)
      }
      if (x && x.chamber) {
        this.chamber = x.chamber
        if(this.chamber === 'null') {
          this.chamber = ''
        }
      }
    })
  }

  ngOnInit(): void {

  }

}
