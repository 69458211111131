import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AddTablePosition,
    EditTablePosition,
    STColumn,
    TablePosition,
    TableSaveData,
    TableSumInfo,
} from '@app/interfaces/sys/st';
import { LicenceService } from '@services/sys/licence.service';
import { PermissionService } from '@services/sys/permission.service';
import { SettingsService } from '@services/sys/settings.service';

@Injectable({
    providedIn: 'root',
})

export class CommonService {
    defaultResizeMinWidth = 60;
    stResizeMinWidth = 80;
    
    constructor(private settingsService: SettingsService,
                private permissionService: PermissionService,
                private licenceService: LicenceService,
                private http: HttpClient) {
    }
    
    getArrayMaxWidth(arr: any[], key: string, fontPixel: number = 14, offset: number = 0, formatter?: any): number {
        let maxWidth = 0;
        arr.forEach((obj) => {
            const columnIndent = obj?.columnIndent || 0;
            const str = formatter ? formatter(obj) : key ? (obj?.[key] || '') : obj;
            const width = this.settingsService.getTextWidth(str, offset + columnIndent, fontPixel);
            maxWidth = Math.max(maxWidth, width);
            console.log('key', str, width);
        });
        return maxWidth;
    }
    
    getResourceNameRowWidth(): number {
        return this.getArrayMaxWidth(['w'], '') * 26;
    }
    
    getTableMaxWidth(columns: STColumn[], data: any[]): number {
        let fixedColumnWidth = 0;
        let autoColumnWidth = 0;
        columns.forEach(item => {
            const key = item?.index as string;
            if (item.autoWidth) {
                autoColumnWidth += this.getArrayMaxWidth(data, key, 13);
                return;
            }
            fixedColumnWidth += parseInt(item.width as string);
        });
        return autoColumnWidth + fixedColumnWidth + 50;
    }
    
    getModalMaxWidth(minModalWidth: number, contentWidth: number, modalPadding: number): number {
        const width = contentWidth + modalPadding;
        return width > minModalWidth ? width : minModalWidth;
    }
    
    changeColumnWidth(columns: STColumn[], content: any[]): STColumn[] {
        // 16 table padding
        const limitWrapWidth = this.getResourceNameRowWidth();
        columns.forEach(item => {
            const columnKey = item?.index as string;
            const { minWidth, title } = item;
            let dragMinWidth!: number;
            let contentMaxWidth!: number;
            let strMaxWidth!: number;
            const titleWidth = this.settingsService.getTextWidth(title || '');
            const defaultWidth = parseInt(item?.defaultWidth as string) || 80;
            if (!item?.minWidth || item?.autoWidth) {
                const format = item.format;
                const multipleFormat = item.multipleFormat;
                strMaxWidth = multipleFormat ? this.getMultipleMaxWidth(content, multipleFormat) :
                    this.getArrayMaxWidth(content, columnKey, 14, 0, format);
                const contentWidth = strMaxWidth + 16;
                contentMaxWidth = Math.max(defaultWidth, contentWidth);
            }
            if (item?.autoWidth) {
                item.width = `${contentMaxWidth}px`;
                dragMinWidth = strMaxWidth < limitWrapWidth ? strMaxWidth : (Math.max(strMaxWidth / 2, limitWrapWidth));
            } else {
                dragMinWidth = titleWidth;
            }
            item.dragMaxWidth = (item.minWidth ? Math.max(titleWidth, defaultWidth, minWidth || 0) : contentMaxWidth) * 2;
            if (!dragMinWidth && !minWidth) {
                return;
            }
            item.dragMinWidth = Math.max(dragMinWidth, minWidth || 0);
        });
        return columns;
    }
    
    getMultipleMaxWidth(arr: any[], multipleFormat: any, fontPixel: number = 14, offset: number = 0): number {
        let maxWidth = 0;
        arr.forEach((obj) => {
            const strArr = multipleFormat(obj);
            const rowMaxWidth = strArr.length > 0 ? Math.max(...strArr.map((str: string) => this.settingsService.getTextWidth(str, offset, fontPixel))) : 0;
            maxWidth = Math.max(maxWidth, rowMaxWidth);
        });
        return maxWidth;
    }
    
    getFormDialogWidth(valueMaxWidth: number, valueSpan: number = 19, dialogPadding: number = 100): number {
        return (valueMaxWidth / valueSpan) * 24 + dialogPadding;
    }
    
    getInputMaxWidth(str: string): number {
        return this.settingsService.getTextWidth(str, 0) + 25;
    }
    
    getOptionWidth(arr: any[], key: string = 'label', defaultWidth: number = 150): number {
        return Math.max(this.getArrayMaxWidth(arr, key) + 75, defaultWidth);
    }
    
    reservationLicenseValid(): boolean {
        const hasCalendarView = this.permissionService.isValidMenu('Resource Reservation');
        const isExpired = this.licenceService.isLicenceExpired('Lab Management');
        
        if (!hasCalendarView) {
            return false;
        }
        if (isExpired) {
            return false;
        }
        return true;
    }
    
    canEditCalendar(): boolean {
        const canEdit = this.settingsService.hasPermission(['Calendar Edit']);
        const valid = this.reservationLicenseValid();
        return valid && canEdit;
    }
    
    async getTablePosition(type: string): Promise<TablePosition[]> {
        return this.http
            .get<TablePosition[]>(`/user-position`, {
                params: {
                    type,
                },
            })
            .toPromise();
    }
    
    async addTablePosition(data: AddTablePosition): Promise<TablePosition> {
        return this.http.put<TablePosition>(`/user-position`, data).toPromise();
    }
    
    async editTablePosition(data: EditTablePosition): Promise<TablePosition> {
        return this.http.post<TablePosition>(`/user-position`, data).toPromise();
    }
    
    formatSavedData(tablePositions?: TablePosition): TableSaveData {
        const positions = JSON.parse(tablePositions?.positions || '{}');
        const { columns, size } = positions;
        const obj = this.getColumnObj(columns);
        return {
            size,
            positions,
            formatterColumnsObj: obj,
        };
    }
    
    getColumnObj(columns: STColumn[]): any {
        let obj = {};
        (columns || []).forEach((it: STColumn, i) => {
            const { index, title } = it;
            const key = index || title || i;
            obj = {
                ...obj,
                [key as string]: it?.realWidth || it?.width || it?.defaultWidth,
            };
        });
        return obj;
    }

    resizeColumn(data: any): any {
        const { columns, resizeIndex, resizedWidth, tableWidth, lastColumnIndex, resizeMinWidth } = data;
        const resizedItem = columns[resizeIndex];
        const dragBeforeWidth = parseFloat(resizedItem?.realWidth || resizedItem?.width as string);
        console.log('dragBeforeWidth', dragBeforeWidth);
        const diff = resizedWidth - dragBeforeWidth;
        const lastIndex = lastColumnIndex || columns.length - 1 as number;
        const isLast = resizeIndex === lastIndex;
        const lastColumnItem = columns[lastIndex];
        const lastColumnMinWidth = Math.max(lastColumnItem?.minWidth || 0, resizeMinWidth || 0, lastColumnItem?.dragMinWidth);
        if (isLast) {
            let otherColumnWidth = 0;
            (columns as any[])?.forEach((it, index) => {
                const showColumn = !it?.iif || it?.iif(it);
                if (index === resizeIndex || !showColumn) {
                    return;
                }
                otherColumnWidth += parseFloat(it?.realWidth || it?.width);
            });
            const resizedTableWidth = otherColumnWidth + resizedWidth;
            const tableDiff = resizedTableWidth - tableWidth;
            // 20 is scroll
            const lastMinWidth = resizedWidth + Math.abs(tableDiff) - 20;
            const realWidth = tableDiff < 0 ? lastMinWidth : resizedWidth;
            columns[resizeIndex].realWidth = `${Math.max(realWidth, lastColumnMinWidth)}px`;
        } else {
            columns[resizeIndex].realWidth = resizedWidth + 'px';
            const { realWidth: lastRealWidth, width: lastWidth, dragMaxWidth: lastDragMaxWidth } = lastColumnItem;
            const lastDragEndWidth = Math.max(parseFloat(lastRealWidth) - diff, lastColumnMinWidth);
            columns[lastIndex].realWidth = Math.min(lastDragEndWidth, lastDragMaxWidth) + 'px';
        }
        return columns;
    }

    getTableColumnRealWidth(columns: STColumn[], tableWidth: number, tableColumnObj?: {
        [columnKey: string]: string
    }): {
        columns: STColumn[],
        lastColumnIndex: number
    } {
        let allWidth = 0;
        let autoWidth = 0;
        let lastColumnIndex!: number;
        let formatterColumns: STColumn[] = columns;
        const hasSavedColumns = tableColumnObj && JSON.stringify(tableColumnObj) !== '{}';
        const allHasWidth = formatterColumns.every(it => it?.realWidth || it?.width);
        if (allHasWidth) {
            let beforeColumnObj: {
                [key: string]: string
            } = {};
            formatterColumns?.forEach((it, index) => {
                const showColumn = !it.iif || it.iif(it);
                const key = it?.index || it?.title as string;
                const savedWidth = tableColumnObj?.[key] || it.width as string;
                const autoWidthColumn = it?.autoWidth || false;
                beforeColumnObj = {
                    ...beforeColumnObj,
                    [key]: savedWidth,
                };
                if (!showColumn) {
                    return;
                }
                lastColumnIndex = index;
                const columnWidth = parseInt(savedWidth as string) || 0;
                if (!hasSavedColumns && autoWidthColumn) {
                    autoWidth += columnWidth;
                    return;
                }
                allWidth += columnWidth;
            });
            formatterColumns = formatterColumns.map(it => {
                const key = it?.index || it?.title as string;
                const columnWidth = parseInt(beforeColumnObj?.[key] || it?.width as string);
                const minWidth = it?.dragMinWidth || it?.minWidth || this.stResizeMinWidth;
                const autoWidthColumn = it?.autoWidth || false;
                const width = hasSavedColumns || (!hasSavedColumns && !autoWidthColumn) ? (columnWidth / allWidth) * (tableWidth - autoWidth) : columnWidth;
                const realWidth = `${Math.max(minWidth, width)}px`;
                return {
                    ...it,
                    width: realWidth,
                    realWidth,
                };
            });
        }
        return {
            columns: formatterColumns,
            lastColumnIndex,
        };
    }
    
    getColumnWidth(it: STColumn, tableColumnObj?: { [columnKey: string]: string }, columnIndex?: number): number {
        const { dragMaxWidth, dragMinWidth } = it;
        const columnKey = it?.index || it?.title || (columnIndex || 0);
        let columnWidth = parseFloat(tableColumnObj?.[columnKey] || it.width as string) || 60;
        if (columnWidth > (dragMaxWidth || 0)) {
            columnWidth = dragMaxWidth || 0;
        }
        if (columnWidth < (dragMinWidth || 0)) {
            columnWidth = dragMinWidth || 0;
        }
        return columnWidth;
    }
    
    formatTableRealWidth(columns: STColumn[], tableWidth: number, tableColumnObj?: {
        [columnKey: string]: string
    }): {
        columns: STColumn[],
        lastColumnIndex: number,
        emptyWidth: number
    } {
        let emptyWidth = 0;
        let lastColumnIndex!: number;
        const formatterColumns: STColumn[] = columns;
        const allHasWidth = formatterColumns.every(it => it?.width);
        const hasSavedColumns = tableColumnObj && JSON.stringify(tableColumnObj) !== '{}';
        if (allHasWidth) {
            const {
                savedWidthSum,
                maxTableWidthSum,
                contentWidthSum,
                maxContentDiffWidthSum,
                lastColumnIndex: lastIndex,
            } = this.getTableInfo(columns, tableColumnObj);
            lastColumnIndex = lastIndex;
            console.log('TableWidth: ', tableWidth);
            console.log('savedWidthSum: ', savedWidthSum);
            console.log('maxTableWidthSum: ', maxTableWidthSum);
            console.log('contentWidthSum: ', contentWidthSum);
            console.log('maxContentDiffWidthSum: ', maxContentDiffWidthSum);
            if (hasSavedColumns) {
                emptyWidth = savedWidthSum > tableWidth ? 0 : tableWidth - savedWidthSum;
                formatterColumns.forEach((it, index) => {
                    const showColumn = !it.iif || it.iif(it);
                    if (!showColumn) {
                        return;
                    }
                    const savedWidth = this.getColumnWidth(it, tableColumnObj, index);
                    it.realWidth = `${savedWidth}px`;
                    it.width = `${savedWidth}px`;
                });
            } else {
                if (contentWidthSum > tableWidth) {
                    emptyWidth = 0;
                } else {
                    const maxDragIsOverTable = maxTableWidthSum > tableWidth;
                    emptyWidth = maxDragIsOverTable ? 0 : tableWidth - maxTableWidthSum;
                    formatterColumns.forEach((it, index) => {
                        const showColumn = !it.iif || it.iif(it);
                        if (!showColumn) {
                            return;
                        }
                        const { dragMaxWidth, realWidth, width } = it;
                        const columnWidth = maxDragIsOverTable ? parseInt(realWidth || width as string) : dragMaxWidth || 0;
                        const diff = maxDragIsOverTable ? ((dragMaxWidth as number - columnWidth) / maxContentDiffWidthSum) * (tableWidth - contentWidthSum) : 0;
                        const w = `${diff + columnWidth}px`;
                        it.realWidth = w;
                        it.width = w;
                    });
                }
            }
        }
        return {
            columns: formatterColumns,
            lastColumnIndex,
            emptyWidth,
        };
    }
    
    getTableLastColumnIndex(columns: any[]): number {
        let index = 0;
        columns.forEach((it, _idx) => {
            const showColumn = !it.iif || it.iif(it);
            if (!showColumn) {
                return;
            }
            index = _idx;
        });
        return index;
    }
    
    getInitColumns(initColumns: STColumn[], data: any): STColumn[] {
        // update columns width with longest string;
        const updateColumns = this.changeColumnWidth(initColumns, data);
        initColumns.forEach((item, idx) => {
            initColumns[idx].width = updateColumns[idx].width;
        });
        return initColumns;
    }
    
    deepClone(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        const clone = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'function') {
                    // @ts-ignore
                    clone[key] = obj[key].bind(clone);
                } else {
                    // @ts-ignore
                    clone[key] = this.deepClone(obj[key]);
                }
            }
        }
        return clone;
    }

    getContentWidth(columns: STColumn[], content: any[], tableWidth: number, indent: number = 0): STColumn[] {
        const limitWrapWidth = this.getResourceNameRowWidth();
        // 16 table padding
        columns.forEach(item => {
            const columnKey = item?.index || item?.title as string;
            const { minWidth, title } = item;
            let strMaxWidth = 0;
            const titleWidth = this.settingsService.getTextWidth(title || '');
            const defaultWidth = item?.defaultWidth ? parseInt(item?.defaultWidth as string) : 0;
            if (item?.autoWidth) {
                const format = item.format;
                const multipleFormat = item.multipleFormat;
                strMaxWidth = multipleFormat ? this.getMultipleMaxWidth(content, multipleFormat) :
                    this.getArrayMaxWidth(content, columnKey, 14, 0, format);
            }
            const contentWidth = Math.max(minWidth || 0, defaultWidth, strMaxWidth, this.stResizeMinWidth) + indent + 16;
            item.width = contentWidth + 'px';
            item.realWidth = contentWidth + 'px';
            item.contentWidth = contentWidth;
            item.titleWidth = Math.max(titleWidth, minWidth || 0, defaultWidth);
            item.dragMinWidth = (contentWidth < limitWrapWidth ? contentWidth : (Math.max(contentWidth / 2, limitWrapWidth)));
            item.dragMaxWidth = item.autoWidth ? contentWidth * 2 : Math.max(tableWidth / 2, contentWidth * 2);
        });
        return columns;
    }

    getTableInfo(columns: STColumn[], tableColumnObj?: {
        [columnKey: string]: string
    }): TableSumInfo {
        let savedWidthSum = 0;
        let maxTableWidthSum = 0;
        let contentWidthSum = 0;
        let lastColumnIndex!: number;
        const hasSavedColumns = tableColumnObj && JSON.stringify(tableColumnObj) !== '{}';
        columns?.forEach((it, index) => {
            const showColumn = !it.iif || it.iif(it);
            if (!showColumn) {
                return;
            }
            lastColumnIndex = index;
            savedWidthSum += hasSavedColumns ? this.getColumnWidth(it, tableColumnObj) : 0;
            const { dragMaxWidth, realWidth, width } = it;
            maxTableWidthSum += dragMaxWidth || 0;
            contentWidthSum += parseFloat(realWidth || width as string);
        });
        const maxContentDiffWidthSum = maxTableWidthSum - contentWidthSum;
        return {
            savedWidthSum,
            maxTableWidthSum,
            contentWidthSum,
            maxContentDiffWidthSum,
            lastColumnIndex,
        };
    }
}
