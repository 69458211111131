export const ENABLE = [
    'dashboard',
    'systemLog',
    'accounts',
    'role',
    'auditLog',
    'setting',
    'profileSetting',
    // 'simulatorManagement',
    'matrixMonitor',
    'resource',
    'deviceControl',
    'advancedMobility',
    'resourceAssignment',
    // 'license',
    'licenceManager',
    'labMonitor',
    // 'backupManager',
    'notification',
    'notificationSetting',
    'ueControl',
    'resourceReservation',
    'labManagement',
    'resourceRequest',
    'opticalSwitch',
    'networkImpairment',
    'e2eScripting'
];