import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { UserLoginComponent } from './components/login/login.component';
import { UeScreenMirrorComponent } from './components/ue-screen-mirror/ue-screen-mirror.component';

const routes: Routes = [
    { path: 'login', component: UserLoginComponent },
    { path: 'mirror', component: UeScreenMirrorComponent },
    {
        path: '',
        loadChildren: () => import('./modules/frame/main-layout.module').then((m) => m.MainLayoutModule),
    },
    {
        path: 'exception',
        loadChildren: () => import('./modules/exception/exception.module').then((m) => m.ExceptionModule),
    },
    {
        path: '**',
        redirectTo: 'exception/404',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: environment.useHash,
            scrollPositionRestoration: 'top',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
