import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddTaskData, Task } from '@app/interfaces/lm/task';
import { Option, SelectOption } from '@app/interfaces/sys/dict';
import { TaskService } from '@services/lm/task.service';
import { AccountService } from '@services/sys/account.service';
import { DictService } from '@services/sys/dict.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-task-form',
    templateUrl: './task-form.component.html',
    styleUrls: ['./task-form.component.less'],
})
export class TaskFormComponent implements OnInit {
    @Input() editData: Task | undefined;
    validateForm!: FormGroup;
    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Input is required',
            email: 'Please enter valid Email.',
            isBeforeDate: 'The date entered cannot be before today',
            endDateError: 'The end date cannot be before start date',
        },
    };
    typeOptions: Option[] = [];
    ownerOptions: SelectOption[] = [];

    get typeId(): string {
        return this.validateForm.value.type;
    }

    constructor(private taskService: TaskService, private fb: FormBuilder,
                private dictService: DictService,
                private userService: AccountService) {}

    startDateValidator = (control: FormControl): { [s: string]: boolean } => {
        const planStartDate = dayjs(control.value).format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        if (dayjs(planStartDate).isBefore(today)) {
            return {
                isBeforeDate: true,
                error: true,
            };
        }
        return {};
    }

    endDateValidator = (control: FormControl): { [s: string]: boolean } => {
        console.log('controls.', control, this.validateForm);
        const planEndDate = dayjs(control.value).format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');

        if (!control.value) {
            return { required: true };
        }

        if (dayjs(planEndDate).isBefore(today)) {
            return {
                isBeforeDate: true,
                error: true,
            };
        }
        const planStartDate = dayjs(this.validateForm.controls.planStartDate.value).format('YYYY-MM-DD');
        if (dayjs(planEndDate).isBefore(planStartDate)) {
            return {
                endDateError: true,
                error: true,
            };
        }
        return {};
    }

    async ngOnInit(): Promise<void> {
        const commonFields = {
            name: [null, [Validators.required]],
            type: [null, [Validators.required]],
            ownerId: [null],
            planEndDate: [null, [Validators.required, this.endDateValidator]],
            description: [null],
            totalTestcase: [0],
            finishedTestcase: [0]
        };
        if (this.editData) {
            // edit task
            this.validateForm = this.fb.group({
                ...commonFields,
                planStartDate: [null, [Validators.required]],
            });
        } else {
            // add task
            this.validateForm = this.fb.group({
                ...commonFields,
                planStartDate: [null, [Validators.required, this.startDateValidator]],
            });
        }

        await Promise.all([
            this.fetchTaskType(),
            this.fetchOwner()
        ]);
        if (this.editData) {
            this.validateForm?.reset(this.editData);
        }
    }

    async fetchTaskType(): Promise<void> {
        this.typeOptions = await this.dictService.findDictList('PROJECT_TASK_SELECT_TYPE');
        console.log('typeOptions', this.typeOptions);
    }

    async fetchOwner(): Promise<void> {
        this.ownerOptions = await this.userService.findList() ?? [];
        console.log('ownerOptions', this.ownerOptions);
    }

    resetForm(): void {
        this.validateForm.reset();
        for (const control of Object.values(this.validateForm.controls)) {
            control.markAsPristine();
            control.updateValueAndValidity();
        }
    }

    validForm(): AddTaskData | undefined {
        console.log('value', this.validateForm.value);
        for (const control of Object.values(this.validateForm.controls)) {
            control.markAsDirty();
            control.updateValueAndValidity();
        }
        if (!this.validateForm.valid) {
            return;
        }
        return this.validateForm.value;
    }
}
